import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import useCheckSessionApi from "../SessionValidity";
import { userActions } from "../actions";
import Tooltip from '@material-ui/core/Tooltip';

import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import HomeIcon from "@material-ui/icons/Home";
import CallIcon from "@material-ui/icons/Call";
import PublishIcon from "@material-ui/icons/Publish";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EditIcon from "@material-ui/icons/Edit";
import BusinessIcon from "@material-ui/icons/Business";
import CloseIcon from '@material-ui/icons/Close';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControll from '@mui/material/FormControl';
import Input from '@mui/material/Input';

import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

import moment from 'moment';

const theme = createTheme({
  palette: {
    primary: {
      main: '#001eaa',
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  profileImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2%',
  },
  profileImgEdiIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    // marginTop: '2%',
  },
  profileImg: {
    // size:'500%',
    // height: '80%',
    // width: '20%',
    width: '250px',
    height: '250px',
    borderRadius: '50%',
  },
  IconColor: {
    color: 'black',
  },
  itemContainer: {
    display: 'flex',
    // justifyContent: 'center',

  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display: 'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height: '15%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),
  },
  paperModalLoginLog: {
    position: 'absolute',
    width: 500,
    height: 650,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top:'6%',
    display:'block',
    overflowY:'scroll',
  },
  LoginLogsHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: '#23c1f7',
    height: '15%',
  },
  modalBodyLoginLog: {
    padding: theme.spacing(0, 4, 3),
    width:'100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  validationBulk: {
    marginTop: '2%',
    color: 'red',
    fontSize: 12,
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  modalTandC: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModalTandC : {
    width: 600,
    height: 550,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  TandCHeaderBackgroud : {
    marginTop:"1%",
    backgroundColor: 'white',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
  },
  modalBodyTandC: {
    padding: theme.spacing(1, 4, 3),
    width: '100%',
    height:'70%',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    overflow: 'auto',
  },
  TandCModalFooter: {
    padding: theme.spacing(0, 4),
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
  },

}));

export default function UsersProfile() {
  const classes = useStyles();
  const [ShowProcedure, setShowProcedure] = React.useState(false);
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('selected_business_id')));
  // const [business_id, setbusiness_id] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [open, setopen] = React.useState(false);
  const [newPassword, setnewPassword] = React.useState("");
  const [confirmPassword, setconfirmPassword] = React.useState("");
  const [newPassErr, setnewPassErr] = React.useState("");
  const [confirmPassErr, setconfirmPassErr] = React.useState("");

  // ProfilePic
  const [openProfilePic, setopenProfilePic] = React.useState(false);
  const [profilepic, setprofilepic] = React.useState("");
  const [profilepicName, setprofilepicName] = React.useState("");
  const [proflePicErr, setproflePicErr] = React.useState("");

  const [ProfilePictureToShow, setProfilePictureToShow] = React.useState("");

  // Log Login
  const [OpenLoginLogs, setOpenLoginLogs] = React.useState(false);

  // T&C
  const [TandCdata, setTandCdata] = React.useState();
  const [OpenTermsAndCondition, setOpenTermsAndCondition] = React.useState(false);

   const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

  const dispatch = useDispatch();

  const [flag, setflag] = React.useState(false);

  useEffect(() => {
    setflag(true);
    dispatch(userActions.checkSessionValidity());

    localStorage.removeItem('view_clients_active_cases');
    
    // let users = JSON.parse(localStorage.getItem('users'));
    // console.log("users details in users profile", users.data.user_details)
    // if(users.data.user_details.profile_picture){
    //   setProfilePictureToShow(users.data.user_details.profile_picture.url);
    // }
    let profile_picture_here = JSON.parse(localStorage.getItem('profile_picture_local'));
    console.log("profile_picture_local details in users profile", profile_picture_here, profile_picture_here)
    // if(profile_picture_here){
    //   setProfilePictureToShow(profile_picture_here);
    // }
    // else{
    //   setProfilePictureToShow("")
    // }

    getProfilePicture()
    getTandC()
    
    // readItemFromStorage();
  }, [flag]);

  const getProfilePicture = () => {
    let profile_picture_here = JSON.parse(localStorage.getItem('profile_picture_local'));
    console.log("profile_picture_here to pass in users profile",profile_picture_here)
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id, 'doc_file_path': profile_picture_here,
        'device_token': device_token, 'session_id': session_id,
      })
    };
    return fetch(process.env.REACT_APP_GET_DOWNLOAD_INFO, requestOptions)
    .then((Response) => Response.json())
    .then(DownloadInfo => {
      if (DownloadInfo.success == true) {
        console.log("DownloadInfo in profile is", DownloadInfo)
        setProfilePictureToShow(DownloadInfo.data.download_info)
      }
      else{
        setProfilePictureToShow("")
        Swal.fire({
          icon: 'error',
          text: DownloadInfo.errors,
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        text: "Something went wrong",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    })
  }

  const getTandC = () => {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id,
        'device_token': device_token, 'session_id': session_id,
      })
    };
    return fetch(process.env.REACT_APP_GET_TERMS_STATUS_CHECK, requestOptions)
    .then((Response) => Response.json())
    .then(AcceptenceTandC => {
      if( AcceptenceTandC.success === true ){
        console.log("accepted t and c", AcceptenceTandC.data.terms_info.terms_accept)

        // setTandCdata(AcceptenceTandC.data.terms_info.terms_accept);
        setTandCdata(AcceptenceTandC.data.terms_info.terms_accept);
      }
      else if( AcceptenceTandC.success === false ){
        setTandCdata();
      }
    })
    .catch(err => {
      Swal.fire({
        icon: 'error',
        text: "Something went wrong",
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    })
  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  let user_details = JSON.parse(localStorage.getItem('users'));
  console.log("user_details in profile", user_details, role_of_user)
  // let user_name = user_details.data.user_details.name
  let user_full_name = user_details.data.user_details.first_name + " " + user_details.data.user_details.middle_name + " " + user_details.data.user_details.surname
  let user_management_all = user_details.data.user_details.user_management
  let user_management = user_details.data.user_details.user_management[0]
  if( role_of_user != "client"){
    console.log("user_management details in profile", user_management, user_management[0])
    let business_role_of_user = user_details.data.user_details.user_permissions.role_info.role_name
    console.log("business_role_of_user is",business_role_of_user)
  }
  
  
  let users_email_or_username = user_details.data.user_details.email
  console.log("users_email_or_username is",users_email_or_username)

  // convert uwc time to local
  const createdDateFunc = (date) => {
    console.log("date in createdDateFunc is", date)

    let dateInString = JSON.stringify(date)
    console.log("date in straing is ", dateInString)

    var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A'); 
    console.log("formated date is ours",moment(mydateIs).format("MM/DD/YYYY hh:mm A"))
    var myDateWithFormatIs = moment(mydateIs).format("MM/DD/YYYY hh:mm A")
    console.log("myDateWithFormatIs",myDateWithFormatIs)
    console.log("date in format for  in table map is local", new Date(myDateWithFormatIs)) 

    var local_date= moment.utc(myDateWithFormatIs).local().format('MM/DD/YYYY hh:mm A');
    console.log("local_date using moments is",local_date)

    var local_date_DayFirst= moment.utc(myDateWithFormatIs).local().format('DD/MMM/YYYY hh:mm A');
    console.log("local_date day first",local_date_DayFirst)

    return(
      <div>
        {/* <div>{local_date}</div> */}
        <div>{local_date_DayFirst}</div>
      </div>  
    )
  }

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //     console.log("ERROR:",error);        }
  // };

  const handelResetPasswordOpen = () => {
    setopen(true);
  }

  const handelResetPasswordClose = () => {
    setopen(false);
    setnewPassword("");
    setconfirmPassword("");
    setnewPassErr("");
    setconfirmPassErr("");
    setShowPassword(false);
    setShowPasswordConfirm(false);
  }

  const validate = () => {
    let newPassErr = '';
    let confirmPassErr = '';

    if (!newPassword) {
      newPassErr = 'Please Enter Password';
    }
    else if(newPassword)  {
      if(newPassword.length < 8){
        newPassErr = 'Password must contain atleast 8 characters';
      }
    }

    if (!confirmPassword) {
      confirmPassErr = 'Please Enter Confirm Password';
    }
    if (newPassword !== confirmPassword) {
      confirmPassErr = 'Passwords not matched';
    }

    if (newPassErr || confirmPassErr) {
      setnewPassErr(newPassErr);
      setconfirmPassErr(confirmPassErr);

      return false;
    }
    return true;
  }

  const handelSubmitResetPassword = () => {
    const isValid = validate();
    if (isValid) {
      setopen(false);
      setShowProcedure(true);
      console.log("newPassword, confirmPassword", newPassword, confirmPassword);
      let changePasswordDetails = {
        newpassword: confirmPassword,
        device_token: device_token,
        session_id: session_id,
        business_id: business_id
      }
      console.log("changePasswordDetails", changePasswordDetails)

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(changePasswordDetails)
      };
      return fetch(process.env.REACT_APP_CHANGE_PASSWORD, requestOptions)
        .then((Response) => Response.json())
        .then(passwordChangedDetails => {
          setShowProcedure(false);
          console.log("passwordChangedDetails", passwordChangedDetails.success);
          console.log("passwordChangedDetails", passwordChangedDetails);
          if (passwordChangedDetails.success == true) {
            Swal.fire({
              icon: 'success',
              text: "Password Changed Succesfully",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setopen(false);
            setnewPassword("");
            setconfirmPassword("");
            setnewPassErr("");
            setconfirmPassErr("");
            setShowPassword(false);
           setShowPasswordConfirm(false);
          }
          if (passwordChangedDetails.success == false) {
            setopen(true);
            Swal.fire({
              icon: 'error',
              text: passwordChangedDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const body = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <h2>Change Password</h2>
      </div>
      <div className={classes.modalBody}>
        {/* <TextField
          autofocus
          margin="normal"
          required
          fullWidth
          id="newPassword"
          label="New Password"
          name="newPassword"
          autoComplete="newPassword"
          inputProps={{ maxLength: 15 }}
          value={newPassword}
          onChange={(e) => setnewPassword(e.target.value)}
        /> */}
        <ThemeProvider theme={theme} >
          <FormControll sx={{ width: '100%' }} variant="outlined" style={{ marginTop: '4.5%' }}>
            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
            <OutlinedInput
              color="primary"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword ? 'hide the password' : 'display the password'
                    }
                    onClick={() => handleClickShowPassword()}
                    onMouseDown={(e) => handleMouseDownPassword(e)}
                    onMouseUp={(e) => handleMouseUpPassword(e)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{ maxLength: 8 }}
              required
              name="newPassword"
              id="newPassword"
              label="New Password"
              value={newPassword}
              autoFocus
              onChange={(e) => setnewPassword(e.target.value)}
            />
          </FormControll>
        </ThemeProvider>
        {/* <div className={classes.validation}>{(newPassword) ? (<div></div>) : (newPassErr)}</div> */}
        <div className={classes.validation}>{(newPassErr) ? (newPassErr) : (<div></div>)}</div>

        {/* <TextField
          margin="normal"
          required
          fullWidth
          id="confirmPassword"
          label="Confirm Password"
          name="confirmPassword"
          autoComplete="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setconfirmPassword(e.target.value)}
        /> */}
        <ThemeProvider theme={theme} >
          <FormControll sx={{ width: '100%' }} variant="outlined" style={{ marginTop: '4.5%' }}>
            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
            <OutlinedInput
              color="primary"
              type={showPasswordConfirm ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPasswordConfirm ? 'hide the password' : 'display the password'
                    }
                    onClick={() => handleClickShowPasswordConfirm()}
                    onMouseDown={(e) => handleMouseDownPassword(e)}
                    onMouseUp={(e) => handleMouseUpPassword(e)}
                    edge="end"
                  >
                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{ maxLength: 8 }}
              required
              name="confirmPassword"
              id="confirmPassword"
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setconfirmPassword(e.target.value)}
            />
          </FormControll>
        </ThemeProvider>
        {/* <div className={classes.validation}>{(confirmPassword)?(<div></div>):(confirmPassErr)}</div> */}
        <div className={classes.validation}>{confirmPassErr}</div>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitResetPassword}
          >
            Change
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelResetPasswordClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    
    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

  // Profile Pic Functions
  const handelChangeProfilePhotoOpen = () => {
    setopenProfilePic(true);
  }

  const handelChangeProfilePhotoClose = () => {
    setopenProfilePic(false);
    setproflePicErr("");
  }

  const onUploadProfilePic = (event) => {
    console.log("event.target.files[0]", event.target.files[0])
    console.log("event.target.files[0] name", event.target.files[0].name)
    setprofilepicName(event.target.files[0].name);
    setprofilepic(event.target.files[0])
  }

  const validateAddProfilePic = () => {
    let proflePicErr = '';

    if (!profilepic) {
      proflePicErr = 'Please Select Profile Photo';
    }

    if (proflePicErr) {
      setproflePicErr(proflePicErr);
      return false;
    }
    return true;
  }

  const handelSubmitResetProfilePic = () => {
    console.log("Submit Profile Pic Clicked, session_id, device_token", profilepic, session_id, device_token);
    console.log("Submit Profile Pic Clicked 222222", profilepic);
    const isValid = validateAddProfilePic();
    if (isValid) {
      setopenProfilePic(false);
      setShowProcedure(true);

      // 12 dec for s3
      // users/getuploadinfo/

      const requestOptionsSecond = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          'doc_type': 'profile_picture', 'doc_name': profilepic.name, 'doc_extra_info': '',
          'device_token': device_token, 'session_id': session_id, 'business_id': business_id
        })
      }
      return fetch( process.env.REACT_APP_GET_UPLOAD_INFO , requestOptionsSecond)
        .then((Response) => Response.json())
        .then(checkResponseOfS3Upload => {
          // console.log("checkResponseOfS3Upload", checkResponseOfS3Upload)
          // console.log("checkResponseOfS3Upload.data", checkResponseOfS3Upload.data)
          // console.log("checkResponseOfS3Upload.data.public_url", checkResponseOfS3Upload.data.public_url)
          // console.log("checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)

          console.log("checkResponseOfS3Upload.success", checkResponseOfS3Upload.success)
          if (checkResponseOfS3Upload.success === true) {
            // console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
            console.log("in success checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)

            const formData = new FormData();
            Object.keys(checkResponseOfS3Upload.data.upload_info.fields).forEach(key => {
              formData.append(key, checkResponseOfS3Upload.data.upload_info.fields[key]);
            });
            formData.append("file", profilepic);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", checkResponseOfS3Upload.data.upload_info.url, true);
            xhr.send(formData);

            xhr.onload = function () {
              if (this.status === 204) {
                console.log("-----SUCCESSFULLY UPLOADED");

                // console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.public_url.url)
                console.log("in success 204 checkResponseOfS3Upload.data.public_url.url", checkResponseOfS3Upload.data.upload_info.url)

                let user = JSON.parse(localStorage.getItem('user'));
                // let formdata = new FormData();
                // formdata.append('profilepic', checkResponseOfS3Upload.data.public_url.url);
                // formdata.append('device_token', device_token);
                // formdata.append('session_id', session_id);
                const requestOptions = {
                  method: 'POST',
                  headers: { 'Authorization': 'Token ' + user.key },
                  // headers: authHeader(),
                  // body: formdata,
                  body: JSON.stringify({
                    // 'profilepic': checkResponseOfS3Upload.data.public_url.url,
                    'profilepic': checkResponseOfS3Upload.data.upload_info.fields.key,
                    'device_token': device_token, 'session_id': session_id,
                    "uuid": checkResponseOfS3Upload.data.upload_info.uuid,
                    // 'profilepic': checkResponseOfS3Upload.data.public_url.url,
                    'device_token': device_token, 'session_id': session_id, 'business_id': business_id
                  })
                };
                return fetch( process.env.REACT_APP_UPLOAD_PROFILE_PIC , requestOptions)
                  .then((Response) => Response.json())
                  .then( profilePicSaved => {
                    setShowProcedure(false);
                    console.log("profilePicSaved ",profilePicSaved.data.profile_picture.profile_picture);
                    if(profilePicSaved.success == true){
                      setProfilePictureToShow(profilePicSaved.data.profile_picture.profile_picture)
                      localStorage.setItem('profile_picture_local', JSON.stringify(profilePicSaved.data.profile_picture.profile_picture));
                      Swal.fire({
                        icon: 'success',
                        text: "Profile Photo Saved Succesfully",
                        confirmButtonColor: 'primary',
                        confirmButtonText: 'OK',
                        timer: 5000,
                      })
                      setopenProfilePic(false);
                      setprofilepicName("");
                      setprofilepic("");
                      setproflePicErr("");
                      // dispatch(userActions.getAll());
                      setflag(false)
                  }
                  if(profilePicSaved.success == false){
                    setopenProfilePic(true);
                    Swal.fire({
                      icon: 'error',
                      text: profilePicSaved.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }      
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });

              }
              else {
                console.log("---------FAILED UPLOAD");
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Upload failed to s3",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              }
            }

          }
          else {
            setShowProcedure(false);
            Swal.fire({
              icon: 'error',
              // text: "Something went wrong",
              text: "Server Error (s3). Please try again.",
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }

        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error (s3). Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        })

      // 12 dec for s3

      // let user = JSON.parse(localStorage.getItem('user'));
      // let formdata = new FormData();
      // formdata.append('profilepic', profilepic);
      // formdata.append('device_token', device_token);
      // formdata.append('session_id', session_id);
      // const requestOptions = {
      //   method: 'POST',
      //   headers: {'Authorization': 'Token ' + user.key},
      //   // headers: authHeader(),
      //   body: formdata,
      // };
      // return fetch( process.env.REACT_APP_UPLOAD_PROFILE_PIC , requestOptions)
      //   .then((Response) => Response.json())
      //   .then( profilePicSaved => {
      //     setShowProcedure(false);
      //     console.log("profilePicSaved ",profilePicSaved.success);
      //     console.log("profilePicSaved ",profilePicSaved);
      //     if(profilePicSaved.success == true){
      //       Swal.fire({
      //       icon: 'success',
      //       text: "Profile Photo Saved Succesfully",
      //       confirmButtonColor: 'primary',
      //       confirmButtonText: 'OK',
      //       timer: 5000,
      //     })
      //     setopenProfilePic(false);
      //     setprofilepicName("");
      //     setprofilepic("");
      //     setproflePicErr("");
      //   }
      //   if(profilePicSaved.success == false){
      //     setopenProfilePic(true);
      //     Swal.fire({
      //       icon: 'error',
      //       text: profilePicSaved.errors,
      //       confirmButtonColor: '#d33',
      //       confirmButtonText: 'OK',
      //       timer: 5000,
      //     })
      //   }      
      // })
      // .catch(err => {
      //   setShowProcedure(false);
      //   Swal.fire({
      //     icon: 'error',
      //     // text: "Something went wrong",
      //     text: "Server Error. Please try again.",
      //     confirmButtonColor: '#d33',
      //     confirmButtonText: 'OK'
      //   })
      // });
    };


  }

  const bodyProfilePic = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <h2>Reset Profile Photo</h2>
      </div>
      <div className={classes.modalBody}>
        <FormControl className={classes.formControl}>
          <Grid item xs={12} style={{ color: 'black', fontSize: 15, }}>
            Upload Profile Photo
          </Grid>
          <div style={{ marginTop: '4%' }}>
            <input type='file'
              // accept="image/*"
              accept=".jpg,.jpeg,.png,.tiff,.jph"
              id="idProf"
              onChange={(e) => onUploadProfilePic(e)}
            />
          </div>
        </FormControl>
        <div className={classes.validationBulk}>{(profilepic) ? (<div></div>) : (proflePicErr)}</div>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitResetProfilePic}
          >
            Upload
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelChangeProfilePhotoClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  // show logs functions
  const handelShowLogsOpen = () => {
    console.log("handelShowLogs Open clicked")
    setOpenLoginLogs(true);
  }

  const handelShowLogsClose = () => {
    console.log("handelShowLogs Open clicked")
    setOpenLoginLogs(false);
  }

  const bodyLoginLogs  = (
    <div className={classes.paperModalLoginLog}>
      <div className={classes.LoginLogsHeaderBackgroud}>
        {/* <h2>Login Logs</h2> */}
        <Grid container item xs={12}>
            <Grid item xs={11} >
            <h2 style={{marginLeft:'43%'}}>
              {/* Login Logs */}
              User Activity
            </h2>
            </Grid>
            <Grid item xs={1} style={{marginTop:'1%',}}>
              <CloseIcon onClick={()=>handelShowLogsClose()} style={{fontSize: 20, cursor:'pointer'}}/>
            </Grid>
          </Grid>
      </div>
      <div className={classes.modalBodyLoginLog} style={{position:'absolute'}}>

        <Grid style={{color:'grey', fontSize: 14, marginTop:'0.1%'}}>
          {
            user_management_all.map((item, index)=>(
              <Grid>
                {
                  ( index <= 11 )?
                  ( 
                    <Grid>
                      <Grid container>
                        <Grid>
                          {createdDateFunc(item.logged_in_time)}
                        </Grid>
                        <Grid>
                        ,&nbsp;{item.device_ip}
                        </Grid>
                        <Grid>
                        &nbsp;({item.device_info})
                        </Grid>
                      </Grid>
                      <hr/>
                    </Grid>
                  ):
                  (
                    <Grid></Grid>
                  )
                } 
              </Grid>  
          
            ))
          }

          {
            ( user_management_all.length >= 12  )?
            (
              <Grid style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
                Last 12 Records
              </Grid> 
            ):
            (
              <Grid style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
                {/* Less than 12 Records */}
              </Grid> 
            )
          }
          {/* <Grid style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
            Last 12 Records
          </Grid>  */}
        
          {/* {createdDateFunc(user_management.logged_in_time)} */}
        </Grid>

        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
          {/* <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitResetProfilePic}
          >
            Upload
          </Button>
          <div style={{ marginLeft: '2%' }}></div> */}
          <Button
            type="submit"
            variant="contained"
            // color="grey"
            className={classes.submit}
            onClick={handelShowLogsClose}
          >
            Ok
          </Button>
        </div>
      </div>
    </div>
  )

  const showTermsAndConditions = () => {
    console.log(" showTermsAndConditions is clicked")
    setOpenTermsAndCondition(true);
  }

  const hideTermsAndConditions = () => {
    console.log(" showTermsAndConditions is clicked")
    setOpenTermsAndCondition(false);
  }

  const bodyTandC = (
    <div className={classes.paperModalTandC}>
        {/* <Grid container item xs={12}>
          <Grid item xs={12} style={{textAlign: "center",}} >
            <h2 >Terms And Conditions</h2>
          </Grid>
        </Grid> */}
        <div className={classes.TandCHeaderBackgroud} >
          <Grid container style={{ justifyContent: 'center', }}>
            <h2> Terms and Conditions </h2>
          </Grid>
        </div>

        <div className={classes.modalBodyTandC} >
          <Grid item xs={12}>
            <div style={{ maxWidth: '100%', wordBreak: 'break-all' }}>
              {
                TandCdata && TandCdata.map((item,index)=>(
                  console.log("item in t and c is",item),
                  
                    <Grid>
                      {item.text}
                    </Grid>
                ))
              }
            </div>
          </Grid>
        </div>
        
        <div className={classes.TandCModalFooter}>
            <Button
              type="submit"
              variant="contained"
              className={classes.submit}
              onClick={hideTermsAndConditions}
            >
              Ok
            </Button>
          </div>

    </div>
  )

  return (
    console.log("ProfilePictureToShow in return", ProfilePictureToShow),
    <div className={classes.root}>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
      </modal> : null}
      <div className={classes.wrapper}>
        {/* <div>Users Profile</div> */}
        <Paper className={classes.paper}>
          <Grid item xs={12} style={{ textAlign: "right", color:"blue", cursor: "pointer"}}
            onClick={showTermsAndConditions}
          >
            Terms and Conditions
          </Grid>
          <Grid container item xs={12} style={{ marginTop:"-2%"}}>
            <Grid item xs={12} className={classes.profileImgContainer}>
              {/* <Avatar className={classes.profileImg} src="/static/images/avatar/1.jpg" /> */}
              {/* <Avatar className={classes.profileImg} src={ProfilePictureToShow} />
              <EditIcon style={{ marginTop: '10%', cursor: 'pointer' }} onClick={handelChangeProfilePhotoOpen} /> */}

              <img
                // class="fit-picture"
                className={classes.profileImg}
                src={ProfilePictureToShow}
                // alt="Grapefruit slice atop a pile of other slices" 
              />
              {/* <EditIcon style={{ marginTop: '20%', cursor: 'pointer' }} onClick={handelChangeProfilePhotoOpen} /> */}
            </Grid>
            <Grid item xs={8} className={classes.profileImgEdiIcon}>
              <Tooltip
                title="Edit Profile Photo"
                placement="center"
              >
                <EditIcon style={{ cursor: 'pointer', marginTop:'-5%', marginLeft:'-10%' }} onClick={handelChangeProfilePhotoOpen} />
              </Tooltip>
            </Grid>
            <Modal
              open={openProfilePic}
              onClose={handelChangeProfilePhotoClose}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {bodyProfilePic}
            </Modal>

            {/* <Grid container item xs={12} style={{ color: 'black', fontSize: 20, marginTop: '2%', 
              justifyContent: 'center', alignItems:'center'}}
            >
              <Grid>
                {user_full_name} ({business_role_of_user}) &nbsp;
              </Grid>
              <Grid style={{color:'grey', fontSize: 15, marginTop:'0.5%'}}>
                Last Login:&nbsp;
              </Grid>
              <Grid style={{color:'grey', fontSize: 15, marginTop:'0.5%'}}>
                {createdDateFunc(user_management.logged_in_time)}
              </Grid>
              <Grid style={{color:'grey', fontSize: 15, marginTop:'0.5%'}}>
                &nbsp;{ '(' + user_management.device_info + ')'}
              </Grid>
              <Grid 
                style={{color:'blue', fontSize: 15, marginTop:'0.5%', cursor:'pointer', marginLeft:'1%'}}
                onClick={handelShowLogsOpen}
              >
                User Activity
              </Grid>
            </Grid> */}

            {/* <Grid container item xs={12} style={{ color: 'black', fontSize: 20, marginTop: '1%', 
              justifyContent: 'center', alignItems:'center'}}
            >
              {console.log("role_of_user for name showing ",role_of_user)}
              {
                ( role_of_user && role_of_user === "client" )?
                (
                  <Grid>
                    {console.log("role_of_user is client ",role_of_user)}
                    {user_full_name} <text>  {role_of_user}  </text> &nbsp;
                  </Grid>
                ):
                (
                  ( role_of_user && role_of_user !== "client" )?
                  (
                    <Grid>
                      {console.log("role_of_user is not client ",role_of_user)}
                      {user_full_name} <text>   {business_role_of_user}   </text> &nbsp;
                    </Grid>
                  ):
                  (<Grid></Grid>)
                    
                )
              }
              
            </Grid> */}

            {
              (role_of_user == "client") ?
              (
                <Grid container item xs={12} style={{ color: 'black', fontSize: 20, marginTop: '1%', 
                  justifyContent: 'center', alignItems:'center'}}
                >
                  <Grid>
                    {user_full_name} <text>  ({role_of_user}, {user_details.data.user_details.business_link.business_name})  </text> &nbsp;
                  </Grid>
                </Grid>
              ):
              (
                <Grid></Grid>
              )
            }

            {
              (role_of_user != "client") ?
              (
                <Grid container item xs={12} style={{ color: 'black', fontSize: 20, marginTop: '1%', 
                  justifyContent: 'center', alignItems:'center'}}
                >
                  <Grid>
                    {user_full_name} 
                    <text>  ({user_details.data.user_details.user_permissions.role_info.role_name}, {user_details.data.user_details.business.business_name})  </text> &nbsp;

                  </Grid>
                </Grid>
              ):
              (
                <Grid></Grid>
              )
            }
            
            
            <Grid container item xs={12} style={{ color: 'black', fontSize: 20, marginTop: '0.1%', 
              justifyContent: 'center', alignItems:'center'}}
            >
              <Grid>
                {users_email_or_username}
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ color: 'black', fontSize: 20, marginTop: '0.5%', 
              justifyContent: 'center', alignItems:'center'}}
            >
              <Grid style={{color:'grey', fontSize: 15,}}>
                Last Login:&nbsp;
              </Grid>
              <Grid style={{color:'grey', fontSize: 15,}}>
                {createdDateFunc(user_management.logged_in_time)}
              </Grid>
              <Grid style={{color:'grey', fontSize: 15,}}>
                &nbsp;{ '(' + user_management.device_info + ')'}
              </Grid>
              <Grid 
                style={{color:'blue', fontSize: 15, cursor:'pointer', marginLeft:'1%'}}
                onClick={handelShowLogsOpen}
              >
                {/* Logs */}
                User Activity
              </Grid>
            </Grid>

            <hr style={{ width: "80%", marginTop: "1%", }} />

            <Grid item xs={12} className={classes.itemContainer}>
              {/* <AddToQueueIcon/> Address: */}
              {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer", marginLeft: "-7%"}} > */}
              <Grid item xs={1} style={{ marginTop: '1.5%', marginLeft: "10%" }} >
                {/* <Link to="UserAddressDetails"> */}
                  <HomeIcon className={classes.IconColor} />
                {/* </Link> */}
              </Grid>
              <Grid item xs={1} style={{ marginTop: '1.7%', marginLeft: '-2%', textDecoration: 'none', color: 'black', fontSize: 15,}}>
                {/* <Link to="UserAddressDetails" style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}> */}
                  Address
                {/* </Link> */}
              </Grid>
              <Grid item xs={10} style={{ marginTop: '1.7%', marginLeft: '', cursor: "pointer", }}>
                <Link to="UserAddressDetails" style={{ textDecoration: 'none', fontSize: 15, marginLeft: '10%' }}>
                  View List
                </Link>
              </Grid>
            </Grid>


            {/* <hr style={{width: "80%", marginTop:"2%",}}/> */}
            <Grid item xs={12} className={classes.itemContainer}>
              {/* <AddToQueueIcon/> Address: */}
              {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer",marginLeft: "-2.8%"}} > */}
              <Grid item xs={1} style={{ marginTop: '1.5%', marginLeft: "10%" }} >
                {/* <Link to="UserPhoneNumberDetails"> */}
                  <CallIcon className={classes.IconColor} />
                {/* </Link> */}
              </Grid>
              <Grid item xs={2} style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '-4%', textDecoration: 'none', color: 'black', fontSize: 15, }}>
                {/* <Link to="UserPhoneNumberDetails" style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}> */}
                  Phone Number
                {/* </Link> */}
              </Grid>
              <Grid item xs={10} style={{ marginTop: '1.3%', marginLeft: '-5.5%', cursor: "pointer", }}>
                <Link to="UserPhoneNumberDetails" style={{ textDecoration: 'none', fontSize: 15, marginLeft: '10%' }}>
                  View List
                </Link>
              </Grid>
            </Grid>

            {/* <hr style={{width: "80%", marginTop:"2%",}}/> */}
            <Grid item xs={12} className={classes.itemContainer}>
              {/* <AddToQueueIcon/> Address: */}
              {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer",marginLeft: "1.7%"}} > */}
              <Grid item xs={1} style={{ marginTop: '1.5%', marginLeft: "10%" }} >
                {/* <Link to="UsersIdProofDetails"> */}
                  <BadgeOutlinedIcon className={classes.IconColor} />
                {/* </Link> */}
              </Grid>
              <Grid item xs={2} style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '-1.5%',textDecoration: 'none', color: 'black', fontSize: 15,}}>
                {/* <Link to="UsersIdProofDetails" style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}> */}
                  Add / Update ID Proofs
                {/* </Link> */}
              </Grid>
              <Grid item xs={10} style={{ marginTop: '1.3%', marginLeft: '-8%', cursor: "pointer", }}>
                <Link to="UsersIdProofDetails" style={{ textDecoration: 'none', fontSize: 15, marginLeft: '10%' }}>
                  View List
                </Link>
              </Grid>
            </Grid>

            {/* <hr style={{width: "80%", marginTop:"2%",}}/> */}
            {
              (role_of_user == "client") ?
                (
                  <Grid item xs={12} className={classes.itemContainer}>
                    {/* <AddToQueueIcon/> Address: */}
                    {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer",marginLeft: "-2.8%"}} > */}
                    <Grid item style={{ marginTop: '1.5%',  marginLeft: "13%" }} >
                      {/* <Link to="UserOtherContactDetails"> */}
                        <PermContactCalendarIcon className={classes.IconColor} />
                      {/* </Link> */}
                    </Grid>
                    <Grid item style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '1%', }}>
                      {/* <Link to="UserOtherContactDetails" style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}> */}
                        Your Contacts
                      {/* </Link> */}
                    </Grid>
                    <Grid item xs={9} style={{ marginTop: '1.0%', marginLeft: '-0.1%', cursor: "pointer", }}>
                      <Link to="UserOtherContactDetails" style={{ textDecoration: 'none', fontSize: 15, marginLeft: '10%' }}>
                        View List
                      </Link>
                    </Grid>
                  </Grid>
                )
                : (
                  <Grid></Grid>
                )
            }

            {
              (role_of_user == "client") ?
                (
                  <Grid item xs={12} className={classes.itemContainer}>
                    {/* <AddToQueueIcon/> Address: */}
                    {/* <Grid item  style={{marginTop: '1.5%', cursor: "pointer",marginLeft: "-2.8%"}} > */}
                    <Grid item style={{ marginTop: '1.5%', marginLeft: "13%" }} >
                      <Link >
                        <BadgeOutlinedIcon className={classes.IconColor} />
                      </Link>
                    </Grid>
                    <Grid container item style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '1%', }}>
                      <Grid style={{ color: 'black', fontSize: 15, }}>
                        PAN No :
                      </Grid>
                      <Grid style={{ color: 'black', fontSize: 17, marginLeft: "2%", }}>
                        {user_details.data.user_details.business_link.pan}
                      </Grid>
                    </Grid>
                  </Grid>
                )
                : (
                  <Grid></Grid>
                )
            }

            {/* <hr style={{width: "80%", marginTop:"2%",}}/> */}
            <Grid item xs={12} className={classes.itemContainer}>
              {/* <AddToQueueIcon/> Address: */}
              {/* <Grid item onClick={handelResetPasswordOpen}  style={{marginTop: '1.5%', cursor: "pointer", marginLeft: "-2%"}} > */}
              <Grid item onClick={handelResetPasswordOpen} style={{ marginTop: '1.5%', cursor: "pointer", marginLeft: "13%" }} >
                <VpnKeyIcon className={classes.IconColor} />
              </Grid>
              <Grid item onClick={handelResetPasswordOpen} style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '1.5%', cursor: "pointer", }}>
                Change Password
              </Grid>
            </Grid>
            <Modal
              open={open}
              onClose={handelResetPasswordClose}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>

            {/* slogin logs */}
            <Modal
            // title={'Title'} footer={'Footer'}
              open={OpenLoginLogs}
              onClose={handelShowLogsClose}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              
            >
              {bodyLoginLogs}
            </Modal>

            <Modal
              open={OpenTermsAndCondition}
              onClose={hideTermsAndConditions}
              className={classes.modalTandC}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {bodyTandC}
          </Modal>

            {/* {
              (role_of_user == "client") ?
                (
                  <Grid item xs={12} className={classes.itemContainer}>
                    <Grid item style={{ marginTop: '1.5%', cursor: "pointer", marginLeft: "13%" }} >
                      <Link >
                        <BusinessIcon className={classes.IconColor} />
                      </Link>
                    </Grid>
                    <Grid item style={{ color: 'black', fontSize: 15, marginTop: '1.7%', marginLeft: '1%', cursor: "pointer", }}>
                      <Link style={{ textDecoration: 'none', color: 'black', fontSize: 15, }}>
                        Switch Business
                      </Link>
                    </Grid>
                  </Grid>
                )
                : (<div></div>)
            } */}

          </Grid>
        </Paper>

      </div>
    </div>
  )
}