import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormattedMessage, injectIntl } from "react-intl";
import { authHeader } from '../../helpers';
import Swal from 'sweetalert2';
import { MentionsInput, Mention } from 'react-mentions';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import Modal from '@material-ui/core/Modal';
import { useSelector, useDispatch } from 'react-redux';
import useCheckSessionApi from "../../SessionValidity";
import { userActions } from "../../actions";

import _clone from 'lodash/clone'
import _escapeRegExp from 'lodash/escapeRegExp'
import _uniqBy from 'lodash/uniqBy'

import moment from 'moment';

import './mention-style.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContianer: {
    padding: theme.spacing(2),
  },
  leftAlign: {
    color: "rgba(0, 0, 0, 0.32)",
    textAlign: "left",
    
  },
  leftAlignWithoutColor: {
    textAlign: "left",
  },
  sentBy: {
    color: 'rgba(9, 8, 8, 0.98)',
    textAlign: 'left',
    fontSize: 15,
  },
  sentByDate: {
    color: 'rgba(0, 0, 0, 0.32)',
    textAlign: 'left',
  },
  msgDescription: {
    color: 'rgba(9, 8, 8, 0.98)',
    textAlign: 'left',
    fontSize: 17,
  },
  rightAlignWithoutColor: {
    textAlign: "right",
  },
  messageButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "#4D47DD",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  addMessageLabel: {
    textAlign: 'left',
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: "16px",
    fontWeight: 600,
  },
  inputPadding: {
    padding: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: '5%',
  },
  commentsTextarea: {
    backgroundColor: '#fff',
    borderRadius: '3px',
    transition: '0.3s ease-in-out',
    padding: '9px',
    fontSize: '14px',
    lineHeight: '1.42857143',
    color: '#333',
    border: '1px solid #dedede',
    // &:focus,&:active{
    //   outline: none;
    //   border: 1px solid #3fb9de;
    // }
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  paperModal: {
    position: 'absolute',
    width: 500,
    // height: 620,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // top:'2%',
    display: 'block'
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height: '12%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },

}));

function TaxManagementCasesMessages() {
  const classes = useStyles();
  const [ShowProcedure, setShowProcedure] = React.useState(false);
  const [caseForDetailsFromAsync, setcaseForDetailsFromAsync] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_details')));
  const [caseDetailsMessages, setcaseDetailsMessages] = React.useState([]);
  const [listOfContact, setlistOfContact] = React.useState([]);
  const [sendMsgTo, setsendMsgTo] = React.useState("");
  const [flag, setflag] = React.useState(false);
  const [sendNewMsgErr, setsendNewMsgErr] = React.useState("");

  const [openEditMsg, setopenEditMsg] = React.useState(false);
  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [editMessageDetails, seteditMessageDetails] = React.useState({
    case_id: "",
    message_id: "",
    message: "",
    is_deleted: false,
    business_id: business_id,
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });
  const [sendEditMsgErr, setsendEditMsgErr] = React.useState("");
  const dispatch = useDispatch();

  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page::::::",user_permissions)


  React.useEffect(() => {
    dispatch(userActions.checkSessionValidity());
    setflag(true);
    setShowProcedure(true);
    // to fetch case details of steps
    let case_id = caseForDetailsFromAsync.case_id;
    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'case_id': case_id, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };
    // console.log("requestOptions",requestOptions)
    fetch(process.env.REACT_APP_GET_CASE_MESSAGES, requestOptions)
      .then((Response) => Response.json())
      .then(async (caseActivityMessages) => {
        setShowProcedure(false);
        console.log("caseActivityMessages on show case details", caseActivityMessages);
        if (caseActivityMessages.success == true) {
          console.log("caseActivityMessages on show case details true", caseActivityMessages.data.case_activity);
          let case_messages = caseActivityMessages.data.case_activity;
          setcaseDetailsMessages(case_messages);

        }
        else if (caseActivityMessages.success == false) {
          setcaseDetailsMessages([]);
          Swal.fire({
            icon: 'error',
            text: caseActivityMessages.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    const requestOptionsTeam = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        'business_id': business_id,
        'device_token': device_token, 'session_id': session_id
      })
    };
    fetch(process.env.REACT_APP_GET_BUSINESS_TEAM, requestOptionsTeam)
      .then((Response) => Response.json())
      .then(async (businessTeamContact) => {
        // setShowProcedure(false);
        console.log("businessTeamContact on show case details", businessTeamContact);
        if (businessTeamContact.success == true) {
          console.log("businessTeamContact on show case details", businessTeamContact.data.business_team);
          let case_messages = businessTeamContact.data.business_team;
          // setlistOfContact(case_messages);
          await setlistOfContact(
            case_messages.map((f, index) => {
              // for (let index = 0; index < caseTags.length; index++) {
              if (f.email) {
                f.display = f.name;
                f.id = index
                // break;
              }
              else {
                f.selected = false;
              }
              // }
              return f;
            })
          )
        }
        else if (businessTeamContact.success == false) {
          setlistOfContact([]);
          Swal.fire({
            icon: 'error',
            text: businessTeamContact.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 9000,
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    // readItemFromStorage();
  }, [flag]);

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //   } catch (error) {
  //     console.log("ERROR:",error);        }
  // };

  const validate = () => {
    let sendNewMsgErr = '';

    if (!sendMsgTo) {
      sendNewMsgErr = "Please Enter Message"
    }

    if (sendNewMsgErr) {
      setsendNewMsgErr(sendNewMsgErr);
      return false;
    }
    return true;
  }

  const handleSendMessageCancel = () => {
    console.log("handleSendMessage", sendMsgTo)
    setsendMsgTo("");
    setsendNewMsgErr("");
  }

  const handleSendMessage = () => {
    // setShowProcedure(true);
    console.log("handleSendMessage", sendMsgTo)
    // @[Pradeep Kumar ](1)
    // const markup = sendMsgTo

    // const parser = (markup) => {
    //   return [...markup.matchAll(/@\[(.*?)]\((\d+)\)/g)]
    //   .reduce((a, v) => {
    //     a[v[2]] = (a[v[2]] || []).concat({id: +v[3], username: v[1], a: +v[4]})
    //     return a
    //   }, {})
    // }
    // console.log(parser(markup))

    // var arry=sendMsgTo.split("@")
    //  console.log('arry',arry)
    //  for(var a of arry){
    //   var value = a.substring(
    //   a.lastIndexOf("[") + 1, 
    //   a.lastIndexOf("]"))
    //  }
    //   console.log('value',value)

    // var arry2=sendMsgTo.split(")")
    // console.log('arry2',arry2)

    const isValid = validate();
    if (isValid) {
      let case_id = caseForDetailsFromAsync.case_id;
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));

      let caseMessageDetails = {
        case_id: case_id,
        message: sendMsgTo,
        is_deleted: false,
        'business_id': business_id,
        device_token: device_token,
        session_id: session_id,
      }
      console.log("caseMessageDetails", caseMessageDetails)

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(caseMessageDetails)
      };
      fetch(process.env.REACT_APP_UPDATE_CASE_MESSAGE, requestOptions)
        .then((Response) => Response.json())
        .then(async (caseMessageAdded) => {
          setShowProcedure(false);
          console.log("caseMessageAdded", caseMessageAdded);
          if (caseMessageAdded.success == true) {
            console.log("caseMessageAdded success", caseMessageAdded.data.comments_info);
            setsendMsgTo("");
            setsendNewMsgErr("");
          }
          else if (caseMessageAdded.success == false) {
            Swal.fire({
              icon: 'error',
              text: caseMessageAdded.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 9000,
            })
          }
          setflag(false);
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handleChangeMentionInput = (event, newPlainTextValue, newValue, mentions) => {
    console.log("event", event.target.value);
    console.log("newPlainTextValue", newPlainTextValue);
    console.log("newValue", newValue);
    console.log("mentions", mentions);
    // setsendMsgTo(event.target.value)
    setsendMsgTo(newValue)
  }

  // Edit msg after rply
  const handleEditMsgOpen = async (item) => {
    console.log("in edit msg", item)
    await seteditMessageDetails({
      ...editMessageDetails,
      case_id: caseForDetailsFromAsync.case_id,
      // message_id: item.message_id,
      // message: item.message,
    })
    setopenEditMsg(true)
  }

  const handleEditMsgClose = () => {
    setopenEditMsg(false)
    setsendEditMsgErr();
    seteditMessageDetails({ ...editMessageDetails, message: "" })
  }

  const handleChangeMentionInputEditMsg = async (event, newValue) => {
    console.log("event handleChangeMentionInputEditMsg", event.target.value);
    console.log("newValue handleChangeMentionInputEditMsg", newValue);
    await seteditMessageDetails({
      ...editMessageDetails,
      message: newValue,
    })
  }

  const validateEdit = () => {
    let sendEditMsgErr = '';

    if (!editMessageDetails.message) {
      sendEditMsgErr = "Please Enter Message"
    }

    if (sendEditMsgErr) {
      setsendEditMsgErr(sendEditMsgErr);
      return false;
    }
    return true;
  }

  const handelSubmitEditMessage = () => {
    console.log("Edit msg", editMessageDetails)
    const isValid = validateEdit();
    if (isValid) {
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(editMessageDetails)
      };
      return fetch(process.env.REACT_APP_UPDATE_CASE_MESSAGE, requestOptions)
        .then((Response) => Response.json())
        .then(async (msgEdited) => {
          setopenEditMsg(false)
          console.log("msgEdited ", msgEdited);
          if (msgEdited.success == true) {
            // Swal.fire({
            //   icon: 'success',
            //   text: "Message Edited Succesfully",
            //   confirmButtonColor: 'primary',
            //   confirmButtonText: 'OK',
            //   timer: 5000,
            // })
            await seteditMessageDetails({
              ...editMessageDetails,
              case_id: caseForDetailsFromAsync.case_id,
              message_id: "",
              message: "",
            })
            setsendEditMsgErr();
          }
          if (msgEdited.success == false) {
            Swal.fire({
              icon: 'error',
              text: msgEdited.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
          setflag(false)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const bodyEditMsg = (
    <div className={classes.paperModal}>
      <div className={classes.addNewBusinessHeaderBackgroud}>
        <h2>Reply</h2>
      </div>
      <div className={classes.modalBody}>
        <Grid item xs={12} className={classes.addMessageLabel}>
          Message
        </Grid>
        <MentionsInput className="mentions" value={editMessageDetails.message}
          autoFocus
          onChange={(event, newPlainTextValue, newValue, mentions) => handleChangeMentionInputEditMsg(event, newValue)}
        >
          <Mention
            className="mentions__mention"
            trigger="@"
            data={listOfContact}
            displayTransform={(id, display) => `@${display}`}
          />
        </MentionsInput>
        <div className={classes.validation}>{(editMessageDetails.message) ? (<div></div>) : (sendEditMsgErr)}</div>
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handelSubmitEditMessage}
          >
            Send
          </Button>
          <div style={{ marginLeft: '2%' }}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleEditMsgClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )

  const createdDateFunc = (date) => {
    console.log("date in createdDateFunc is msgs", date)
    let dateInString = JSON.stringify(date)
    
    console.log("date in straing is ", dateInString)
    
    var mydateIs = moment(dateInString, 'DD/MM/YYYY hh:mm A'); 
    console.log("formated date is ours",moment(mydateIs).format("MM/DD/YYYY hh:mm A"))
    var myDateWithFormatIs = moment(mydateIs).format("MM/DD/YYYY hh:mm A")
    console.log("myDateWithFormatIs",myDateWithFormatIs)
    console.log("date in format for  in table map is local", new Date(myDateWithFormatIs)) 
    
    var local_date= moment.utc(myDateWithFormatIs).local().format('MM/DD/YYYY hh:mm A');
    console.log("local_date using moments is",local_date)
    
    var local_date_DayFirst= moment.utc(myDateWithFormatIs).local().format('DD/MMM/YYYY hh:mm A');
    console.log("local_date day first",local_date_DayFirst)

    var forForNow= moment.utc(date, moment.defaultFormat).local().format('YYYY/MM/DD HH:mm');

    return(
      <Grid item container xs={12} className={classes.leftAlign} >
        <Grid style={{fontSize:14 }}>
          {local_date_DayFirst}
        </Grid>
        <Grid style={{fontSize:13 , alignSelf:'center'}}> 
          &nbsp;({moment(forForNow).fromNow()})
        </Grid>
      </Grid>
    )

  }

  let role_of_user = JSON.parse(localStorage.getItem('role_of_user'));
  console.log("role_of_user", role_of_user)

  if (role_of_user == "trp") {
    return (
      console.log("caseForDetailsFromAsync on case overview page,caseDetailsMessages,",
        caseForDetailsFromAsync, caseDetailsMessages),
      console.log("listOfContact,sendMsgTo", listOfContact, sendMsgTo),
      <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
          <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
        </modal> : null}
        <Grid container>
          {/* <Grid item xs={12} className={classes.marginBottom}>
            <Grid container>
              <Grid item xs={12} className={classes.addMessageLabel}>
                <FormattedMessage id="addMessageLabel" />
                Messages
              </Grid>
              <Grid item xs={12}> */}
          {/* send msg input */}
          {/* <MentionsInput className="mentions" value={sendMsgTo} 
                onChange={(event,newPlainTextValue,newValue,mentions) => handleChangeMentionInput(event,newPlainTextValue,newValue,mentions)}
              >
                <Mention
                className="mentions__mention"
                trigger="@"
                data={listOfContact}
                displayTransform={(id, display) => `@${display}`}
              />
              </MentionsInput>
              <div className={classes.validation} style={{textAlign:"left",marginTop:"-1%"}}>
                {(sendMsgTo)?(<div></div>):(sendNewMsgErr)}
              </div>
              <div style={{marginTop:"2%"}}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handleSendMessage()}
                >
                  <FormattedMessage id="sendLabel" />
                </Button>
              </div>
              </Grid>
            </Grid>
          </Grid> */}

          <Grid item xs={12}>
            {
              caseDetailsMessages.map((item, index) => (
                <Grid container className={classes.gridContianer}>
                  <Grid item xs={1} style={{alignSelf:'center'}}>
                    <Avatar src="/static/images/avatar/1.jpg" />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container>
                      <Grid item xs={12} className={classes.leftAlignWithoutColor}>
                        <Grid container item xs={12}>
                          <Grid className={classes.sentBy}>
                              {item.user_full_name} &nbsp;&nbsp;
                            </Grid>
                            <Grid className={classes.sentByDate}>
                              {createdDateFunc(item.created)}
                            </Grid>
                          {/* <Grid item xs={2} className={classes.rightAlignWithoutColor} 
                          onClick={() => handleEditMsgOpen(item)}>
                          <Button
                            className={classes.messageButton}
                          >
                            <FormattedMessage id="editLabel" />
                            Reply
                          </Button>
                        </Grid>                       */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.msgDescription}>
                        <div style={{ maxWidth: '100%', wordBreak: 'break-all' }}>
                          {item.message}
                        </div>
                      </Grid>
                      {/* <Grid item xs={12} className={classes.leftAlign}>
                        {"14 hours ago"}
                      </Grid> */}
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
          {/* <Modal
            open={openEditMsg}
            onClose={handleEditMsgClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyEditMsg}
          </Modal> */}
        </Grid>
      </div>
    );
  }

  // else if(role_of_user == "trpadmin" || role_of_user== "trpdoc" || role_of_user == "client"  ){
  else {
    // if (caseForDetailsFromAsync.case_curr_state == "CREATED"
    //   || caseForDetailsFromAsync.case_curr_state == "COMPLETED") {
    //   console.log("case status is CREATED in message")
    //   return (
    //     console.log("caseForDetailsFromAsync on case overview page,caseDetailsMessages,",
    //       caseForDetailsFromAsync, caseDetailsMessages),
    //     console.log("listOfContact,sendMsgTo", listOfContact, sendMsgTo),
    //     <div className={classes.root}>
    //       {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
    //         <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
    //       </modal> : null}
    //       <Grid container>
    //         {/* <Grid item xs={12} className={classes.marginBottom}>
    //           <Grid container>
    //             <Grid item xs={12} className={classes.addMessageLabel}>
    //               <FormattedMessage id="addMessageLabel" />
    //               Messages
    //             </Grid>
    //             <Grid item xs={12}> */}
    //         {/* send msg input */}
    //         {/* <MentionsInput className="mentions" value={sendMsgTo} 
    //               onChange={(event,newPlainTextValue,newValue,mentions) => handleChangeMentionInput(event,newPlainTextValue,newValue,mentions)}
    //             >
    //               <Mention
    //               className="mentions__mention"
    //               trigger="@"
    //               data={listOfContact}
    //               displayTransform={(id, display) => `@${display}`}
    //             />
    //             </MentionsInput>
    //             <div className={classes.validation} style={{textAlign:"left",marginTop:"-1%"}}>
    //               {(sendMsgTo)?(<div></div>):(sendNewMsgErr)}
    //             </div>
    //             <div style={{marginTop:"2%"}}>
    //               <Button
    //                 variant="contained"
    //                 color="primary"
    //                 className={classes.button}
    //                 onClick={() => handleSendMessage()}
    //               >
    //                 <FormattedMessage id="sendLabel" />
    //               </Button>
    //             </div>
    //             </Grid>
    //           </Grid>
    //         </Grid> */}

    //         <Grid item xs={12}>
    //           {
    //             caseDetailsMessages.map((item, index) => (
    //               <Grid container className={classes.gridContianer}>
    //                 <Grid item xs={1} style={{alignSelf:'center'}}>
    //                   <Avatar src="/static/images/avatar/1.jpg" />
    //                 </Grid>
    //                 <Grid item xs={11}>
    //                   <Grid container>
    //                     <Grid item xs={12} className={classes.leftAlignWithoutColor}>
    //                       <Grid container>
    //                         <Grid item xs={10}>
    //                           {item.user_full_name}
    //                         </Grid>
    //                         {/* <Grid item xs={2} className={classes.rightAlignWithoutColor} 
    //                         onClick={() => handleEditMsgOpen(item)}>
    //                         <Button
    //                           className={classes.messageButton}
    //                         >
    //                           <FormattedMessage id="editLabel" />
    //                           Reply
    //                         </Button>
    //                       </Grid>                       */}
    //                       </Grid>
    //                     </Grid>
    //                     <Grid item xs={12} className={classes.leftAlign}>
    //                       {item.message}
    //                     </Grid>
    //                     <Grid item xs={12} className={classes.leftAlign}>
    //                       {/* {"14 hours ago"} */}
    //                       {createdDateFunc(item.created)}
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                       <Divider />
    //                     </Grid>
    //                   </Grid>
    //                 </Grid>
    //               </Grid>
    //             ))
    //           }
    //         </Grid>
    //         {/* <Modal
    //           open={openEditMsg}
    //           onClose={handleEditMsgClose}
    //           className={classes.modal}
    //           aria-labelledby="simple-modal-title"
    //           aria-describedby="simple-modal-description"
    //         >
    //           {bodyEditMsg}
    //         </Modal> */}
    //       </Grid>
    //     </div>
    //   );
    // }

    // else 
    if (caseForDetailsFromAsync.case_curr_state == "IN-PROGRESS" || caseForDetailsFromAsync.case_curr_state == "PENDING"
      || caseForDetailsFromAsync.case_curr_state == "PAYMENT-PENDING" || caseForDetailsFromAsync.case_curr_state == "CLOSED"
      || caseForDetailsFromAsync.case_curr_state == "CREATED" || caseForDetailsFromAsync.case_curr_state == "COMPLETED") {
      console.log("case status is CONFIRMED in message")
      return (
        console.log("caseForDetailsFromAsync on case overview page,caseDetailsMessages,",
          caseForDetailsFromAsync, caseDetailsMessages),
        console.log("listOfContact,sendMsgTo", listOfContact, sendMsgTo),
        <div className={classes.root}>
          {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
          </modal> : null}
          <Grid container>

            <Grid item xs={12} className={classes.marginBottom}>
              <Grid container>
                <Grid item xs={12} className={classes.addMessageLabel}>
                  {/* <FormattedMessage id="addMessageLabel" /> */}
                  Message
                </Grid>

                {
                  (role_of_user && role_of_user == "client") ?
                    (


                      <Grid item xs={12}>
                        {/* send msg input */}
                        <MentionsInput className="mentions" value={sendMsgTo}
                          autoFocus
                          // onChange={event => setsendMsgTo(event.target.value)}
                          // onChange={(event,newPlainTextValue) => setsendMsgTo(newPlainTextValue)}
                          onChange={(event, newPlainTextValue, newValue, mentions) => handleChangeMentionInput(event, newPlainTextValue, newValue, mentions)}
                        >
                          <Mention
                            // style={{color: 'transparent'}}
                            className="mentions__mention"
                            trigger="@"
                            // markup="@[__display__](user:__id__)"
                            data={listOfContact}
                            displayTransform={(id, display) => `@${display}`}
                          />
                        </MentionsInput>
                        <div className={classes.validation} style={{ textAlign: "left", marginTop: "-1%" }}>
                          {(sendMsgTo) ? (<div></div>) : (sendNewMsgErr)}
                        </div>
                        <div style={{
                          marginTop: '2%', display: 'flex', alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                        }}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => handleSendMessage()}
                          >
                            <FormattedMessage id="sendLabel" />
                          </Button>
                          <div style={{ marginLeft: '2%' }}></div>
                          <Button
                            type="submit"
                            variant="contained"
                            className={classes.button}
                            onClick={() => handleSendMessageCancel()}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Grid>


                    ) :
                    (
                      <Grid item xs={12}>
                        {role_of_user && role_of_user !== "client" && user_permissions && user_permissions.permissions.send_messages &&
                          <Grid item xs={12}>
                            {/* send msg input */}
                            <MentionsInput className="mentions" value={sendMsgTo}
                              autoFocus
                              // onChange={event => setsendMsgTo(event.target.value)}
                              // onChange={(event,newPlainTextValue) => setsendMsgTo(newPlainTextValue)}
                              onChange={(event, newPlainTextValue, newValue, mentions) => handleChangeMentionInput(event, newPlainTextValue, newValue, mentions)}
                            >
                              <Mention
                                // style={{color: 'transparent'}}
                                className="mentions__mention"
                                trigger="@"
                                // markup="@[__display__](user:__id__)"
                                data={listOfContact}
                                displayTransform={(id, display) => `@${display}`}
                              />
                            </MentionsInput>
                            <div className={classes.validation} style={{ textAlign: "left", marginTop: "-1%" }}>
                              {(sendMsgTo) ? (<div></div>) : (sendNewMsgErr)}
                            </div>
                            <div style={{
                              marginTop: '2%', display: 'flex', alignItems: 'flex-end',
                              justifyContent: 'flex-end',
                            }}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => handleSendMessage()}
                              >
                                <FormattedMessage id="sendLabel" />
                              </Button>
                              <div style={{ marginLeft: '2%' }}></div>
                              <Button
                                type="submit"
                                variant="contained"
                                className={classes.button}
                                onClick={() => handleSendMessageCancel()}
                              >
                                Cancel
                              </Button>
                            </div>
                          </Grid>
                        }
                      </Grid>
                    )
                }

                {/* { role_of_user && role_of_user !== "client" && user_permissions && user_permissions.permissions.send_messages &&
                    <Grid item xs={12}>
                      
                      <MentionsInput className="mentions" value={sendMsgTo} 
                       
                        onChange={(event,newPlainTextValue,newValue,mentions) => handleChangeMentionInput(event,newPlainTextValue,newValue,mentions)}
                      >
                        <Mention
                         
                          className="mentions__mention"
                          trigger="@"
                          
                          data={listOfContact}
                          displayTransform={(id, display) => `@${display}`}
                        />
                      </MentionsInput>
                      <div className={classes.validation} style={{textAlign:"left",marginTop:"-1%"}}>
                        {(sendMsgTo)?(<div></div>):(sendNewMsgErr)}
                      </div>
                      <div style={{marginTop:'2%', display: 'flex',alignItems: 'flex-end', 
                        justifyContent: 'flex-end',}}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => handleSendMessage()}
                        >
                          <FormattedMessage id="sendLabel" />
                        </Button>
                        <div style={{marginLeft:'2%'}}></div>
                        <Button
                          type="submit"
                          variant="contained"
                          className={classes.button}
                          onClick={() => handleSendMessageCancel()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                  } */}

              </Grid>
            </Grid>

            <Grid item xs={12}>
              {
                caseDetailsMessages.map((item, index) => (
                  <Grid container className={classes.gridContianer}>
                    <Grid item xs={1} style={{alignSelf:'center'}}>
                      <Avatar src="/static/images/avatar/1.jpg" />
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid item xs={12} className={classes.leftAlignWithoutColor}>
                          <Grid container item xs={12}>
                            <Grid className={classes.sentBy}>
                              {item.user_full_name} &nbsp;&nbsp;
                            </Grid>
                            <Grid className={classes.sentByDate}>
                              {createdDateFunc(item.created)}
                            </Grid>
                            {/* <Grid item xs={2} className={classes.rightAlignWithoutColor} 
                            onClick={() => handleEditMsgOpen(item)}>
                            <Button
                              className={classes.messageButton}
                            >
                              Reply
                            </Button>
                            </Grid> */}
                              {/* <FormattedMessage id="editLabel" /> */}

                              {/* <Grid item xs={2} className={classes.rightAlignWithoutColor}>
                              <Button
                                className={classes.messageButton}
                              >
                                <FormattedMessage id="replyLabel" />
                              </Button>
                            </Grid> */}
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} className={classes.msgDescription}>
                          <div style={{ maxWidth: '100%', wordBreak: 'break-all' }}>
                            {item.message}
                          </div>
                        </Grid>
                        {/* <Grid item xs={12} className={classes.leftAlign}>
                          {"14 hours ago"}                        
                        </Grid> */}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
            <Modal
              open={openEditMsg}
              onClose={handleEditMsgClose}
              className={classes.modal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {bodyEditMsg}
            </Modal>
          </Grid>
        </div>
      );
    }
  }



}

export default injectIntl(TaxManagementCasesMessages);
