// ResetForgotPassword

import React from 'react';
import Avatar from "@material-ui/core/Avatar";
import { authHeader } from '../helpers';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { injectIntl, FormattedMessage } from "react-intl";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { history } from "../helpers";
import { Router, Route, Redirect} from "react-router-dom";
import Swal from 'sweetalert2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";
import { PropagateLoader } from 'react-spinners';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#001eaa',
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(../Image/loginBanner.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarLocked: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarSignIn: {
    margin: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftAlign: {
    textAlign: "left",
  },
  errorBlock: {
    // marginLeft: "5%",
    // marginRight: "5%",
    width: "100%",
    paddingTop: "2%",
    textAlign: "left",
  },
  overFlowHidden: {
    overflow: "hidden",
  },
  dontHaveAccText: {
    cursor: "pointer",
    color: "#3f51b5",
  },
  verifyLabel: {
    width: "100%",
    marginTop: theme.spacing(5),
    // textAlign: "left",
    fontSize: 20,
  },
  verifyText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 15,
  },
  didntOtpText: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "left",
    fontSize: 12,
  },
  resendText: {
    width: "100%",
    marginTop: "1%",
    textAlign: "left",
    fontSize: 12,
    cursor: "pointer",
    color: "#3f51b5",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },

}));



export default function ResetForgotPassword() {
  const classes = useStyles();
  const[ShowProcedure, setShowProcedure ] = React.useState(false);
  const [redirectLogin, setredirectLogin] = React.useState(false);
  const [emailForChangePassword, setemailForChangePassword] = React.useState(JSON.parse(localStorage.getItem('emailForChangePassword')));
  const [verificationCodeChangePassword, setverificationCodeChangePassword] = React.useState(JSON.parse(localStorage.getItem('verificationCodeChangePassword')));
  const [newPassword, setnewPassword] = React.useState("");
  const [confirmPassword, setconfirmPassword] = React.useState("");

  const[newPassErr, setnewPassErr] = React.useState("");
  const[confirmPassErr, setconfirmPassErr] = React.useState("");

  const[SubmitPassword, setSubmitPassword] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);

  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));

  const dispatch = useDispatch()

  React.useEffect(() => {
    // console.log("loggingIn in login",loggingIn)
    console.log("device_token in useeffect login",device_token)
    console.log("session_id in useeffect login",session_id)
    
    let user = JSON.parse(localStorage.getItem('user'));
    console.log("user in useeffect login",user)

    if( device_token !== null && session_id !== null && user !== null){
      console.log("device token , session id, user info found in useeffect of login")
      // Swal.fire({
      //   icon: 'error',
      //   text: "You are already logged in in app",
      //   confirmButtonColor: '#d33',
      //   confirmButtonText: 'OK'
      // })
      dispatch(userActions.logout());
    }
    else{
      console.log("device token , session id, user info is empty in useeffect of login")
    }

  }, []);
  
  const validate = () => {
    let newPassErr = '';
    let confirmPassErr = '';

    if(!newPassword)  {
        newPassErr = 'New password is require';
    }
    else if(newPassword)  {
      if(newPassword.length < 8){
        newPassErr = 'Password must contain atleast 8 characters';
      }
    }

    if(!confirmPassword)  {
        confirmPassErr = 'Confirm password is require';
    }
    if(newPassword && confirmPassword){
        if( newPassword !== confirmPassword)
        confirmPassErr = 'New password and confirm password is not matching';
    }
    // if(newPassword !== confirmPassword){
    //     // confirmPassErr = 'Passwords not matched';
    //     confirmPassErr = 'New password and confirm password is not matching';
    //   }

      if(newPassErr || confirmPassErr){
        setnewPassErr(newPassErr);
        setconfirmPassErr(confirmPassErr);
        return false;
      }
  
      return true;

  }

  const submitNewPassFunc = () => {
    setSubmitPassword(true);
    const isValid = validate();
    if(isValid){
      console.log("submit pass func",newPassword,confirmPassword);
      setShowProcedure(false);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'email': emailForChangePassword, 'verification_code': verificationCodeChangePassword,
         'newpassword': confirmPassword })
      };
      fetch( process.env.REACT_APP_RESET_PASSWORD , requestOptions)
      .then((Response) => Response.json())
      .then(newPasswordDetails => {
        setShowProcedure(false);
        console.log("newPasswordDetails",newPasswordDetails);
        if(newPasswordDetails.success == true )
        {
          Swal.fire({
            icon: 'success',
            text: "Password Reset Succesfully",
            confirmButtonColor: 'primary',
            confirmButtonText: 'OK'
          })
          setredirectLogin(true);
        }
        else
        {
          Swal.fire({
            icon: 'error',
            text: newPasswordDetails.errors,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  
  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
    

  if(redirectLogin == true){
    return(
      <Redirect to={'/login'}/>
    )
  }
  else{
  return (
    console.log("emailForChangePassword, verificationCodeChangePassword", emailForChangePassword,verificationCodeChangePassword),
    <div>
      {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
        <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </modal>: null}
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className={classes.overFlowHidden}
      >
        <div className={classes.paper} style={{marginTop:'30%'}}>
            {/* <Avatar className={classes.avatarLocked}>
              <LockOutlinedIcon />
            </Avatar> */}
          <Typography component="h1" variant="h5">
            Enter New Password
          </Typography>
          <div>
              <div>
                <div className={classes.form}>
                  {/* <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="newPassword"
                    label="New Password"
                    name="newPassword"
                    autoComplete="newPassword"
                    autoFocus
                    value={newPassword}
                    // type="number"
                    onChange={(e) => setnewPassword(e.target.value) }
                  />   */}
                  <ThemeProvider theme={theme} >
                    <FormControl sx={{ width: '100%' }} variant="outlined" style={{marginTop:'4.5%'}}>
                      <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                      <OutlinedInput
                        color="primary"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={
                                showPassword ? 'hide the password' : 'display the password'
                              }
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseUpPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ maxLength: 8 }}
                        required
                        placeholder='New Password'
                        name="newPassword"
                        id="newPassword"
                        label="newPassword"
                        value={newPassword}
                        autoFocus
                        onChange={(e) => setnewPassword(e.target.value) }
                      />
                    </FormControl>
                  </ThemeProvider>

                  {/* <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    autoComplete="confirmPassword"
                    // autoFocus
                    value={confirmPassword}
                    // type="number"
                    onChange={(e) => setconfirmPassword(e.target.value) }
                  />   */}
                  <ThemeProvider theme={theme} >
                    <FormControl sx={{ width: '100%' }} variant="outlined" style={{marginTop:'4.5%'}}>
                      <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                      <OutlinedInput
                        color="primary"
                        type={showPasswordConfirm ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label={
                                showPasswordConfirm ? 'hide the password' : 'display the password'
                              }
                              onClick={handleClickShowPasswordConfirm}
                              onMouseDown={handleMouseDownPassword}
                              onMouseUp={handleMouseUpPassword}
                              edge="end"
                            >
                              {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        inputProps={{ maxLength: 8 }}
                        required
                        placeholder='Confirm Password'
                        name="confirmPassword"
                        id="confirmPassword"
                        label="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setconfirmPassword(e.target.value) }
                      />
                    </FormControl>
                  </ThemeProvider>          

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={submitNewPassFunc}
                  >
                    Submit New Password
                  </Button>
                </div>

                <Grid item xs={12}>
                  <div>
                    {
                      (SubmitPassword == true && newPassErr)?
                      (
                        <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          <div>
                            {newPassErr}
                          </div>
                        </Alert>
                      </div>
                      ):
                      (<div></div>)
                    }

                    {
                      (SubmitPassword == true && confirmPassErr)?
                      (
                        <div className={classes.errorBlock}>
                        <Alert variant="filled" severity="error">
                          <div>
                            {confirmPassErr}
                          </div>
                        </Alert>
                      </div>
                      ):
                      (<div></div>)
                    }

                  </div>
                </Grid>
              </div>
          </div> 
        </div>
      </Grid>
    </Grid>
    </div>  
  );
  }
}