// NoticeHistory
import React, { useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TaxManagementRecentCases from "../TaxManagementRecentCases";
import { Link , Route, Redirect} from 'react-router-dom';
import SearchView from "../SearchView";
import { FormattedMessage } from "react-intl";
import { userActions } from "../actions";
import Grid from "@material-ui/core/Grid";
import useCheckSessionApi from "../SessionValidity";
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import { set } from "lodash";
import { PropagateLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import TextField from "@material-ui/core/TextField";
import { Multiselect } from 'react-widgets';
import "react-widgets/styles.css";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

import UserNoticeHistory from './UserNoticeHistory';
import BusinessNoticeHistory from './BusinessNoticeHistory';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    wrapper: {
      margin: "10%",
      marginRight: "5%",
      marginTop: "7%",
      marginBottom: "3%",
    },
  
    pageHeaderLabel: {
      fontFamily: [
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.64)",
      textAlign: "left",
    },
    pageHeaderContainer: {
      marginBottom: "5%",
    },
    showProcedureStyle: {
      position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%',
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    validation: {
      color: 'red',
      fontSize: 12,
    },
    backButton: {
        padding: 0,
        lineHeight: 0,
        textTransform: "none",
        color: "rgba(0, 0, 0, 0.64)",
        fontSize: "16px",
        fontWeight: 600,
        fontFamily: [
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
        ].join(","),
      },
      backArrow: {
        marginRight: "10%",
      },
      tabCustomStyle: {
        textTransform: "none",
      },
      customPaperStyle: {
        borderRadius: "10px",
        // marginLeft: "3%",
      },
      casesDetailSubMenu: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.32)",
      },
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  export default function TaxManagementNoticeBoard(props) {
    const classes = useStyles();
    const[ShowProcedure, setShowProcedure ] = React.useState(false);
    const [device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
    const [Flag, setFlag] = React.useState(false);
    const [value, setValue] = React.useState(0);
    

    // redux
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(userActions.checkSessionValidity());
        setShowProcedure(true);
        setShowProcedure(false);
    }, []);

    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
  };

  let user_details = JSON.parse(localStorage.getItem('users'));
  console.log("user details on notice board", user_details.data.user_details.first_name, user_details)
  let User_Notice_History = "Sent By:" + ' ' + user_details.data.user_details.first_name
    return (
        <div className={classes.root}>
            {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
          <div className={classes.wrapper}>
            <Grid container>
              <Grid item xs={1} className={classes.pageHeaderLabel} >
                <Button href="/TaxManagementNoticeBoard" className={classes.backButton}>
                    <span className={classes.backArrow}>
                    <ArrowBackIcon fontSize="small" />
                  </span>
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid container className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
                <Grid item xs={12} className={classes.pageHeaderLabel}>
                    Notice History
                </Grid>
            </Grid>

            <Grid item xs={12} style={{marginTop:'-3%'}}>
                <Paper elevation={0} className={classes.customPaperStyle}>
                    <div className={classes.casesDetailSubMenu}>
                        <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            >
                            <Tab
                                className={classes.tabCustomStyle}
                                label={User_Notice_History}
                            />                
                            <Tab
                                className={classes.tabCustomStyle}
                                label="All Notices"
                            />
                        </Tabs>
                    </div>
                    <TabPanel value={value} index={0}>
                        <UserNoticeHistory />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <BusinessNoticeHistory />
                    </TabPanel>
                </Paper>
              </Grid>
            

          </div>
        </div>
    )
  }