import React, { useEffect } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import PersonIcon from '@material-ui/icons/Person';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TableChartIcon from '@material-ui/icons/TableChart';
import BusinessIcon from "@material-ui/icons/Business";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import FeedbackIcon from '@material-ui/icons/Feedback';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
// import SwitchAccessShortcutIcon from "@material-ui/icons/Switch"
import ExploreIcon from '@material-ui/icons/Explore';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import ProfilePicture from "../ProfilePicture";
import Communication from "../Communication";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector, useDispatch } from 'react-redux';
import NoteIcon from '@material-ui/icons/Note';
import GroupIcon from '@material-ui/icons/Group';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import MessageIcon from '@material-ui/icons/Chat';
import { Redirect } from "react-router-dom";

import TakeFeedback from '../TakeFeedback';

import { updateCaseActionReset } from "../actions/updatecase.actions";

import { useThemeContext, useThemeUpdate } from '../ThemeContext'

// import { StickyContainer, Sticky } from 'react-sticky';

// import SessionValidity from '../SessionValidity';
import useCheckSessionApi from "../SessionValidity";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#4D47DD",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    position: 'relative',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textAlignLeft: {
    textAlign: "left",
  },
  alignItemsCenter: {
    alignItems: "Center",
  },
}));


function TaxManagementHeader({ intl,users,props }) {

  const TaxManagementHeaderContext = React.createContext();
  const UserDispatchContext = React.createContext();
  const [drawerOpenCloseHere, setdrawerOpenCloseHere] = React.useState();

  const darkTheme = useThemeContext()
  const toggleTheme = useThemeUpdate()

  const themeStyles = {
    // backgroundColor: darkTheme ? '#333' : '#CCC',
    // color: darkTheme ? '#CCC' : '#333',
    backgroundColor: darkTheme ? 'white' : 'white',
    color: darkTheme ? 'white' : 'white',
    padding: '2rem',
    margin: '2rem',
  }

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [role_of_user, setrole_of_user] = React.useState();
  const [UserDetailsFromRedux, setUserDetailsFromRedux] = React.useState();

  const valuesFromRedux = useSelector(state => state);
  const roleOfUserFromRedux = useSelector(state => state.loginDetails.logedInRole);
  const userGetAllDetails = useSelector(state => state.users);

  const [DeviceToken, setDeviceToken] =   React.useState();
  const [SessionId, setSessionId] =   React.useState();

  const [flag, setflag] = React.useState(false);
  const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
  const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
  const userInfoFromReduxLogDetails = useSelector(state => state.loginDetails.logedInUsersDetails);
  let user = JSON.parse(localStorage.getItem('user'));
  let user_permissions = JSON.parse(localStorage.getItem('user_permissions'))
  // console.log("user_permissions from local storage on header page:::::: header",user_permissions)

  const[CategoryCreation, setCategoryCreation] = React.useState([]);

  let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
  // console.log('Check this for business_id on header',business_id);

  // T and c
  const [ TermsAndConditionAccepted, setTermsAndConditionAccepted ] = React.useState(false);

  //
  const dispatch = useDispatch();
  const updateCaseActionResetRedux = () => dispatch(updateCaseActionReset());

  const handleDrawerOpen = async() => {
    setOpen(true);
    await localStorage.setItem('drawerOpenClose', JSON.stringify(true));
    setdrawerOpenCloseHere(true);
    setflag(false);
  };

  const handleDrawerClose = async() => {
    setOpen(false);
    await localStorage.setItem('drawerOpenClose', JSON.stringify(false));
    setdrawerOpenCloseHere(false);
    setflag(true)
  };


  useEffect(() => {
    setflag(true)
    getReduxValues();
    readItemFromStorage();
    localStorage.setItem('drawerOpenClose', JSON.stringify(false));
    console.log("in useeffect of taxmgmtheader")

    // localStorage.setItem('pageNumberOfTableCasesTableTrpAdmin', 0);
    // localStorage.setItem('rowsPerPageOfTableCasesTableTrpAdmin',5);
     
    // if(roleOfUserFromRedux !== undefined ){
    //   setrole_of_user(roleOfUserFromRedux)
    // }

    // console.log("in check ForCategory Empty,deviceTokenFromRedux,sessionIdFromRedux",
    // userGetAllDetails,deviceTokenFromRedux,sessionIdFromRedux)
    // if( roleOfUserFromRedux == "trpadmin"){
    //   console.log("trpadmin business id in header",userGetAllDetails.items.data.user_details.business.business_id)
    //   let business_id = userGetAllDetails.items.data.user_details.business.business_id;
    //   const requestOptions = {
    //     method: 'POST',
    //     headers: authHeader(),
    //     body: JSON.stringify({'business_id': business_id,
    //       'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux })
    //   };
    //   fetch( process.env.REACT_APP_GET_ALL_CATEGORY_INFO , requestOptions)
    //     .then((Response) => Response.json())
    //     .then( async(categoryDetails) => {
    //       console.log("categoryDetails",categoryDetails);
    //       if(categoryDetails.success == true)
    //       {
    //         setCategoryCreation(categoryDetails.data.category_tree);
    //       }
    //       else if(categoryDetails.success == false){
    //         setCategoryCreation([]);
    //       }
    //   })
    //   .catch(err => {
    //     setCategoryCreation([]);
    //     Swal.fire({
    //       icon: 'error',
    //       text: "Server Error. Please try again.",
    //       confirmButtonColor: '#d33',
    //       confirmButtonText: 'OK'
    //     })
    //   });
    // }

  }, [flag]);

  const readItemFromStorage = async() => {
        try {
             setDeviceToken(deviceTokenFromRedux)
             setSessionId(sessionIdFromRedux)
            setflag(false)
            // callCjeckSession()
            // const resp =  checkSessionValidity

        } catch (error) {
            console.log("ERROR:",error);
        }
  };

  const getReduxValues = async() => {
    await setrole_of_user(roleOfUserFromRedux)
    await setUserDetailsFromRedux(userGetAllDetails)

    // checkForCategoryEmpty();
  }

  const checkForCategoryEmpty = () => {
    console.log("in check ForCategory Empty,deviceTokenFromRedux,sessionIdFromRedux",
    userGetAllDetails,deviceTokenFromRedux,sessionIdFromRedux )
    if( roleOfUserFromRedux == "trpadmin"){
      console.log("trpadmin business id in header",userGetAllDetails.items.data.user_details.business.business_id)
      let business_id = userGetAllDetails.items.data.user_details.business.business_id;
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'business_id': business_id,
          'device_token': deviceTokenFromRedux, 'session_id': sessionIdFromRedux })
      };
      fetch( process.env.REACT_APP_GET_ALL_CATEGORY_INFO , requestOptions)
        .then((Response) => Response.json())
        .then( async(categoryDetails) => {
          console.log("categoryDetails",categoryDetails);
          if(categoryDetails.success == true)
          {
            setCategoryCreation(categoryDetails.data.category_tree);
          }
          else if(categoryDetails.success == false){
            setCategoryCreation([]);
          }
      })
      .catch(err => {
        setCategoryCreation([]);
        Swal.fire({
          icon: 'error',
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }
  }

  const TaxManagementClientCasesFunc = () => {
    console.log("in TaxManagementClientCasesFunc")
    // updateCaseActionResetRedux();
  }

  const setPageForCaseTable = () => {
    console.log("in func setPageForCaseTable")
    // localStorage.setItem('pageNumberOfTableCasesTableTrpAdmin', 0);
  }

  const handleClick = (item) => {
    console.log("handleClick =",item)
    if(item === "client"){
      setflag(true)
      // return(
      //   // <Redirect to={`/client`} />
      //   // to="/client"

      //   // <Link
      //   //   to={{
      //   //     pathname: "/client",
      //   //     // search: "?query=string",
      //   //     // hash: "#hash",
      //   //   }}
      //   // />

      // )
    }
  }

  return (
    // console.log("userGetAllDetails in header",userGetAllDetails),
    // console.log("userGetAllDetails business name in header",userGetAllDetails && userGetAllDetails.items.data),
    // console.log("deviceTokenFromRedux in header",deviceTokenFromRedux),
    console.log("userInfoFromReduxLogDetails",userInfoFromReduxLogDetails ),
    // console.log("header drawerOpenClose",JSON.parse(localStorage.getItem('drawerOpenClose'))),
    // console.log("UserDetailsFromRedux in header",UserDetailsFromRedux),
    // console.log("role_of_user in Header,role_of_user",roleOfUserFromRedux,role_of_user),
    // checkForCategoryEmpty(),
    console.log("in header file" ),

    <div className={classes.root}>
      {/* <TaxManagementHeaderContext.Provider value={drawerOpenCloseHere}>
        <UserDispatchContext.Provider value={setdrawerOpenCloseHere}>
          {children}
        </UserDispatchContext.Provider>
      </TaxManagementHeaderContext.Provider> */}
      {
        ( roleOfUserFromRedux == "sysadmin" || role_of_user == "sysadmin" ) ?
        (
          <div>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <Grid container>
                  <Grid item xs={11}>
                    <Grid container className={classes.alignItemsCenter}>
                      <Grid item className={classes.textAlignLeft} onClick={toggleTheme}>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerOpen}
                          // onClick={toggleTheme}
                          // onClick={ () => {
                          //   handleDrawerOpen();
                          //   toggleTheme();
                          // }}
                          edge="start"
                          className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                          })}
                        >
                          <MenuIcon />
                        </IconButton>
                      </Grid>
                      <Grid item className={classes.textAlignLeft}>
                        {
                          ( open == true )?
                          (
                            <Typography variant="h6" noWrap style={{marginTop:'4%', }}>
                              <FormattedMessage id="taxManagementHeader" />
                            </Typography>
                          )
                          :(
                              <Typography variant="h6" noWrap>
                                <FormattedMessage id="taxManagementHeader" />
                              </Typography>
                          )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={1}>
                    <TakeFeedback
                      users={users}
                    />
                  </Grid> */}
                  <Grid item xs={1}>
                    <ProfilePicture
                    users={users}
                    />
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              {/* <div className={classes.toolbar}>
                <FormattedMessage id="clickToMinimizeLabel" />
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div> */}
              <div className={classes.toolbar} onClick={toggleTheme}>
                <FormattedMessage id="clickToMinimizeLabel" />
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                    <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                      borderRadius: '50%',}}>
                      <ChevronRightIcon style={{color:'white',marginTop:'10%'}}/>
                    </div>
                  ) : (
                    // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                    <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                      borderRadius: '50%',}}>
                      <ChevronLeftIcon style={{color:'white',marginTop:'15%', marginLeft:'15%'}}/>
                    </div>
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                    <ListItem
                      button
                      key="search"
                      component={Link}
                      to="/search"
                      value="/search"
                      elementype="div"
                    >
                      <Tooltip
                        title={intl.formatMessage({ id: "searchLink" })}
                        placement="right-start"
                        className={clsx({
                          [classes.hide]: open,
                        })}
                      >
                        <ListItemIcon>
                          <FindInPageIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemIcon
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      >
                        <FindInPageIcon />
                      </ListItemIcon>
                      <ListItemText primary={intl.formatMessage({ id: "searchLink" })} />
                    </ListItem>

                <ListItem
                  button
                  key="Businesses"
                  component={Link}
                  to="/Businesses"
                  value="/Businesses"
                  elementType="div"
                >
                  <Tooltip
                    // title={intl.formatMessage({ id: "newClientLink" })}
                    title="Businesses"
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <BusinessCenterIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <BusinessCenterIcon />
                  </ListItemIcon>
                  <ListItemText primary="Businesses" />
                </ListItem>

                <ListItem
                  button
                  key="Feedback"
                  component={Link}
                  to="/Feedback"
                  value="/Feedback"
                  elementType="div"
                >
                  <Tooltip
                    // title={intl.formatMessage({ id: "newClientLink" })}
                    title="Feedback"
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <FeedbackIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <FeedbackIcon />
                  </ListItemIcon>
                  <ListItemText primary="Feedback" />
                </ListItem>

                {/* T and C */}
                <ListItem
                  button
                  key="TermsAndConditions"
                  component={Link}
                  to="/TermsAndConditions"
                  value="/TermsAndConditions"
                  elementType="div"
                >
                  <Tooltip
                    // title={intl.formatMessage({ id: "newClientLink" })}
                    title="Terms And Conditions"
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <PlaylistAddCheckIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <PlaylistAddCheckIcon />
                  </ListItemIcon>
                  <ListItemText primary="Terms And Conditions" />
                </ListItem>

              </List>

            </Drawer>
          </div>
        ):
        (<div></div>)
      }
      {
        ( roleOfUserFromRedux == "business-user" || role_of_user == "business-user" )?
        (
          
        //   for t and c
        //   <div>
        //   {
        //     ( TermsAndConditionAccepted === true )?
        //     (
        //       <div>
        //         {
                  
        //         }
        //       </div>
        //     ):
        //     (
        //       <div>
        //         <text> show tandc to accepts </text>
        //       </div>
        //     )
        //   }
        // </div>
        // for t and c

          // ( userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.is_active == true )?
          ( userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business.business_is_active == true)?
          (
            <div>
              {console.log("in role business-user of taxmgmtheader")}
              {setPageForCaseTable()}
              <CssBaseline />
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <Grid container className={classes.alignItemsCenter}>
                        <Grid item xs={1} className={classes.textAlignLeft} onClick={toggleTheme}>
                          {/* <button onClick={toggleTheme}>Toggle Theme</button> */}
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}

                            // onClick={ () => {
                            //   handleDrawerOpen();
                            //   toggleTheme;
                            // }}
                            edge="start"
                            className={clsx(classes.menuButton, {
                              [classes.hide]: open,
                            })}
                          >
                            <MenuIcon />
                          </IconButton>
                        </Grid>
                        <Grid item className={classes.textAlignLeft}>
                          {
                          ( open == true )?
                          (
                            <Typography variant="h6" noWrap style={{marginTop:'4%', marginLeft:'-30%'}}>
                              {/* <FormattedMessage id="taxManagementHeader" /> */}
                              {userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business.business_name}
                            </Typography>
                          )
                          :(
                              <Typography variant="h6" noWrap>
                                {/* <FormattedMessage id="taxManagementHeader" /> */}
                                {userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business.business_name}
                              </Typography>
                          )
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={1}>
                      <TakeFeedback
                      users={users}
                      />
                    </Grid> */}
                    {/* <Grid item xs={1}>
                      <Communication
                      users={users}
                      />
                    </Grid> */}
                    <Grid item xs={1}>
                      <ProfilePicture
                      users={users}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
              >
                <div className={classes.toolbar} onClick={toggleTheme}>
                  {/* <StickyContainer>
                    <Sticky> */}
                      <FormattedMessage id="clickToMinimizeLabel" />
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                          // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                          <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                            borderRadius: '50%',}}>
                            <ChevronRightIcon style={{color:'white',marginTop:'10%'}}/>
                          </div>
                        ) : (
                          // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                          <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                            borderRadius: '50%',}}>
                            <ChevronLeftIcon style={{color:'white',marginTop:'15%', marginLeft:'15%'}}/>
                          </div>
                        )}
                      </IconButton>
                    {/* </Sticky>
                  </StickyContainer> */}

                </div>
                <Divider />
                <List>

                {user_permissions && user_permissions.permissions.search &&
                    <ListItem
                      button
                      key="search"
                      component={Link}
                      to="/search"
                      value="/search"
                      elementype="div"
                      
                    >
                      <Tooltip
                        title={intl.formatMessage({ id: "searchLink" })}
                        placement="right-start"
                        className={clsx({
                          [classes.hide]: open,
                        })}
                      >
                        <ListItemIcon>
                          <FindInPageIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemIcon
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      >
                        <FindInPageIcon />
                      </ListItemIcon>
                      <ListItemText primary={intl.formatMessage({ id: "searchLink" })} />
                    </ListItem>

                }

                {user_permissions && user_permissions.permissions.view_client_cases &&
                  <ListItem
                    button
                    key="cases"
                    component={Link}
                    to="/cases"
                    value="/cases"
                    elementType="div"
                  >
                    <Tooltip
                      title={intl.formatMessage({ id: "casesLink" })}
                      placement="right-start"
                      className={clsx({
                        [classes.hide]: open,
                      })}
                    >
                      <ListItemIcon>
                        <PersonalVideoIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemIcon
                      className={clsx({
                        [classes.hide]: !open,
                      })}
                    >
                      <PersonalVideoIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "casesLink" })} />
                  </ListItem>
              }
                { user_permissions && user_permissions.permissions.view_clients &&
                  <ListItem
                    button
                    key="client"
                    component={Link}
                    to="/client"
                    value="/client"
                    elementType="div"
                    // onClick={() => handleClick("client")}
                  >
                    <Tooltip
                      title="Clients"
                      placement="right-start"
                      className={clsx({
                        [classes.hide]: open,
                      })}
                    >
                      <ListItemIcon>
                        {/* <PersonIcon /> */}
                        <GroupIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemIcon
                      className={clsx({
                        [classes.hide]: !open,
                      })}
                    >
                      {/* <PersonIcon /> */}
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "clientsLink" })} />
                  </ListItem>
                }

                  {/* <ListItem
                    button
                    key="alerts"
                    component={Link}
                    to="/alerts"
                    value="/alerts"
                    elementType="div"
                  >
                    <Tooltip
                      title={intl.formatMessage({ id: "alertLink" })}
                      placement="right-start"
                      className={clsx({
                        [classes.hide]: open,
                      })}
                    >
                      <ListItemIcon>
                        <AnnouncementIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemIcon
                      className={clsx({
                        [classes.hide]: !open,
                      })}
                    >
                      <AnnouncementIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "alertLink" })} />
                  </ListItem> */}

                </List>
                <Divider />
                <List>
                  {/* {
                    ( CategoryCreation.length == 0)?
                    (
                      <div></div>
                    )
                    :(
                      <List> */}
                      {user_permissions && user_permissions.permissions.create_case &&
                        <ListItem
                          button
                          key="NewCase"
                          component={Link}
                          to="/newcase"
                          value="/newcase"
                          elementType="div"
                        >
                        <Tooltip
                          title={intl.formatMessage({ id: "newCaseLink" })}
                          placement="right-start"
                          className={clsx({
                            [classes.hide]: open,
                          })}
                        >
                          <ListItemIcon>
                            <AddToQueueIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemIcon
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          <AddToQueueIcon />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: "newCaseLink" })} />
                        </ListItem>
                        }
                        {user_permissions && user_permissions.permissions.create_client &&
                            <ListItem
                              button
                              key="NewClient"
                              component={Link}
                              to="/newclient"
                              value="/newclient"
                              elementType="div"
                            >
                            <Tooltip
                              title={intl.formatMessage({ id: "newClientLink" })}
                              placement="right-start"
                              className={clsx({
                                [classes.hide]: open,
                              })}
                            >
                              <ListItemIcon>
                                <PersonAddIcon />
                              </ListItemIcon>
                            </Tooltip>
                            <ListItemIcon
                              className={clsx({
                                [classes.hide]: !open,
                              })}
                            >
                              <PersonAddIcon />
                            </ListItemIcon>
                            <ListItemText primary={intl.formatMessage({ id: "newClientLink" })} />
                          </ListItem>
                        }
                    {/* </List>
                    )
                  } */}

                  {/* from sanyukta */}
                  <Divider />

                  {user_permissions && user_permissions.permissions.business_dashboard &&
                          <ListItem
                            button
                            key="BusinessDashboard"
                            component={Link}
                            to="/BusinessDashboard"
                            value="/BusinessDashboard"
                            elementType="div"
                          >
                            <Tooltip
                              // title={intl.formatMessage({ id: "newClientLink" })}
                              // title="Business Dashboard"
                              title= "Administration"
                              placement="right-start"
                              className={clsx({
                                [classes.hide]: open,
                              })}
                            >
                              <ListItemIcon>
                                <BusinessIcon />
                              </ListItemIcon>
                            </Tooltip>
                            <ListItemIcon
                              className={clsx({
                                [classes.hide]: !open,
                              })}
                            >
                              <BusinessIcon />
                            </ListItemIcon>
                            <ListItemText primary="Business Dashboard" />
                          </ListItem>
                    }
                  <Divider />

                  {user_permissions && user_permissions.permissions.explorer &&
                      <ListItem
                        button
                        key="TaxManagementExplorer"
                        component={Link}
                        to="/TaxManagementExplorer"
                        value="/TaxManagementExplorer"
                        elementType="div"
                      >
                        <Tooltip
                          title="Explorer"
                          placement="right-start"
                          className={clsx({
                            [classes.hide]: open,
                          })}
                        >
                          <ListItemIcon>
                            <ExploreIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemIcon
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          <ExploreIcon />
                        </ListItemIcon>
                        <ListItemText primary="Explorer" />
                      </ListItem>
                  }

                  {/*service explorer  */}
                  {user_permissions && user_permissions.permissions.service_explorer &&
                      <ListItem
                        button
                        key="TaxManagementServiceExplorer"
                        component={Link}
                        to="/TaxManagementServiceExplorer"
                        value="/TaxManagementServiceExplorer"
                        elementType="div"
                      >
                        <Tooltip
                          title="Service Explorer"
                          placement="right-start"
                          className={clsx({
                            [classes.hide]: open,
                          })}
                        >
                          <ListItemIcon>
                            <AccountTreeIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemIcon
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          <AccountTreeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Service Explorer" />
                      </ListItem>
                    }

                    {user_permissions && user_permissions.permissions.create_process &&
                      <ListItem
                        button
                        key="TaxManagementProcessCreation"
                        component={Link}
                        to="/TaxManagementProcessCreation"
                        value="/TaxManagementProcessCreation"
                        elementType="div"
                      >
                        <Tooltip
                          title="Create Process"
                          placement="right-start"
                          className={clsx({
                            [classes.hide]: open,
                          })}
                        >
                          <ListItemIcon>
                            <LinearScaleIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemIcon
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          <LinearScaleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create Process" />
                      </ListItem>
                  }
                  {user_permissions && user_permissions.permissions.create_categories &&
                      <ListItem
                        button
                        key="TaxManagementCategoryCreation"
                        component={Link}
                        to="/TaxManagementCategoryCreation"
                        value="/TaxManagementCategoryCreation"
                        elementType="div"
                      >
                        <Tooltip
                          title="Create Category"
                          placement="right-start"
                          className={clsx({
                            [classes.hide]: open,
                          })}
                        >
                          <ListItemIcon>
                            <DeviceHubIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemIcon
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          <DeviceHubIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create Category" />
                      </ListItem>
                  }
                  {/* {user_permissions && user_permissions.permissions.create_roles && */}
                  {user_permissions && user_permissions.permissions.roles &&
                    <ListItem
                      button
                      key="roles"
                      component={Link}
                      to="/roles"
                      value="/roles"
                      elementType="div"
                    >
                      <Tooltip
                        title="Roles"
                        placement="right-start"
                        className={clsx({
                          [classes.hide]: open,
                        })}
                      >
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemIcon
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      >
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary={intl.formatMessage({ id: "rolesLink" })} />
                    </ListItem>
                  }

                {user_permissions && user_permissions.permissions.create_users &&
                  <ListItem
                    button
                    key="users"
                    component={Link}
                    to="/users"
                    value="/users"
                    elementType="div"
                  >
                    <Tooltip
                      title="Users"
                      placement="right-start"
                      className={clsx({
                        [classes.hide]: open,
                      })}
                    >
                      <ListItemIcon>
                        <RecentActorsIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemIcon
                      className={clsx({
                        [classes.hide]: !open,
                      })}
                    >
                      <RecentActorsIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "usersLink" })} />
                  </ListItem>
                }

                {/* {user_permissions && user_permissions.permissions.create_tags && */}
                {user_permissions && user_permissions.permissions.tags &&
                  <ListItem
                    button
                    key="tags"
                    component={Link}
                    to="/tags"
                    value="/tags"
                    elementType="div"
                  >
                    <Tooltip
                      title="Tags"
                      placement="right-start"
                      className={clsx({
                        [classes.hide]: open,
                      })}
                    >
                      <ListItemIcon>
                        <ListAltIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemIcon
                      className={clsx({
                        [classes.hide]: !open,
                      })}
                    >
                      <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "tagsLink" })} />
                  </ListItem>
                  }
                  <Divider />
                   {/* {user_permissions && user_permissions.permissions.view_noticeboard && */}
                      <ListItem
                        button
                        key="TaxManagementNoticeBoard"
                        component={Link}
                        to="/TaxManagementNoticeBoard"
                        value="/TaxManagementNoticeBoard"
                        elementType="div"
                      >
                        <Tooltip
                          title="Notice Board"
                          placement="right-start"
                          className={clsx({
                            [classes.hide]: open,
                          })}
                        >
                          <ListItemIcon>
                            <NoteIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemIcon
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          <NoteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Notice Board" />
                      </ListItem>
                    {/* } */}

                      {/* <ListItem
                        button
                        key="CommunicationInterface"
                        component={Link}
                        to="/CommunicationInterface"
                        value="/CommunicationInterface"
                        elementType="div"
                      >
                        <Tooltip
                          title="Chat"
                          placement="right-start"
                          className={clsx({
                            [classes.hide]: open,
                          })}
                        >
                          <ListItemIcon>
                            <MessageIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemIcon
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          <MessageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Chat" />
                      </ListItem> */}

                 {/* {user_permissions && user_permissions.permissions.view_client_groups && */}
                      <ListItem
                        button
                        key="TaxManagementClientGroups"
                        component={Link}
                        to="/TaxManagementClientGroups"
                        value="/TaxManagementClientGroups"
                        elementType="div"
                      >
                        <Tooltip
                          title="Client Groups"
                          placement="right-start"
                          className={clsx({
                            [classes.hide]: open,
                          })}
                        >
                          <ListItemIcon>
                            <GroupIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemIcon
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Client Groups" />
                      </ListItem>
                  {/* } */}
                   {/*role based access  */}
                   <Divider />
                   {user_permissions && user_permissions.permissions.view_roles &&
                    <ListItem
                      button
                      key="RoleBasedAccess"
                      component={Link}
                      to="/RoleBasedAccess"
                      value="/RoleBasedAccess"
                      elementType="div"
                    >
                      <Tooltip
                        // title={intl.formatMessage({ id: "newClientLink" })}
                        title={intl.formatMessage({ id: "rolesLink" })}
                        placement="right-start"
                        className={clsx({
                          [classes.hide]: open,
                        })}
                      >
                        <ListItemIcon>
                          <NoteIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemIcon
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      >
                        <NoteIcon />
                      </ListItemIcon>
                      <ListItemText primary="RoleBasedAccess" />
                    </ListItem>
                    }

                    {
                      <ListItem
                        button
                        key="Feedback"
                        component={Link}
                        to="/Feedback"
                        value="/Feedback"
                        elementType="div"
                      >
                        <Tooltip
                          // title={intl.formatMessage({ id: "newClientLink" })}
                          title="Feedback"
                          placement="right-start"
                          className={clsx({
                            [classes.hide]: open,
                          })}
                        >
                          <ListItemIcon>
                            <FeedbackIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemIcon
                          className={clsx({
                            [classes.hide]: !open,
                          })}
                        >
                          <FeedbackIcon />
                        </ListItemIcon>
                        <ListItemText primary="Feedback" />
                      </ListItem>
                    }

                </List>
              </Drawer>
            </div>
          ):
          (
            <div>
              <CssBaseline />
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar>
                  <Grid container>
                    <Grid item xs={11}>
                      <Grid container className={classes.alignItemsCenter}>
                        <Grid item xs={1} className={classes.textAlignLeft} onClick={toggleTheme}>
                          {/* <button onClick={toggleTheme}>Toggle Theme</button> */}
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}

                            // onClick={ () => {
                            //   handleDrawerOpen();
                            //   toggleTheme;
                            // }}
                            edge="start"
                            className={clsx(classes.menuButton, {
                              [classes.hide]: open,
                            })}
                          >
                            <MenuIcon />
                          </IconButton>
                        </Grid>
                        <Grid item className={classes.textAlignLeft}>
                          {
                          ( open == true )?
                          (
                            <Typography variant="h6" noWrap style={{marginTop:'4%', marginLeft:'-30%'}}>
                              {/* <FormattedMessage id="taxManagementHeader" /> */}
                              {userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business.business_name}
                            </Typography>
                          )
                          :(
                              <Typography variant="h6" noWrap>
                                {/* <FormattedMessage id="taxManagementHeader" /> */}
                                {userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business.business_name}
                              </Typography>
                          )
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      <ProfilePicture
                      users={users}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
              >
                <div className={classes.toolbar} onClick={toggleTheme}>
                  {/* <StickyContainer>
                    <Sticky> */}
                      <FormattedMessage id="clickToMinimizeLabel" />
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                          // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                          <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                            borderRadius: '50%',}}>
                            <ChevronRightIcon style={{color:'white',marginTop:'10%'}}/>
                          </div>
                        ) : (
                          // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                          <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                            borderRadius: '50%',}}>
                            <ChevronLeftIcon style={{color:'white',marginTop:'15%', marginLeft:'15%'}}/>
                          </div>
                        )}
                      </IconButton>
                    {/* </Sticky>
                  </StickyContainer> */}

                </div>
                <Divider />
                <List>
                  <ListItem
                    button
                    key="search"
                    component={Link}
                    to="/search"
                    value="/search"
                    elementype="div"
                  >
                    <Tooltip
                      title={intl.formatMessage({ id: "searchLink" })}
                      placement="right-start"
                      className={clsx({
                        [classes.hide]: open,
                      })}
                    >
                      <ListItemIcon>
                        <FindInPageIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemIcon
                      className={clsx({
                        [classes.hide]: !open,
                      })}
                    >
                      <FindInPageIcon />
                    </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({ id: "searchLink" })} />
                  </ListItem>
                </List>
                <Divider />

              </Drawer>
            </div>
          )
        ):
        (
          <div></div>
        )
      }
      {
        // ( roleOfUserFromRedux &&  roleOfUserFromRedux == "client"  )?
        ( roleOfUserFromRedux == "client" || role_of_user == "client" )?
        // ( UserDetailsFromRedux && UserDetailsFromRedux.items.data.user_details.role == "client" )?
        (
          // console.log("in client condition of role base")
          <div>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <Grid container>
                  <Grid item xs={11}>
                    <Grid container className={classes.alignItemsCenter}>
                      <Grid item className={classes.textAlignLeft} onClick={toggleTheme}>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerOpen}
                          // onClick={toggleTheme}
                          edge="start"
                          className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                          })}
                        >
                          <MenuIcon />
                        </IconButton>
                      </Grid>
                      <Grid item className={classes.textAlignLeft}>
                        {
                         ( open == true )?
                         (
                          <Typography variant="h6" noWrap style={{marginTop:'4%', }}>
                            {/* <FormattedMessage id="taxManagementHeader" /> */}
                            {userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business_link.business_name}
                          </Typography>
                         )
                         :(
                            <Typography variant="h6" noWrap>
                              {/* <FormattedMessage id="taxManagementHeader" /> */}
                              {userInfoFromReduxLogDetails && userInfoFromReduxLogDetails.business_link.business_name}
                            </Typography>
                         )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <ProfilePicture
                    users={users}
                    />
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              {/* <div className={classes.toolbar}>
                <FormattedMessage id="clickToMinimizeLabel" />
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div> */}
              <div className={classes.toolbar} onClick={toggleTheme}>
                <FormattedMessage id="clickToMinimizeLabel" />
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                    <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                      borderRadius: '50%',}}>
                      <ChevronRightIcon style={{color:'white',marginTop:'10%'}}/>
                    </div>
                  ) : (
                    // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                    <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                      borderRadius: '50%',}}>
                      <ChevronLeftIcon style={{color:'white',marginTop:'15%', marginLeft:'15%'}}/>
                    </div>
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem
                  button
                  key="search"
                  component={Link}
                  to="/search"
                  value="/search"
                  elementype="div"
                >
                  <Tooltip
                    title={intl.formatMessage({ id: "searchLink" })}
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <FindInPageIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <FindInPageIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: "searchLink" })} />
                </ListItem>
                <ListItem
                  button
                  key="cases"
                  component={Link}
                  to="/cases"
                  value="/cases"
                  elementType="div"
                >
                  <Tooltip
                    title={intl.formatMessage({ id: "casesLink" })}
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <PersonalVideoIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <PersonalVideoIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: "casesLink" })} />
                </ListItem>
                {/* <ListItem
                  button
                  key="client"
                  component={Link}
                  to="/client"
                  value="/client"
                  elementType="div"
                >
                  <Tooltip
                    title={intl.formatMessage({ id: "clientsLink" })}
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: "clientsLink" })} />
                </ListItem> */}

                {/* <ListItem
                  button
                  key="alerts"
                  component={Link}
                  to="/alerts"
                  value="/alerts"
                  elementType="div"
                >
                  <Tooltip
                    title={intl.formatMessage({ id: "alertLink" })}
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <AnnouncementIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <AnnouncementIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: "alertLink" })} />
                </ListItem> */}

                <Divider />

                <ListItem
                  button
                  key="TaxManagementNoticeBoard"
                  component={Link}
                  to="/TaxManagementNoticeBoard"
                  value="/TaxManagementNoticeBoard"
                  elementType="div"
                >
                  <Tooltip
                    title="Notice Board"
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <NoteIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <NoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Notice Board" />
                </ListItem>

              </List>
              {/* <Divider /> */}
              {/* <List>
                <ListItem
                        button
                        key="TaxManagementClientCases"
                        component={Link}
                        to="/TaxManagementClientCases"
                        value="/TaxManagementClientCases"
                        elementType="div"
                        onClick={() => TaxManagementClientCasesFunc()}
                      >
                      <Tooltip
                        title={intl.formatMessage({ id: "newCaseLink" })}
                        placement="right-start"
                        className={clsx({
                          [classes.hide]: open,
                        })}
                      >
                        <ListItemIcon>
                          <AddToQueueIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemIcon
                        className={clsx({
                          [classes.hide]: !open,
                        })}
                      >
                        <AddToQueueIcon />
                      </ListItemIcon>
                      <ListItemText primary={intl.formatMessage({ id: "newCaseLink" })} />
                  </ListItem>
              </List> */}
              <List>


                {/* from sanyukta */}
                {/* <Divider /> */}
                {/* <ListItem
                  button
                  key="BusinessDashboard"
                  component={Link}
                  to="/BusinessDashboard"
                  value="/BusinessDashboard"
                  elementType="div"
                >
                  <Tooltip
                    // title={intl.formatMessage({ id: "newClientLink" })}
                    title="Business Dashboard"
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Business Dashboard" />
                </ListItem> */}

                {/* <ListItem
                  button
                  key="Businesses"
                  component={Link}
                  to="/Businesses"
                  value="/Businesses"
                  elementType="div"
                >
                  <Tooltip
                    // title={intl.formatMessage({ id: "newClientLink" })}
                    title="Businesses"
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <BusinessCenterIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <BusinessCenterIcon />
                  </ListItemIcon>
                  <ListItemText primary="Businesses" />
                </ListItem> */}

                

              </List>
            </Drawer>
          </div>
        ):
        (
          <div></div>
        )
      }

      {/* new for trp and trpdoc */}
      {
        ( roleOfUserFromRedux == "trp" || role_of_user == "trp" || roleOfUserFromRedux == "trpdoc" || role_of_user == "trpdoc" )?
        // ( UserDetailsFromRedux && UserDetailsFromRedux.items.data.user_details.role == "trpadmin" )?
        (
          // console.log("in trp admin condition of role base")
          <div>
            <CssBaseline />
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <Grid container>
                  <Grid item xs={11}>
                    <Grid container className={classes.alignItemsCenter}>
                      <Grid item className={classes.textAlignLeft} onClick={toggleTheme}>
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={handleDrawerOpen}
                          // onClick={toggleTheme}
                          edge="start"
                          className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                          })}
                        >
                          <MenuIcon />
                        </IconButton>
                      </Grid>
                      <Grid item className={classes.textAlignLeft}>
                        {
                          ( open == true )?
                          (
                            <Typography variant="h6" noWrap style={{marginTop:'4%',}}>
                              <FormattedMessage id="taxManagementHeader" />
                            </Typography>
                          )
                          :(
                              <Typography variant="h6" noWrap>
                                <FormattedMessage id="taxManagementHeader" />
                              </Typography>
                          )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <ProfilePicture
                    users={users}
                    />
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              {/* <div className={classes.toolbar}>
                <FormattedMessage id="clickToMinimizeLabel" />
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </div> */}
              <div className={classes.toolbar} onClick={toggleTheme}>
                <FormattedMessage id="clickToMinimizeLabel" />
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                    <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                      borderRadius: '50%',}}>
                      <ChevronRightIcon style={{color:'white',marginTop:'10%'}}/>
                    </div>
                  ) : (
                    // <div style={{ backgroundColor:'#3f51b5', borderRadius: '40%'}}>
                    <div style={{display: 'flex', width: '35px',height: '35px', backgroundColor: '#3f51b5',
                      borderRadius: '50%',}}>
                      <ChevronLeftIcon style={{color:'white',marginTop:'15%', marginLeft:'15%'}}/>
                    </div>
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem
                  button
                  key="search"
                  component={Link}
                  to="/search"
                  value="/search"
                  elementype="div"
                >
                  <Tooltip
                    title={intl.formatMessage({ id: "searchLink" })}
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <FindInPageIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <FindInPageIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: "searchLink" })} />
                </ListItem>
                <ListItem
                  button
                  key="cases"
                  component={Link}
                  to="/cases"
                  value="/cases"
                  elementType="div"
                >
                  <Tooltip
                    title={intl.formatMessage({ id: "casesLink" })}
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <PersonalVideoIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <PersonalVideoIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: "casesLink" })} />
                </ListItem>
                <ListItem
                  button
                  key="client"
                  component={Link}
                  to="/client"
                  value="/client"
                  elementType="div"
                >
                  <Tooltip
                    title="Clients"
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      {/* <PersonIcon /> */}
                      <GroupIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    {/* <PersonIcon /> */}
                    <GroupIcon />
                  </ListItemIcon>
                  {/* <ListItemText primary={intl.formatMessage({ id: "clientsLink" })} /> */}
                  <ListItemText primary="Clients" />
                </ListItem>

                {/* <ListItem
                  button
                  key="alerts"
                  component={Link}
                  to="/alerts"
                  value="/alerts"
                  elementType="div"
                >
                  <Tooltip
                    title={intl.formatMessage({ id: "alertLink" })}
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <AnnouncementIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <AnnouncementIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: "alertLink" })} />
                </ListItem> */}

              </List>
              <Divider />
              <List>
                <ListItem
                  button
                  key="NewCase"
                  component={Link}
                  to="/newcase"
                  value="/newcase"
                  elementType="div"
                >
                  <Tooltip
                    title={intl.formatMessage({ id: "newCaseLink" })}
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <AddToQueueIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <AddToQueueIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: "newCaseLink" })} />
                </ListItem>
                <ListItem
                  button
                  key="NewClient"
                  component={Link}
                  to="/newclient"
                  value="/newclient"
                  elementType="div"
                >
                  <Tooltip
                    title={intl.formatMessage({ id: "newClientLink" })}
                    placement="right-start"
                    className={clsx({
                      [classes.hide]: open,
                    })}
                  >
                    <ListItemIcon>
                      <PersonAddIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemIcon
                    className={clsx({
                      [classes.hide]: !open,
                    })}
                  >
                    <PersonAddIcon />
                  </ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: "newClientLink" })} />
                </ListItem>

              </List>
            </Drawer>
          </div>
        ):
        (
          <div></div>
        )
      }

    </div>
  );
}

export default injectIntl(TaxManagementHeader);
// export { TaxManagementHeaderContext, UserDispatchContext };
