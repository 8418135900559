import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Modal from '@material-ui/core/Modal';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { PropagateLoader } from 'react-spinners';
import { modal } from 'reactstrap';
import { useSelector , useDispatch} from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "react-phone-input-2/lib/material.css";

import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AntSwitch from '@material-ui/core/Switch';

import TableSortLabel from '@material-ui/core/TableSortLabel';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(clientOtherContact, comparator) {
  const stabilizedThis = clientOtherContact && clientOtherContact.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, label: 'Name', },
  { id: 'email', numeric: false, label: 'Email ID', },
  { id: 'phone', numeric: false, label: 'Mobile Number', },
  { id: 'pan', numeric: false, label: 'PAN No.', },
  { id: 'is_active', numeric: false, label: 'Is Active', },
  { id: 'actions', numeric: false, label: 'Actions',}
];
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          ( headCell.id === "actions")?
            (
              <TableCell
                key={headCell.id}
                align="center"
                style={{fontWeight: 'bold', fontSize: 17,  width: "1%"}}
              >
                {headCell.label}
              </TableCell>
            )
            :(
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              align="center"
              style={{fontWeight: 'bold', fontSize: 17,  width: "10%"}}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
            )
          
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  }
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
//   intl: PropTypes.shape.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  wrapper: {
    margin: "10%",
    marginRight: "10%",
    marginTop: "8%",
    marginBottom: "3%",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'scroll',
  },
  paper: {
    position: 'absolute',
    width: 500,
    // height: 650,
    // top:'2%',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  addNewBusinessHeaderBackgroud: {
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    backgroundColor: '#23c1f7',
    height:'10%',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 3),  
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonSwalDelete: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
  },
  buttonSwalCancel: {
    background: "#1a1aff",
    height: 35,
    fontSize: 14,
    color: '#ffffff',
    // padding: 10,
    border: "1px solid #1a1aff",
    borderRadius: 5,
    marginRight: "2%",
  },
  validation: {
    color: 'red',
    fontSize: 12,
  },
  formControl: {
    // margin: theme.spacing(1),
    marginTop: '4%',
    width: '100%',
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  pageHeaderLabel:{
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
  },
  buttonAddNew: {
    textAlign: "right",
    marginTop:"3%",
    marginLeft: "40%",
  },
  buttonDelete: {
    backgroundColor: "#de4545",
    color:"white",
    '&:hover': {
      backgroundColor: '#e85f5f',
      color: 'white',
    },
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    // fontWeight: "500",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "25px",
    // marginBottom: "10px",
    textAlign: "left",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

}));

export default function UserOtherContactDetails() {
    const classes = useStyles();
    // const [AddressList, setAddressList] = React.useState([
    //   { id: 1, address: "Pune"},
    //   { id: 2, address: "Mumbai"},
    //   { id: 3, address: "Delhi"},
    // ]);
    const [ ShowProcedure, setShowProcedure ] = React.useState(false);
    const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
    const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));
    const [flag , setflag] = React.useState(false);
    const [clientOtherContact, setclientOtherContact] = React.useState([]);
    const [newAddressDetails, setnewAddressDetails] = React.useState({
      full_address: "",
      is_active: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });
    const [yesNo, setyesNo] = React.useState([
      { value: true , name: 'Yes'  },
      { value: false , name: 'No'  },
    ]);
    const [fullAddressErr, setfullAddressErr] = React.useState("");
    const [isActiveErr, setisActiveErr] = React.useState("");

    const [deleteAddressDetails, setdeleteAddressDetails] = React.useState({
      address_id: "",
      full_address: "",
      is_active: "",
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')), 
    });

    const [clientIdFromLocal, setclientIdFromLocal] = React.useState();

    let user_details = JSON.parse(localStorage.getItem('users'));
    console.log("user_details in client contact",user_details,user_details.data.user_details.business_link.client_id);
    let client_id = user_details.data.user_details.business_link.client_id;

    const[editContactDetails, seteditContactDetails] = React.useState({
      assesse_id: "",
      name: "",
      email: "",
      phone: "",
      country_code: "",
      pan: "",
      is_active: "",
      client_id: client_id,
      device_token: JSON.parse(localStorage.getItem('device_token')),
      session_id: JSON.parse(localStorage.getItem('session_id')),
    });
    const[openEditContact, setopenEditContact] = React.useState(false);
    const [onlyCountryArray, setonlyCountryArray] = React.useState([
      'in','us', 'gb', 'ca', 'ae', 'sg','np', 'jp', 'de', 'bt', 'au'
    ]);
    const[CountryCode, setCountryCode] = React.useState();
    const[asseNameErr, setasseNameErr] = React.useState("");
    const[asseEmailErr, setasseEmailErr] = React.useState("");
    const[assePhoneErr, setassePhoneErr] = React.useState("");
    const[assePanErr, setassePanErr] = React.useState("");
    const[AddOrUpdate, setAddOrUpdate] = React.useState("");

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    
    const dispatch = useDispatch();

    // sort
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // pagination
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, clientOtherContact && clientOtherContact.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        let valueHere = parseInt(event.target.value, 10)
        let valueToCompare = JSON.stringify(valueHere)
        if( valueToCompare === "null" ){
          setRowsPerPage(0);
        }
        else{
          setRowsPerPage(parseInt(event.target.value, 10));
        }
        setPage(0);
    };
    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    useEffect(() => {
      setflag(true);
      dispatch(userActions.checkSessionValidity());
      setShowProcedure(true);

      let user_details = JSON.parse(localStorage.getItem('users'));
      let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));
      console.log("user_details in client contact",user_details,user_details.data.user_details.business_link.client_id)
      let client_id = user_details.data.user_details.business_link.client_id;

      setclientIdFromLocal(client_id)

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'client_id': client_id ,'device_token': device_token, 'session_id': session_id, 'business_id': business_id })
      };
      fetch( process.env.REACT_APP_GET_CLIENT_DETAILS , requestOptions)
        .then((Response) => Response.json())
        .then(clientOtherContact => {
          setShowProcedure(false)
          console.log("clientOtherContact are",clientOtherContact);
          if(clientOtherContact.success == true)
          {
            setclientOtherContact(clientOtherContact.data.client_details.contacts)
          }
          if(clientOtherContact.success == false){
            setclientOtherContact([])
            Swal.fire({
              icon: 'error',
              text: clientOtherContact.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }, [flag]);

    const handleChangeIsActiveContact = (item) => {
      console.log("handleChangeIsActiveContact", item);
      setclientOtherContact(
      // let clientOtherContactHere = clientOtherContact
      // clientOtherContactHere.map((i, index)=>{
      clientOtherContact.map((i, index)=>{
        if(i.assesse_id == item.assesse_id){
          console.log("asseid matched",i.assesse_id)
          i.is_active = !i.is_active
        }
        return i;
      })
      )
      // console.log("clientOtherContactHere",clientOtherContactHere)

      let InActiveContactDetailsFinal = ({
        assesse_id: item.assesse_id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        pan: item.pan,
        is_active: item.is_active,
        client_id: clientIdFromLocal,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')), 
      });

      console.log("InActiveContactDetailsFinal",InActiveContactDetailsFinal)

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(InActiveContactDetailsFinal)
      };
      return fetch( process.env.REACT_APP_UPDATE_CLIENT_CONTACT , requestOptions)
        .then((Response) => Response.json())
        .then( contactIsActive => {
          console.log("contactIsActive",contactIsActive)
          if(contactIsActive.success == true){
            console.log("contactIsActive success",contactIsActive)
          }
          else{
            Swal.fire({
              icon: 'error',
              text: contactIsActive.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              // timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });

    }

    const handleAddContact = () => {
      console.log("handle Add Contact clicked")
    }

    const handleEditContact = async(AddOrUpdate,item) => {
      console.log("contact selected",item)
      if( AddOrUpdate == "UpdateExisting"){
        setAddOrUpdate("UpdateExisting");
        await seteditContactDetails({ ...editContactDetails,
          assesse_id: item.assesse_id,
          name: item.name,
          email: item.email,
          phone: item.phone,
          country_code: item.countryCode,
          pan: item.pan,
          is_active: item.is_active,
          client_id: clientIdFromLocal,
          device_token: JSON.parse(localStorage.getItem('device_token')),
          session_id: JSON.parse(localStorage.getItem('session_id')), 
        });
        console.log("editContactDetails",editContactDetails);
        if(item.phone){
          console.log("Phone Number is=======",item.phone)
          let phoneHere = item.phone
          let splitsNois = phoneHere.split('-',1)
          console.log("splitsNois in edit=-=-=-=-=",splitsNois)
          // var onlyCodeIs = splitsNois.split(" ",1);
          // console.log("splitsNois only country code in edit=-=-=-=-=",splitsNois)
          if(splitsNois == "+975"){
            console.log("split is 975)))))))))",splitsNois)
            setCountryCode("bt")
          }
          else if(splitsNois == "+61"){
            console.log("split is 61)))))))))",splitsNois)
            setCountryCode("au")
          }
          else if(splitsNois == "+1"){
            console.log("split is 1)))))))))",splitsNois)
            setCountryCode("us")
          }
          else if(splitsNois == "+49"){
            console.log("split is 49)))))))))",splitsNois)
            setCountryCode("de")
          }
          else if(splitsNois == "+91"){
            console.log("split is 91)))))))))",splitsNois)
            setCountryCode("in")
          }
          else if(splitsNois == "+81"){
            console.log("split is 81)))))))))",splitsNois)
            setCountryCode("jp")
          }
          else if(splitsNois == "+977"){
            console.log("split is 977)))))))))",splitsNois)
            setCountryCode("np")
          }
          else if(splitsNois == "+65"){
            console.log("split is 65)))))))))",splitsNois)
            setCountryCode("sg")
          }
          else if(splitsNois == "+971"){
            console.log("split is 971)))))))))",splitsNois)
            setCountryCode("ae")
          }
          else if(splitsNois == "+44"){
            console.log("split is 44)))))))))",splitsNois)
            setCountryCode("gb")
          }
          // else if(splitsNois == "+1"){
          //   console.log("split is canada",splitsNois)
          //   setCountryCode("ca")
          // }
        }
      }
      if( AddOrUpdate == "AddNew"){
        setAddOrUpdate("AddNew");
        await seteditContactDetails({ ...editContactDetails,
          assesse_id: "",
          name: "",
          email: "",
          phone: "",
          country_code: "",
          pan: "",
          is_active: true,
          client_id: clientIdFromLocal,
          device_token: JSON.parse(localStorage.getItem('device_token')),
          session_id: JSON.parse(localStorage.getItem('session_id')), 
        });
      }
      
      handleEditContactDetailsOpen();
    }

    const handleEditContactDetailsOpen = async() => {
      setopenEditContact(true);
    }

    const handleEditContactDetailsClose = async() => {
      setopenEditContact(false);
      setShowProcedure(false);
      await seteditContactDetails({
        assesse_id: "",
        name: "",
        email: "",
        phone: "",
        country_code: "",
        pan: "",
        is_active: "",
        client_id: clientIdFromLocal,
        device_token: JSON.parse(localStorage.getItem('device_token')),
        session_id: JSON.parse(localStorage.getItem('session_id')), 
      });
      setasseNameErr("");
      setasseEmailErr("");
      setassePhoneErr("");
      setassePanErr("");
    }

    const validateEditContact = () =>{
      let asseNameErr = '';
      let asseEmailErr = '';
      let assePhoneErr = '';
      let assePanErr = '';

      if( !editContactDetails.name ){
        asseNameErr = "Please Enter Name"
      }

      if( !editContactDetails.email ){
        asseEmailErr = "Please Enter Email"
      }
      else if(typeof editContactDetails.email !== "undefined"){
        let lastAtPos = editContactDetails.email.lastIndexOf('@');
        let lastDotPos = editContactDetails.email.lastIndexOf('.');
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
          asseEmailErr = "Email is not valid";
            if(asseEmailErr) {
              setasseEmailErr(asseEmailErr);
            }
        }
      }

      if( !editContactDetails.phone ){
        assePhoneErr = "Please Enter Phone Number"
      }
      else if (typeof editContactDetails.phone !== "undefined") {
        if (CountryCode == "in"){
          console.log("editContactDetails.phone.length",editContactDetails.phone.length)
          if(editContactDetails.phone.length !== 10){
              assePhoneErr = "Please enter valid phone number.";
            }
        }
        else if(CountryCode == "us"){
          console.log("editContactDetails.phone.length us",editContactDetails.phone.length)
          if(editContactDetails.phone.length !== 10){
              assePhoneErr = "Please enter valid phone number.";
            }
        }
        else if(CountryCode == "gb"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(editContactDetails.phone.length !== 10){
            assePhoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "ca"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(editContactDetails.phone.length !== 10){
            assePhoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "ae"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(editContactDetails.phone.length !== 12){
            assePhoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "sg"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(editContactDetails.phone.length !== 8){
            assePhoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "np"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(editContactDetails.phone.length !== 12){
            assePhoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "jp"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(editContactDetails.phone.length !== 10){
            assePhoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "de"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(editContactDetails.phone.length !== 12){
            assePhoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "bt"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(editContactDetails.phone.length !== 12){
            assePhoneErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCode == "au"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(editContactDetails.phone.length !== 13){
            assePhoneErr = "Please enter valid phone number.";
          }
        }
      }

      if( !editContactDetails.pan ){
        assePanErr = "Please Enter PAN Number"
      }
      else if (typeof editContactDetails.pan !== "undefined") {
        var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
        if(editContactDetails.pan.length !== 10){
          console.log("in != 10")
          assePanErr = "PAN Number is Not Valid";
        }
        else if (!pattern.test(editContactDetails.pan)) {
          console.log("in string check")
          assePanErr = "PAN Number is Not Valid";
        } 
      }

      if( asseNameErr || asseEmailErr || assePhoneErr || assePanErr ){
        setasseNameErr(asseNameErr);
        setasseEmailErr(asseEmailErr);
        setassePhoneErr(assePhoneErr);
        setassePanErr(assePanErr);

        return false;
      }
      return true;
    }

    const handleEditContactDetailsSubmit = () => {
      console.log("handleEditContactDetailsSubmit",editContactDetails)
      setShowProcedure(true);
      const isValid = validateEditContact();
      if(isValid){
        const requestOptions = {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(editContactDetails)
        };
        return fetch( process.env.REACT_APP_UPDATE_CLIENT_CONTACT , requestOptions)
          .then((Response) => Response.json())
          .then(contactEdited => {
            setShowProcedure(false);
            console.log("contactEdited",contactEdited.success);
            console.log("contactEdited",contactEdited);
            if(contactEdited.success == true){

              if( AddOrUpdate == "UpdateExisting" ){
                Swal.fire({
                  icon: 'success',
                  text: "Contact Details Updated Succesfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
              if( AddOrUpdate == "AddNew" ){
                Swal.fire({
                  icon: 'success',
                  text: "New Contact Created Succesfully",
                  confirmButtonColor: 'primary',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
              
              setopenEditContact(false);
              seteditContactDetails({
                assesse_id: "",
                name: "",
                email: "",
                phone: "",
                country_code: "",
                pan: "",
                is_active: "",
                client_id: clientIdFromLocal,
                device_token: JSON.parse(localStorage.getItem('device_token')),
                session_id: JSON.parse(localStorage.getItem('session_id')), 
              });
              setasseNameErr("");
              setasseEmailErr("");
              setassePhoneErr("");
              setassePanErr("");
            }
            if(contactEdited.success == false ){
              setopenEditContact(true);
              Swal.fire({
                icon: 'error',
                text: contactEdited.errors,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK',
                // timer: 5000,
              })
            } 
          setflag(false);            
        })
        .catch(err => {
          setShowProcedure(false);
          setopenEditContact(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
    }

    const phoneNumberChange = (clientNumber,e,formattedValue,value) => {
      console.log("phoneNumberChange clientNumber",clientNumber)
      console.log("phoneNumberChange e",e)
      console.log("phoneNumberChange value",value)
      let countryCodeIs = "+" + e.dialCode
      let splitNoIs = ""
      if(e.countryCode == "in"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "us"){
        // splitNoIs = value.split(" ")[1];
        // splitNoIs = value.substr(value.indexOf(' ')+1);

        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "gb"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "ca"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "ae"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "sg"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "np"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "jp"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "de"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "bt"){
        splitNoIs = value.split(" ")[1];
      }
      if(e.countryCode == "au"){
        splitNoIs = value.split(" ")[1];
      }
      console.log("splitNoIs",splitNoIs)
      let noHereIs = "+"+e.dialCode+"-"+splitNoIs;
      console.log("noHereIs",noHereIs)
      setCountryCode(e.countryCode)
      // seteditContactDetails({...editContactDetails, phone:noHereIs })
      seteditContactDetails({...editContactDetails, phone: splitNoIs, country_code: countryCodeIs })
    }

    const bodyEditContact = (
      <div className={classes.paper}>
        {
          ( AddOrUpdate == "UpdateExisting" )?
          (
            <div className={classes.addNewBusinessHeaderBackgroud}> 
              <h2>Edit Contact</h2>
            </div>
          ):
          (
            ( AddOrUpdate == "AddNew" )?
            (
              <div className={classes.addNewBusinessHeaderBackgroud}> 
                <h2>Add New Contact</h2>
              </div>
            ):
            (<Grid></Grid>)
          )
        }
      
      <div className={classes.modalBody}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoFocus
          value={editContactDetails.name}
          onChange={(e) => seteditContactDetails({...editContactDetails, name:e.target.value }) }
        />
        <div className={classes.validation}>{(editContactDetails.name)?(<div></div>):(asseNameErr)}</div>
        
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          // inputProps={{ style: { textTransform: "lowercase" } }}
          value={editContactDetails.email}
          onChange={(e) => seteditContactDetails({...editContactDetails, email:e.target.value.toLowerCase() }) }
        />
        {/* <div className={classes.validation}>{(editContactDetails.email)?(<div></div>):(asseEmailErr)}</div> */}
        <div className={classes.validation}>{asseEmailErr}</div>

        <TextField
          margin="normal"
          required
          fullWidth
          id="pan"
          label="pan"
          name="pan"
          placeholder="XXXXX0000X"
          inputProps={{ maxLength: 10, style: { textTransform: "uppercase" } }}
          value={editContactDetails.pan}
          onChange={(e) => seteditContactDetails({...editContactDetails, pan:e.target.value.toUpperCase() }) }
        />
        <div className={classes.validation}>{assePanErr}</div>

        <Grid item xs={12} className={classes.subHeadingLabel} style={{marginTop:'3%'}}>
          Phone Number*
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            inputStyle={{
              width: "100%",
              height: "50px",
              fontSize: "15px",
            }}
            countryCodeEditable={false}
            onlyCountries={onlyCountryArray}
            masks={{in: '..........', us: '..........', gb: '..........', ca: '..........', ae:'............',
                    sg: '........', np:'............', jp:'..........', de:'............', bt:'............',
                    au: '.............'  }}
            specialLabel=""
            country={'in'}
            // value={editContactDetails.phone}
            value={ editContactDetails.country_code + editContactDetails.phone }
            onChange={(usersNumber,e,formattedValue,value) => phoneNumberChange(usersNumber,e,formattedValue,value)}
          />
        </Grid>
        <div className={classes.validation}>{assePhoneErr}</div>

      <div style={{display: 'flex',alignItems: 'flex-end', justifyContent: 'flex-end',}}>
          {
            ( AddOrUpdate == "UpdateExisting" )?
            (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleEditContactDetailsSubmit}
              >
                Update Contact
              </Button>
            ):
            (
              ( AddOrUpdate == "AddNew" )?
              (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleEditContactDetailsSubmit}
                >
                  Add Contact
                </Button>
              ):
              (<Grid></Grid>)
            )
          }
          
          <div style={{marginLeft:'2%'}}></div>
          <Button
            type="submit"
            variant="contained"
            className={classes.submit}
            onClick={handleEditContactDetailsClose}
          >
            Cancel
          </Button>
        </div>
      </div>
      </div>
    )
    
    return(
      console.log("editContactDetails in return",editContactDetails),
        <div className={classes.root}>
        {ShowProcedure ? <modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
          </modal>: null}
        <div className={classes.wrapper}>
            {/* <div>Address Details</div> */}
            <Grid container>
              <Grid item xs={1} className={classes.pageHeaderLabel} >
                <Button href="/UsersProfile" className={classes.backButton}>
                    <span className={classes.backArrow}>
                    <ArrowBackIcon fontSize="small" />
                  </span>
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={10} className={classes.pageHeaderLabel} >
                <h2>Contact Details</h2>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={ () => handleEditContact("AddNew","")}
              >
                Add Contact
              </Button>
            </Grid>
            
            <Grid container>
              <Grid item xs={12}>
                <TableContainer className={classes.tableWrapper} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={clientOtherContact && clientOtherContact.length}
                    />
                    <TableBody>
                        {stableSort(clientOtherContact && clientOtherContact, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        // .filter(item => item.is_active == true ).map((clientOtherContact) => {
                        .map((clientOtherContact) => {
                          // console.log("row in table map",clientOtherContact)
                            return (
                            <TableRow 
                                key={clientOtherContact.client_id} 
                                hover={true} classes={{hover: classes.hover}}
                                // hover
                                tabIndex={-1}
                                align="center"
                            >
                              <TableCell width="10%" align="center">{clientOtherContact.name}</TableCell>
                              <TableCell width="10%" align="center">{clientOtherContact.email}</TableCell>
                              {/* <TableCell width="10%" align="center">{clientOtherContact.phone}</TableCell>  */}
                              <TableCell width="10%" align="center">{ clientOtherContact.country_code + clientOtherContact.phone}</TableCell> 
                              <TableCell width="10%" align="center">{clientOtherContact.pan}</TableCell> 
                              <TableCell width="10%" align="center">{clientOtherContact.is_active ? 'True' : 'false'}</TableCell> 
                              <TableCell width="10%" align="center">
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  onClick={() => handleEditContact("UpdateExisting",clientOtherContact)}
                                >
                                  Edit
                                </Button>
                                &nbsp;&nbsp;&nbsp;
                                <AntSwitch 
                                  checked={clientOtherContact.is_active} 
                                  onChange={() => handleChangeIsActiveContact(clientOtherContact)} 
                                  size="small" 
                                  name="clientOtherContact.is_active" 
                                  value="clientOtherContact.is_active"
                                  color="primary"
                                />  
                              </TableCell> 
                            </TableRow>
                            );
                        })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: clientOtherContact && clientOtherContact.length }]}
                                colSpan={8}
                                count={clientOtherContact && clientOtherContact.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                            </TableRow>
                        </TableFooter>
                </Table>
                </TableContainer>
              </Grid>
            </Grid>
        </div>  
          <Modal
            open={openEditContact}
            onClose={handleEditContactDetailsClose}
            className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {bodyEditContact}
          </Modal>
        </div>
    )
}