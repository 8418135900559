import React, {useEffect}from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link, Redirect } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { authHeader } from '../helpers';
import Swal from 'sweetalert2';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Modal } from 'reactstrap';
import { PropagateLoader } from 'react-spinners';
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import useCheckSessionApi from "../SessionValidity";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from "react-datepicker";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,
  // },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    marginBottom: "5%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
  linkBackToCase: {
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    marginTop: "-2%",
    // marginBottom: "2%",
    textAlign: "right",
  },
  linkNoUnderline: {
    textDecoration: "none",
  },
  wrapperInner: {
    // width: "100%",
    background: "#ffffff",
    marginTop:'-2%'
  },
  rootInner: {
    marginLeft: "2%",
    marginRight: "2%",
    marginBottom: "1%",
  },
  subHeadingLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.96)",
    marginTop: "25px",
    marginBottom: "10px",
    textAlign: "left",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  showProcedureStyle: {
    // display: "flex",
    // alignItems: "center",
    // alignSelf: "center",
    // marginLeft: "50%",
    // marginTop: "-20%",
     position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  AddNewClientButton: {
    color: "Blue",
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
  subHeadingLabelSelect: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "20px",
    color: "black",
    textAlign: "left",
    marginLeft: "1%",
  },
  formControl: {
    marginLeft: "-5%",
    marginTop: '2%',
    width: '90%',
  },
  validation: {
    marginTop: '0.5%',
    color: 'red',
    fontSize: 12,
    textAlign: "left",
  },
  datePickerStyle: {
    width: '94%',
    height: '50px',
    borderColor: 'grey',
    borderRadius: '5px',
    borderWidth: '0.5px',
    marginTop: '2%',
  },
}));


function TrpEditCase({ intl }) {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [ selectedCaseDetails, setselectedCaseDetails ] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_edit')));
  const [device_token, setdevice_token ] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id ] = React.useState(JSON.parse(localStorage.getItem('session_id')));
  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [clientList, setclientList ] = React.useState([]);
  const [selectedClient, setselectedClient] = React.useState("");
  const [newCaseDetails, setnewCaseDetails] = React.useState({
    created_by: "business",  
    case_id: selectedCaseDetails.case_id,
    service_id: selectedCaseDetails.service_id,
    client_id: selectedCaseDetails.client_id,
    name: selectedCaseDetails.name,
    description: selectedCaseDetails.description,
    assessment_year: selectedCaseDetails.assessment_year,
    device_token: JSON.parse(localStorage.getItem('device_token')),
    session_id: JSON.parse(localStorage.getItem('session_id')),
  });

  const [ superCategoryList, setsuperCategoryList ] = React.useState([]);
  const [ superCategoryValue, setsuperCategoryValue ] = React.useState("");
  const [ subCategoryList, setsubCategoryList ] = React.useState([]);
  const [ SubCtegoryFetched, setSubCtegoryFetched ] = React.useState(false);
  const [ subCategoryValue, setsubCategoryValue ] = React.useState("");
  const [ SelectedCategoryIsCategory, setSelectedCategoryIsCategory ] = React.useState(false);
  const [ categoryDetailsLastList, setcategoryDetailsLastList ] = React.useState([]);
  const [ lastCategoryValue, setlastCategoryValue ] = React.useState("");

  const [ selectedAssesmentYear, setselectedAssesmentYear] = React.useState("");

  const [ caseNameErr, setcaseNameErr ] = React.useState("");
  const [ caseDescriptionErr, setcaseDescriptionErr ] = React.useState("");
  const [ caseServiceErr, setcaseServiceErr ] = React.useState("");
  const [ caseAssesYearErr, setcaseAssesYearErr ] = React.useState("");

  const [ changeSeviceFlag, setchangeSeviceFlag ] = React.useState(false);

  const [RedirectCaseTable, setRedirectCaseTable] = React.useState(false);

  const [flag , setflag] = React.useState(false);

  const [AssesmentYearArray, setAssesmentYearArray] = React.useState([])

  var d1 = new Date();
  var pastYear = d1.getFullYear() - 10;
  d1.setFullYear(pastYear);
  console.log("d==",d1);

  var d2 = new Date();
  var pastYear = d2.getFullYear() + 2;
  d2.setFullYear(pastYear);
  console.log("d==",d2);

  const [dueDate, setdueDate] = React.useState(d2);
  const [minDate, setminDate] = React.useState(d1);

  const dispatch = useDispatch();

  const getAssesmentYear = () => {
    var year1 = new Date();
    var pastYear = year1.getFullYear() - 10;
    year1.setFullYear(pastYear);
    console.log("d== d1.getFullYear()",year1, year1.getFullYear());
    var year1Final = year1.getFullYear()

    var year2 = new Date();
    var pastYear = year2.getFullYear() + 2;
    year2.setFullYear(pastYear);
    console.log("d== d1.getFullYear()",year2, year2.getFullYear());
    var year2Final = year2.getFullYear()

    const value = [{value:"N/A", item:"N/A"}];
    for (let i = year1Final ; i <= year2Final ; i++) {

      const last2 = i.toString().slice(-2);
      const lastis = parseInt(last2)+1;
      console.log("last2 DIGITS ARE",last2);
      console.log("lastis DIGITS ARE",lastis);

      let LastValueIs = 'AY' + '-' + i.toString() + '-' +  lastis

      console.log("LastValueIs of assesment year", LastValueIs)

      // let a = {value:i.toString(), item:i.toString()}
      let a = {value:LastValueIs.toString(), item:LastValueIs.toString()}
      
      value.push(a);
      // AssesmentYearArray.push(a)
      console.log("value array is", value)
    }
    console.log("value array is", value)
    setAssesmentYearArray({...AssesmentYearArray, value })
  }

  useEffect(() => {
    setflag(false);
    // readItemFromStorage();
    dispatch(userActions.checkSessionValidity());

    getAssesmentYear()

    let category_type = "supercategory"

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'category_type': category_type,'business_id':business_id,
      'device_token': device_token, 'session_id': session_id })
    };

    fetch( process.env.REACT_APP_GET_CATEGORIES , requestOptions)
      .then((Response) => Response.json())
      .then(superCategoryDetails => {
        console.log("super categoryDetails for category list",superCategoryDetails.data.categories);
        if(superCategoryDetails.data.categories)
        {
          setsuperCategoryList(superCategoryDetails.data.categories.filter(item => item.is_live == true ))
        }
        else{
          setsuperCategoryList([])
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });

    }, [flag]);

    // const readItemFromStorage = async() => {
    //     try {
    //     setDeviceToken(deviceTokenFromRedux)
    //     setSessionId(sessionIdFromRedux)
    //     setflag(false)    
    //     } catch (error) {
    //         console.log("ERROR:",error);
    //     }
    // };

  const handleClientSearch = (e) => {
    console.log("in handleClientSearch",e.target.value)
    let search_string = e.target.value;
    console.log("search_string",search_string)
    console.log("client search device_token,session_id, ",device_token,session_id);

    if( search_string !== "" ){
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'search_string': search_string, 'device_token': device_token, 
          'session_id': session_id, 'business_id': business_id,  "client_type": "ALL" })
      };

      fetch( process.env.REACT_APP_SEARCH_CLIENTS , requestOptions)
        .then((Response) => Response.json())
        .then( clientDetails => {
          console.log("clientDetails in search",clientDetails.data.clients);
          if(clientDetails.data.clients)
          {
            // settrial("ok");
            setclientList(clientDetails.data.clients)
            if( clientList.lenght == 0){
              setclientList({client_id: 0, name: "+Add New Client",})
            }
          }
          if(clientDetails.success == false){
            setclientList({client_id: 0, name: "+Add New Client",})
            Swal.fire({
              icon: 'error',
              text: clientDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const handleChangeSuperAcategory = (e) => {
    console.log("e of super category",e.target.value)
    setsuperCategoryValue(Number(e.target.value));
    setsubCategoryValue("");
    setSelectedCategoryIsCategory(false);
    setcategoryDetailsLastList([]);
    setlastCategoryValue("")

    let device_token = JSON.parse(localStorage.getItem('device_token'));
    let session_id = JSON.parse(localStorage.getItem('session_id'));
    let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));

    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'parent_category_id': e.target.value, 'business_id': business_id,
      'device_token': device_token, 'session_id': session_id })
    };

    fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
      .then((Response) => Response.json())
      .then( subCategoryDetails => {
        console.log("subCategoryDetails for list",subCategoryDetails.data.category_children);
        if(subCategoryDetails.data.category_children)
        {
          setsubCategoryList(subCategoryDetails.data.category_children.filter(item => item.is_live == true ))
        }
        else
        {
          setsubCategoryList([])
        }
        setSubCtegoryFetched(true)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
  }

  const handleChangeSubcategory = async(e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e of handleChangeSubcategory",e.target.value)
    // console.log("e of catType",catType)
    await setsubCategoryValue(Number(e.target.value));
    setlastCategoryValue("")

    let matchedCategory = "";
    subCategoryList.map((item, index) =>{
      if(serviceIdFinalIs == item.category_id){
        // console.log("ok this is matched of selected item",item)
        matchedCategory = item;
      }
    })
    console.log("matchedCategory",matchedCategory)
    
    // if( catType == "service" ){
    if( matchedCategory.category_type == "service"){
      setSelectedCategoryIsCategory(false)
      console.log("service is selected now set service to object")
      await setnewCaseDetails({...newCaseDetails, service_id: JSON.parse(serviceIdFinalIs) })
      console.log('serviceID is newCaseDetails', newCaseDetails)
    }
    else if( matchedCategory.category_type == "category" ){
      setSelectedCategoryIsCategory(true)
      console.log("category is selected call api")
      let device_token = JSON.parse(localStorage.getItem('device_token'));
      let session_id = JSON.parse(localStorage.getItem('session_id'));
      let business_id = JSON.parse(localStorage.getItem('logedIn_users_businessId'));

      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'parent_category_id': serviceIdFinalIs, 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };

      fetch( process.env.REACT_APP_GET_SUB_CATEGORY_FOR_CATEGORY , requestOptions)
        .then((Response) => Response.json())
        .then( categoryLastDetails => {
          console.log("categoryLastDetails for list",categoryLastDetails.data.category_children);
          if(categoryLastDetails.data.category_children)
          {
            setcategoryDetailsLastList(categoryLastDetails.data.category_children.filter(item => item.is_live == true ))
          }
          else
          {
            setcategoryDetailsLastList([])
          }
          setSubCtegoryFetched(true)
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
      }
  }

  const handleChangeLastCategory = async(e) => {
    let serviceIdFinalIs = e.target.value
    console.log("e LastCategory category",e.target.value)
    await setlastCategoryValue(Number(e.target.value));
    
    console.log("last category service is selected now set it to object")
    await setnewCaseDetails({...newCaseDetails, service_id: JSON.parse(serviceIdFinalIs) })
    console.log('serviceID is newCaseDetails', newCaseDetails)
  }

  const handleChangeAssesmentYear = (date) => {
    console.log("in handleChangeAssesment Year", date)
    console.log("date.getFullYear()",JSON.stringify(date.getFullYear()))
    let assesmentYearHere = JSON.stringify(date.getFullYear())
    setnewCaseDetails({...newCaseDetails, assessment_year: assesmentYearHere })
  }

  const validate = () => {
    let caseNameErr  = '';
    let caseDescriptionErr = '';
    let caseServiceErr = '';
    let caseAssesYearErr = '';

    if(!newCaseDetails.name)  {
      caseNameErr = 'Please Enter Case Name';
    }
    if(!newCaseDetails.description)  {
      caseDescriptionErr = 'Please Enter Case Description';
    }
    if(!newCaseDetails.service_id)  {
      caseServiceErr = 'Please Select Service';
    }
    if(!newCaseDetails.assessment_year)  {
      caseAssesYearErr = 'Please Select Assessment Year';
    }

    if(caseNameErr || caseDescriptionErr || caseServiceErr || caseAssesYearErr ){
        setcaseNameErr(caseNameErr);
        setcaseDescriptionErr(caseDescriptionErr);
        setcaseServiceErr(caseServiceErr);
        setcaseAssesYearErr(caseAssesYearErr);

      return false;
    }
    return true;
  }

  const handelSubmitUpdateCase = () => {
    console.log('serviceID is newCaseDetails submit function', newCaseDetails);

    const isValid = validate();
    if(isValid){
      setShowProcedure(true);
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({...newCaseDetails, business_id: business_id })
      };
      fetch( process.env.REACT_APP_UPDATE_CASE , requestOptions)
        .then((Response) => Response.json())
        .then( caseCreatedDetails => {
          setShowProcedure(false)
          console.log("caseCreatedDetails on Admin",caseCreatedDetails);
          if(caseCreatedDetails.success == true)
          {
            Swal.fire({
              icon: 'success',
              text: "Case updated successfully.",
              confirmButtonColor: 'primary',
              confirmButtonText: 'OK',
              timer: 5000,
            })
            setnewCaseDetails({
              created_by:"business",    
              service_id: "",
              client_id:"",
              name:"",
              description: "",
              assessment_year: "",
              device_token: JSON.parse(localStorage.getItem('device_token')),
              session_id: JSON.parse(localStorage.getItem('session_id')),
            })
            setsuperCategoryValue("");
            setsubCategoryValue("");
            setlastCategoryValue("");
            setselectedClient("");
            setSubCtegoryFetched(false);

            setcaseNameErr("");
            setcaseDescriptionErr("");
            setcaseServiceErr("");
            setcaseAssesYearErr("");

            setRedirectCaseTable(true);
          }
          if(caseCreatedDetails.success == false)
          {
            Swal.fire({
              icon: 'error',
              text: caseCreatedDetails.errors,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK',
              timer: 5000,
            })
          }
        })
        .catch(err => {
          setShowProcedure(false);
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        });
    }
  }

  const changeSeviceFlagFunc = async() => {
    await setchangeSeviceFlag(true);
  }

  const changeCloseFunc = async() => {
    await setchangeSeviceFlag(false);
  }

  if( RedirectCaseTable == true ){
    return <Redirect to={`/cases`} />
  }
  else{
  return (
    // console.log("selectedClient in return",selectedClient,newCaseDetails),
    console.log("selectedCaseDetails in return",selectedCaseDetails),
    <div className={classes.root}>
      <div>
          {ShowProcedure ? <Modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader color={'#255ab0'} size='40px' loading='true' />
          </Modal>: null}
      </div>
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div className={classes.wrapper}>
          <Grid container>
              <Grid item xs={1} className={classes.pageHeaderLabel} >
                <Button href="/cases" className={classes.backButton}>
                    <span className={classes.backArrow}>
                    <ArrowBackIcon fontSize="small" />
                  </span>
                  Back
                </Button>
              </Grid>
            </Grid>
          <Grid container className={classes.pageHeaderContainer} style={{marginTop:'3%'}}>
            <Grid item xs={12} className={classes.pageHeaderLabel}>
              {/* <FormattedMessage id="newCasesLabel" /> */}
              Edit Case
            </Grid>
            <Grid item xs={12} className={classes.linkBackToCase}>
              <Link to="/cases" className={classes.linkNoUnderline}>
                {/* <FormattedMessage id="navigateToCase" /> */}
                View List of Cases
              </Link>
            </Grid>
          </Grid>
          {/* <Grid container className={classes.linkBackToCase}>
            <Grid item xs={12}>
              <Link to="/cases" className={classes.linkNoUnderline}>
                {/* <FormattedMessage id="navigateToCase" /> */}
                {/* View List of Cases
              </Link>
            </Grid>
          </Grid> */}

          <Grid container className={classes.wrapperInner}>
            <Grid item xs={12} className={classes.rootInner}>

              {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                Client Name or PAN *
              </Grid> */}
              <Grid item xs={12} style={{marginTop:'2%'}}>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    name="description"
                    variant="outlined"
                    fullWidth
                    disabled
                    // label="Client Name or PAN"
                    label=" Client Name"
                    required
                    value={selectedCaseDetails.client_name}
                  />
                </Grid>
              </Grid>

              {/* {
                ( selectedClient !== null && selectedClient !== "")?
                ( */}
                  <Grid item xs={12} style={{marginTop:'4%'}}>
                    {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                      Case Name *
                    </Grid> */}
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        name="name"
                        variant="outlined"
                        fullWidth
                        required
                        autoFocus
                        label="Case Name"
                        value={newCaseDetails.name}
                        onChange={(e) => setnewCaseDetails({...newCaseDetails, name:e.target.value }) }
                      />
                    </Grid>
                    <div className={classes.validation}>{(newCaseDetails.name)?(<div></div>):(caseNameErr)}</div>

                    {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                      Case Description *
                    </Grid> */}
                    <Grid item xs={12} style={{marginTop:'4%'}}>
                      <TextField
                        id="description"
                        name="description"
                        variant="outlined"
                        fullWidth
                        required
                        label="Case Description"
                        value={newCaseDetails.description}
                        onChange={(e) => setnewCaseDetails({...newCaseDetails, description:e.target.value }) }
                      />
                    </Grid>
                    <div className={classes.validation}>{(newCaseDetails.description)?(<div></div>):(caseDescriptionErr)}</div>

                    {
                      ( changeSeviceFlag == false)?(
                        <Grid item xs={12}>
                          <Grid item xs={12} className={classes.subHeadingLabel}>
                            Case Service *
                          </Grid>
                          <Grid container item xs={12}>
                            {selectedCaseDetails.service}
                            <EditIcon onClick={changeSeviceFlagFunc}/>
                          </Grid>
                          <div className={classes.validation}>{(newCaseDetails.service_id)?(<div></div>):(caseServiceErr)}</div>
                        </Grid>
                        
                      ):
                      (
                        // <Grid item xs={12}>

                        //   <Grid item xs={12} container className={classes.subHeadingLabel}>
                        //     <Grid className={classes.subHeadingLabel}>
                        //       Case Super Catgory
                        //     </Grid>
                        //     <Grid  style={{marginTop:'2%', marginLeft:'2%'}}>
                        //       <CloseIcon onClick={changeCloseFunc}/>
                        //     </Grid>
                        //   </Grid>
                        //   <Grid container item xs={12}>
                        //     {
                        //       superCategoryList.map((item, index) =>{
                        //       // console.log("item map is",item);
                        //         return(
                        //           <RadioGroup
                        //             row
                        //             aria-label="position"
                        //             name="position"
                        //             value={superCategoryValue}
                        //             // onChange={(event, value) => setsuperCategoryValue(value) }
                        //             onChange={handleChangeSuperAcategory}
                        //           >                                     
                        //             <div className={classes.customRadioButton}>
                        //               <FormControlLabel
                        //                 key={index}
                        //                 value={item.category_id}
                        //                 classes={{ label: classes.label }}
                        //                 control={<Radio size="small" color="primary" />}
                        //                 label={item.name}
                        //               />
                        //             </div>                        
                        //           </RadioGroup>
                        //         )
                        //       })
                        //     }
                        //   </Grid>
                        //   <div className={classes.validation}>{(newCaseDetails.service_id)?(<div></div>):(caseServiceErr)}</div>

                        //   {/* second sub category options */}
                        //   {
                        //     ( SubCtegoryFetched == true )?
                        //     (
                        //       <Grid container>
                        //         <Grid item xs={12} className={classes.subHeadingLabel}>
                        //           Case Service or Category
                        //         </Grid>
                        //         <Grid container item xs={12}>
                        //         {
                        //           subCategoryList.map((item, index) =>{
                        //           // console.log("item map is",item);
                        //             return(
                        //               <RadioGroup
                        //                 row
                        //                 aria-label="position"
                        //                 name="position"
                        //                 value={subCategoryValue}
                        //                 onChange={(e) => handleChangeSubcategory(e, item.category_type)}
                        //               >                  
                        //                 <div className={classes.customRadioButton}>
                        //                   <FormControlLabel
                        //                     key={index}
                        //                     value={item.category_id}
                        //                     classes={{ label: classes.label }}
                        //                     control={<Radio size="small" color="primary" />}
                        //                     label={item.name}
                        //                   />
                        //                 </div>                                
                        //               </RadioGroup>
                        //             )
                        //           })
                        //         }
                        //         </Grid>
                        //       </Grid>
                        //     ):
                        //     (<div></div>)
                        //   }

                        //   {/* Third if service navigate else if category then show options */}
                        //   {
                        //     ( SelectedCategoryIsCategory == true)?
                        //     (
                        //       <Grid container>
                        //         <Grid item xs={12} className={classes.subHeadingLabel}>
                        //           Case Category
                        //         </Grid>
                        //         <Grid container item xs={12}>
                        //         {
                        //           categoryDetailsLastList.map((item, index) =>{
                        //           // console.log("item map is",item);
                        //             return(
                        //               <RadioGroup
                        //                 row
                        //                 aria-label="position"
                        //                 name="position"
                        //                 value={lastCategoryValue}
                        //                 onChange={handleChangeLastCategory}
                        //               >                                            
                        //                 <div className={classes.customRadioButton}>
                        //                   <FormControlLabel
                        //                     key={index}
                        //                     value={item.category_id}
                        //                     classes={{ label: classes.label }}
                        //                     control={<Radio size="small" color="primary" />}
                        //                     label={item.name}
                        //                   />
                        //                 </div>                             
                        //               </RadioGroup>
                        //             )
                        //           })
                        //         }
                        //         </Grid>
                        //       </Grid>
                        //     ):
                        //     (<div></div>)
                        //   }

                        // </Grid>
                        <Grid container item xs={12}>
                              <Grid item xs={4} style={{marginTop:'2%'}}> 
                                <Grid className={classes.subHeadingLabelSelect} >
                                  Case Super Catgory *
                                </Grid>
                                {/* {
                                  superCategoryList.map((item, index) =>{
                                  // console.log("item map is",item);
                                    return(
                                      <RadioGroup
                                        row
                                        aria-label="position"
                                        name="position"
                                        value={superCategoryValue}
                                        // onChange={(event, value) => setsuperCategoryValue(value) }
                                        onChange={handleChangeSuperAcategory}
                                      >                                     
                                        <div className={classes.customRadioButton}>
                                          <FormControlLabel
                                            key={index}
                                            value={item.category_id}
                                            classes={{ label: classes.label }}
                                            control={<Radio size="small" color="primary" />}
                                            label={item.name}
                                          />
                                        </div>                        
                                      </RadioGroup>
                                    )
                                  })
                                } */}
                                <Grid>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                      native
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={superCategoryValue}
                                      // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                      onChange = {handleChangeSuperAcategory}
                                    >
                                      <option value="" style={{color:'grey'}}>Select Category</option>
                                        {superCategoryList && superCategoryList.map(item =>
                                          <option value={item.category_id}>{item.name}</option>
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <div className={classes.validation}>{(newCaseDetails.service_id)?(<div></div>):(caseServiceErr)}</div>
                              </Grid>
                            
                              {/* second sub category options */}
                              {
                                ( SubCtegoryFetched == true )?
                                (
                                  <Grid item xs={4} style={{marginTop:'2%'}} >
                                    <Grid className={classes.subHeadingLabelSelect}>
                                      Case Service or Category
                                    </Grid>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                      <Select
                                        native
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={subCategoryValue}
                                        // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                        onChange={(e) => handleChangeSubcategory(e)}
                                      >
                                        <option value="" style={{color:'grey'}}>Select Category</option>
                                          {subCategoryList && subCategoryList.map(item =>
                                            <option value={item.category_id}>{item.name}</option>
                                        )}
                                      </Select>
                                    </FormControl>
                                    {/* <Grid container item xs={12}>
                                    {
                                      subCategoryList.map((item, index) =>{
                                      // console.log("item map is",item);
                                        return(
                                          <RadioGroup
                                            row
                                            aria-label="position"
                                            name="position"
                                            value={subCategoryValue}
                                            onChange={(e) => handleChangeSubcategory(e, item.category_type)}
                                          >                  
                                            <div className={classes.customRadioButton}>
                                              <FormControlLabel
                                                key={index}
                                                value={item.category_id}
                                                classes={{ label: classes.label }}
                                                control={<Radio size="small" color="primary" />}
                                                label={item.name}
                                              />
                                            </div>                                
                                          </RadioGroup>
                                        )
                                      })
                                    }
                                    </Grid> */}
                                  </Grid>
                                ):
                                (<div></div>)
                              }
                            
                              {/* Third if service navigate else if category then show options */}
                              {
                                ( SelectedCategoryIsCategory == true)?
                                (
                                  <Grid item xs={4} style={{marginTop:'2%'}}>
                                    <Grid className={classes.subHeadingLabelSelect}>
                                      {/* Case Category */}
                                      Case Service
                                    </Grid>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                      <Select
                                        native
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={lastCategoryValue}
                                        // onChange={(e) => settaskDetails({...taskDetails, todolist_id: JSON.parse(e.target.value) }) }
                                        onChange={(e) => handleChangeLastCategory(e)}
                                      >
                                        <option value="" style={{color:'grey'}}>Select Category</option>
                                          {categoryDetailsLastList && categoryDetailsLastList.map(item =>
                                            <option value={item.category_id}>{item.name}</option>
                                        )}
                                      </Select>
                                    </FormControl>
                                    {/* <Grid container item xs={12}>
                                    {
                                      categoryDetailsLastList.map((item, index) =>{
                                      // console.log("item map is",item);
                                        return(
                                          <RadioGroup
                                            row
                                            aria-label="position"
                                            name="position"
                                            value={lastCategoryValue}
                                            onChange={handleChangeLastCategory}
                                          >                                            
                                            <div className={classes.customRadioButton}>
                                              <FormControlLabel
                                                key={index}
                                                value={item.category_id}
                                                classes={{ label: classes.label }}
                                                control={<Radio size="small" color="primary" />}
                                                label={item.name}
                                              />
                                            </div>                             
                                          </RadioGroup>
                                        )
                                      })
                                    }
                                    </Grid> */}
                                  </Grid>
                                ):
                                (<div></div>)
                              }

                            </Grid>
                      )
                    }

                    <Grid item xs={4} style={{marginTop:'2%'}}>
                      <Grid className={classes.subHeadingLabelSelect}>
                        Assesment Year
                      </Grid>
                      {/* <Grid style={{textAlign: "left",marginLeft:'2%'}}>
                        <DatePicker
                          selected={Date.parse(moment(newCaseDetails.assessment_year, 'YYYY').toISOString())}
                          onChange={(date) => handleChangeAssesmentYear(date)}
                          placeholderText="Select Year"
                          showYearPicker
                          dateFormat="yyyy"
                          yearItemNumber={8}
                          minDate={minDate}
                          maxDate={dueDate}
                          className={classes.datePickerStyle}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        // containerstyle={{height:'50%'}}
                        />
                      </Grid> */}
                        <FormControl variant="outlined" className={classes.formControl}>
                          <Select
                            native
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={newCaseDetails.assessment_year}
                            onChange={(e) => setnewCaseDetails({...newCaseDetails, assessment_year:e.target.value }) }
                          >
                            <option value="" style={{color:'grey'}}>Select Assesment Year</option>
                            {AssesmentYearArray && AssesmentYearArray.value.map(item =>
                              <option value={item.value}>{item.item}</option>
                            )}
                            {/* <option value="AY-2014-15" >AY-2014-15</option>
                            <option value="AY-2015-16" >AY-2015-16</option>
                            <option value="AY-2016-17" >AY-2016-17</option>
                            <option value="AY-2017-18" >AY-2017-18</option>
                            <option value="AY-2018-19" >AY-2018-19</option>
                            <option value="AY-2019-20" >AY-2019-20</option>
                            <option value="AY-2020-21" >AY-2020-21</option>
                            <option value="AY-2021-22" >AY-2021-22</option>
                            <option value="AY-2022-23" >AY-2022-23</option>
                            <option value="AY-2023-24" >AY-2023-24</option>
                            <option value="AY-2024-25" >AY-2024-25</option>
                            <option value="N/A" >N/A</option>  */}
                          </Select>
                        </FormControl>
                    </Grid>
                    <div className={classes.validation}>{(newCaseDetails.assessment_year)?(<div></div>):(caseAssesYearErr)}</div>
                    

                    {/* <Grid item xs={12} className={classes.subHeadingLabel}>
                    Case Super Catgory
                    </Grid>
                    <Grid container item xs={12}>
                    {
                      superCategoryList.map((item, index) =>{
                      // console.log("item map is",item);
                        return(
                          <RadioGroup
                            row
                            aria-label="position"
                            name="position"
                            value={superCategoryValue}
                            // onChange={(event, value) => setsuperCategoryValue(value) }
                            onChange={handleChangeSuperAcategory}
                          >                                     
                            <div className={classes.customRadioButton}>
                              <FormControlLabel
                                key={index}
                                value={item.category_id}
                                classes={{ label: classes.label }}
                                control={<Radio size="small" color="primary" />}
                                label={item.name}
                              />
                            </div>                        
                          </RadioGroup>
                        )
                      })
                    }
                    </Grid> */}

                  
                  <Grid alignItems={'center'} style={{marginTop:'2%'}}>
                    <Button
                      type="submit"
                      //   fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handelSubmitUpdateCase}
                      >
                      Update Case
                    </Button>

                    <Button
                      style={{marginLeft:'1%'}}
                      type="submit"
                      //   fullWidth
                      variant="contained"
                      // color="primary"
                      className={classes.submit}
                      href="/cases"
                      // onClick={handelSubmitCreateCase}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  </Grid>
              {/* //   ):
              //   (<div></div>)
              // } */}


            </Grid>
          </Grid>

        </div>
      </Slide>
    </div>
  );
  }
}

export default injectIntl(TrpEditCase);
