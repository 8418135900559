import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NewCaseGeneralDetailsClientCases from "./NewCaseGeneralDetailsClientCases";
import NewCaseQuestionnaire1ClientCases from "./NewCaseQuestionnaire1ClientCases";
import NewCaseQuestionnaire2ClientCases from "./NewCaseQuestionnaire2ClientCases";
import NewCaseQuestionnaire3ClientCases from "./NewCaseQuestionnaire3ClientCases";
import NewCaseUploadFileClientCases from "./NewCaseUploadFileClientCases";
import NewCaseUploadFileClientCasesPersonal from "./NewCaseUploadFileClientCasesPersonal";
import { Link, Redirect, useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import { authHeader } from '../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { PropagateLoader } from 'react-spinners';
// import Modal from '@material-ui/core/Modal';
import { Modal } from 'reactstrap';
import useCheckSessionApi from "../SessionValidity";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { addsseInfo , addcreateCaseDetails, addnewCaseCreateCaseId, newCaseActionReset } from "../actions/newcase.actions";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    // marginBottom: "5%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
  linkBackToCase: {
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    marginTop: "-2%",
    marginBottom: "2%",
    textAlign: "right",
  },
  linkNoUnderline: {
    textDecoration: "none",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
}));

function getSteps() {
  return [
    "generalDetails",
    "questionnaire1",
    "questionnaire2",
    "questionnaire3",
    "fileUploadBusiness",
    "fileUploadPersonal",
  ];
}

// function getStepContent(step) {
//   switch (step) {
//     case 0:
//       return <NewCaseGeneralDetailsClientCases parentCallback={handleNext()} />;
//     case 1:
//       return <NewCaseQuestionnaire1ClientCases />;
//     case 2:
//       return <NewCaseQuestionnaire2 />;
//     case 3:
//       return <NewCaseQuestionnaire3 />;
//     case 4:
//       return <NewCaseUploadFile />;
//     default:
//       return "Unknown step";
//   }
// }

function TaxManagementClientCases({ intl, props }) {
  const history = useHistory();
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [ editItemIs, seteditItemIs ] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_edit')));
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] =  React.useState(JSON.parse(localStorage.getItem('session_id')));

  const [case_creation_details, setcase_creation_details] = React.useState(JSON.parse(localStorage.getItem('case_creation_details')));
  const [created_case_case_id, setcreated_case_case_id] = React.useState(JSON.parse(localStorage.getItem('created_case_case_id')));

  const [formState, setformState] = React.useState(0);

  const [CreateCaseDetails, setCreateCaseDetails] = React.useState({});

  const [ NextOrBackIs, setNextOrBackIs ] = React.useState("next");

  // variables from general state
  const dispatch = useDispatch();

  const selfAssesFromRedux = useSelector(state => state.newCase.selfAssesment);
  const newCaseCreatedCaseIdRedux = useSelector(state => state.newCase.newCaseCreatedCaseId);
  const sseInfoFromReduxOnMainPage = useSelector(state => state.newCase.sseInfo);
  const createCaseDetailsRedux = useSelector(state => state.newCase.createCaseDetails);
  const lastCategoryFromRedux = useSelector(state => state.newCase.lastCategory);
  const assesmentYearFromRedux = useSelector(state => state.newCase.yearAss);

  const addnewCaseCreateCaseIdRedux = (caseId) => dispatch(addnewCaseCreateCaseId(caseId));
  const addSseInfoRedux = (sseInfo) => dispatch(addsseInfo(sseInfo));
  const addCreateCaseDetailsRedux = (CreateCaseDetails) => dispatch(addcreateCaseDetails(CreateCaseDetails));
  const newCaseActionResetCallRedux = () => dispatch(newCaseActionReset());


  const [selectedServiceId, setselectedServiceId] = React.useState(0);
  const [SelfAssesment, setSelfAssesment] = React.useState();
  // const [SelfAssesmentFromRedux, setSelfAssesmentFromRedux] = React.useState();
  const [SSEInfo, setSSEInfo] = React.useState({});
  const [CountryCodeHere, setCountryCodeHere] = React.useState("");
  const [AssesmentYearHere, setAssesmentYearHere] = React.useState("");

  const [ sseNameErr, setsseNameErr ] = React.useState("");
  const [ sseEmailErr, setsseEmailErr ] = React.useState("");
  const [ ssePhoneNoErr , setssePhoneNoErr ] = React.useState("");
  const [ ssePanErr , setssePanErr ] = React.useState("");
  const [ sseServiceErr , setsseServiceErr ] = React.useState("");
  const [ asseAssessmentYearErr, setasseAssessmentYearErr] = React.useState("");

  const [serviceIdErr , setserviceIdErr ] = React.useState("");
  const [assesmentYearErr, setassesmentYearErr] = React.useState("");

  // variables from Question Level One
  const [formStateQLOne, setformStateQLOne] = React.useState(0);
  const [SubmitAnsObject, setSubmitAnsObject] = React.useState({});

  const [qsn_list, setqsn_list] = React.useState([]);
  const [Qsn_Answer_list, setQsn_Answer_list] = React.useState([]);
  const [options_list, setOptions_list] = React.useState([]);
  const qsnListFromRedux = useSelector(state => state.newCase.qsnList);

  // variables from Question Level Two
  const [formStateQLTwo, setformStateQLTwo] = React.useState(0);

  const [qsn_list_LTwo, setqsn_list_LTwo] = React.useState([]);
  const [Qsn_Answer_list_LTwo, setQsn_Answer_list_LTwo] = React.useState([]);
  const [options_list_LTwo, setOptions_list_LTwo] = React.useState([]);
  const qsnListLevelTwoFromRedux = useSelector(state => state.newCase.qsnListLevelTwo);

  // variables from Question Level Three
  const [formStateQLThree, setformStateQLThree] = React.useState(0);

  const[qsn_list_LThree, setqsn_list_LThree] = React.useState([]);
  const [Qsn_Answer_list_LThree, setQsn_Answer_list_LThree] = React.useState([]);
  const [options_list_LThree, setoptions_list_LThree] = React.useState([]);
  const qsnListLevelThreeFromRedux = useSelector(state => state.newCase.qsnListLevelThree);

  // variables from Upload Files
  const [formStateUploadFile, setformStateUploadFile] = React.useState(0);

  // variables from file upload personal
  const [formStateUploadFilePersonal, setformStateUploadFilePersonal] = React.useState(0);
  const [RedirectToCaseList, setRedirectToCaseList] = React.useState(false);

  const [flag , setflag] = React.useState(false)
  const [DeviceToken, setDeviceToken] =   React.useState();
  const [SessionId, setSessionId] =   React.useState();
  const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
  const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
  let user = JSON.parse(localStorage.getItem('user'));
  const [data, checkSessionValidity] = useCheckSessionApi({
    url: process.env.REACT_APP_CHECK_SESSION_VALIDITY ,
    options: {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Token " + user.key,
      },
      body: JSON.stringify({
        device_token: deviceTokenFromRedux,
        session_id: sessionIdFromRedux,
      }),
    },
    props,
  });

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        // return <NewCaseGeneralDetailsClientCases onSelectService={handleNext}/>
        return <NewCaseGeneralDetailsClientCases onSelectService={handleChangeFormState}
          sseNameErr={sseNameErr} sseEmailErr={sseEmailErr} ssePhoneNoErr={ssePhoneNoErr}
          ssePanErr={ssePanErr} sseServiceErr={sseServiceErr} asseAssessmentYearErr={asseAssessmentYearErr}
          serviceIdErr={serviceIdErr} assesmentYearErr={assesmentYearErr}/>
      case 1:
        return <NewCaseQuestionnaire1ClientCases selectedServiceId={selectedServiceId}
          onSelectQuestionLevelOne={handleChangeFormStateQuestionOne} />;
        //  return <NewCaseQuestionnaire1ClientCases activeStep={activeStep} onChange={handleNext} />;
      case 2:
        return <NewCaseQuestionnaire2ClientCases onSelectQuestionLevelTwo={handleChangeFormStateQuestionTwo}/>;
      case 3:
        return <NewCaseQuestionnaire3ClientCases  onSelectQuestionLevelThree={handleChangeFormStateQuestionThree}/>;
      case 4:
        return <NewCaseUploadFileClientCases onSelectUploadFiles={handleChangeFormStateUploadFiles} />;
      case 5:
        return <NewCaseUploadFileClientCasesPersonal onSelectUploadFilesPersonal={handleChangeFormStateUploadFilesPersonal} />;
      default:
        return "Unknown step";
    }
  }

  useEffect(() => {
    setflag(false);
    readItemFromStorage();
  }, [flag]);

  const readItemFromStorage = async() => {
    try {
      setDeviceToken(deviceTokenFromRedux)
      setSessionId(sessionIdFromRedux)
      setflag(false)
      } catch (error) {
        console.log("ERROR:",error);
      }
  };

  const handleChangeFormState = async(formState, serviceId,forSelf,SSEInfo,CountryCode,assesment_year) => {
     console.log('I will submit my ChildForm Input State:, serviceID is,',formState,serviceId);
     console.log('serviceID is, forSelf, SSEInfo',serviceId, forSelf, SSEInfo);
     console.log('serviceId, forSelf==',serviceId, forSelf);
     console.log('sseInfoFromReduxOnMainPage',sseInfoFromReduxOnMainPage);
     console.log("CountryCode is",CountryCode);
     console.log("assesment_year is",assesment_year);
     await setformState(formState);
     await setselectedServiceId(serviceId);

     await setSelfAssesment(JSON.parse(forSelf));
    //  await setSelfAssesment(forSelf);

     await setCountryCodeHere(CountryCode);
     await setAssesmentYearHere(assesment_year);

      if( forSelf == "true" || forSelf == true){
        await setsseNameErr("");
        await setsseEmailErr("");
        await setssePhoneNoErr("");
        await setssePanErr("");
        await setsseServiceErr("");
        await setasseAssessmentYearErr("");
      }

    ////////////////////////////////
    // this code is causing that create case 1sr select service and then other info then getting last value blank /////////
      //   if( newCaseCreatedCaseIdRedux!== undefined){
      //    await addSseInfoRedux({...SSEInfo,
      //     case_id: newCaseCreatedCaseIdRedux,
      //     service_id: JSON.parse(serviceId),
      //    })
      //  }
    //  if(sseInfoFromReduxOnMainPage !== undefined ){
    //    await setSSEInfo(sseInfoFromReduxOnMainPage)
    //    await addSseInfoRedux({...SSEInfo,
    //     case_id: newCaseCreatedCaseIdRedux,
    //     service_id: JSON.parse(serviceId),
    //    })
    //    console.log('sse info in condition',sseInfoFromReduxOnMainPage);
    //  }
    ////////////////////////////////////////////////////////////////////

   let user_details_here =  JSON.parse(localStorage.getItem('users'));
   console.log("user_details_here on new case for client",user_details_here, 
      user_details_here.data.user_details.first_name)
    let nameHere = "Case for"+ ' ' + user_details_here.data.user_details.first_name + ' ' +
      user_details_here.data.user_details.middle_name + ' ' + user_details_here.data.user_details.surname

    console.log("case nameHere is",nameHere)

     await setCreateCaseDetails({
        created_by: "client",

        // service_id: serviceId,
        // self_assessment: forSelf,
        service_id: JSON.parse(serviceId),
        self_assessment: JSON.parse(forSelf),

        assessment_year: assesment_year,
        device_token: device_token,
        session_id: session_id,
        name: nameHere,
     });
    //  await addCreateCaseDetailsRedux(CreateCaseDetails);    coz of this line
     await addCreateCaseDetailsRedux({...CreateCaseDetails,
        created_by: "client",

        // service_id: serviceId,
        // self_assessment: forSelf,
        service_id: JSON.parse(serviceId),
        self_assessment: JSON.parse(forSelf),

        assessment_year: assesment_year,
        device_token: device_token,
        session_id: session_id,
        case_id: newCaseCreatedCaseIdRedux,
        name: nameHere,
      })

    //  if(JSON.parse(localStorage.getItem('created_case_case_id')) !== null){
    //   await setCreateCaseDetails({
    //       created_by: "client",
    //       service_id: JSON.parse(serviceId),
    //       self_assessment: JSON.parse(forSelf),
    //       device_token: device_token,
    //       session_id: session_id,
    //       case_id: created_case_case_id
    //     });

    //     // setSSEInfo(sseInfoFromReduxOnMainPage)
    //     await addSseInfoRedux({...SSEInfo, service_id: JSON.parse(serviceId), case_id: created_case_case_id })
    //     await setSSEInfo({...SSEInfo, service_id: JSON.parse(serviceId), case_id: created_case_case_id })
    //     setflag(false);
    //  }

  }

  // const handleChangeFormStateQuestionOne = async(formState,Qsn_Answer_list ) => {
  //   console.log('call of fun in main page with form state',formState);
  //   console.log('call of fun in main page with Qsn_Answer_list',Qsn_Answer_list);
  //   setformStateQLOne(formState);
  //   let case_id_details = JSON.parse(localStorage.getItem('case_creation_details'));
  //   console.log("case_id_details in que l1 form state",case_id_details);
  //   let case_id = case_id_details.case_id
  //   console.log("case_id in que l1 form state",case_id);
  //   console.log('ActiveStep check in q l1',activeStep);
  //   await setSubmitAnsObject({
  //     case_id: case_id,
  //     question_answers: Qsn_Answer_list,
  //     device_token: device_token,
  //     session_id: session_id,
  //   })
  //   console.log("SubmitAnsObject",SubmitAnsObject)
  // }
  const handleChangeFormStateQuestionOne = async(formState,qsn_list,empty) => {
    // console.log('call of fun in main page with form state',formState);
    // console.log('call of fun in main page with Qsn_Answer_list',qsn_list);
    setqsn_list(qsn_list);
    setformStateQLOne(formState);
    if( empty == "Yes Empty"){
      if( NextOrBackIs == "next" ){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if( NextOrBackIs == "back" ){
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }

      //   console.log("yes array is empty");
      // Swal.fire({
      //   // icon: 'error',
      //   text: "Questions are not available for this set one.Please navigate to next set",
      //   confirmButtonColor: 'primary',
      //   cancelButtonColor: '#D3C8C7',
      //   confirmButtonText: 'OK',
      //   showCancelButton: true,
      //   cancelButtonText: 'cancel',
      //   // timer: 5000,
      // }).then(function (result) {
      //     console.log("result of swal",result.isConfirmed)
      //     if(result.isConfirmed == true){
      //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //     }
      //     else if(result.isConfirmed == false){
      //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
      //     }
      // })
    }
  }

  const handleChangeFormStateQuestionTwo = async(formState,qsn_list,empty) => {
    // console.log('call of fun in main page with form state',formState);
    // console.log('call of fun in main page with qsn_list',qsn_list);
    setformStateQLTwo(formState);
    setqsn_list_LTwo(qsn_list);
    if( empty == "Yes Empty"){
      if( NextOrBackIs == "next" ){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if( NextOrBackIs == "back" ){
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }

      //   console.log("yes array is empty");
      // Swal.fire({
      //   // icon: 'error',
      //   text: "Questions are not available for this set two.Please navigate to next set",
      //   confirmButtonColor: 'primary',
      //   cancelButtonColor: '#D3C8C7',
      //   confirmButtonText: 'OK',
      //   showCancelButton: true,
      //   cancelButtonText: 'cancel',
      //   // timer: 5000,
      // }).then(function (result) {
      //     console.log("result of swal",result.isConfirmed)
      //     if(result.isConfirmed == true){
      //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //     }
      //     else if(result.isConfirmed == false){
      //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
      //     }
      // })
    }
  }

  const handleChangeFormStateQuestionThree  = async(formState,qsn_list,empty) => {
    // console.log('call of fun in main page with form state',formState);
    // console.log('call of fun in main page with qsn_list',qsn_list);
    setformStateQLThree(formState);
    setqsn_list_LThree(qsn_list);
    if( empty == "Yes Empty"){
      if( NextOrBackIs == "next" ){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if( NextOrBackIs == "back" ){
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }

      //   console.log("yes array is empty");
      // Swal.fire({
      //   // icon: 'error',
      //   text: "Questions are not available for this set three.Please navigate to next set",
      //   confirmButtonColor: 'primary',
      //   cancelButtonColor: '#D3C8C7',
      //   confirmButtonText: 'OK',
      //   showCancelButton: true,
      //   cancelButtonText: 'cancel',
      //   // timer: 5000,
      // }).then(function (result) {
      //     console.log("result of swal",result.isConfirmed)
      //     if(result.isConfirmed == true){
      //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //     }
      //     else if(result.isConfirmed == false){
      //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
      //     }
      // })
    }
  }

  const handleChangeFormStateUploadFiles = async(formState,empty) => {
    setformStateUploadFile(formState);
    // if( empty == "Yes Empty"){
    //   if( NextOrBackIs == "next" ){
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //   }
    //   if( NextOrBackIs == "back" ){
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //   }

      //   console.log("yes array is empty");
        // Swal.fire({
        //   // icon: 'error',
        //   text: "Documents are not available for this set.Please navigate to next set",
        //   confirmButtonColor: 'primary',
        //   cancelButtonColor: '#D3C8C7',
        //   confirmButtonText: 'OK',
        //   showCancelButton: true,
        //   cancelButtonText: 'cancel',
        //   // timer: 5000,
        // }).then(function (result) {
        //     console.log("result of swal",result.isConfirmed)
        //     if(result.isConfirmed == true){
        //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //     }
        //     else if(result.isConfirmed == false){
        //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
        //     }
        // })
      // }
  }

  const handleChangeFormStateUploadFilesPersonal = async(formState,empty) => {
    console.log('call of fun in main page with form state',formState);
    setformStateUploadFilePersonal(formState);
    // if( empty == "Yes Empty"){
    //   if( NextOrBackIs == "next" ){
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //   }
    //   if( NextOrBackIs == "back" ){
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //   }
      
      //   console.log("yes array is empty");
        // Swal.fire({
        //   // icon: 'error',
        //   text: "Documents are not available for this set.Please Click Finish to save the case",
        //   confirmButtonColor: 'primary',
        //   cancelButtonColor: '#D3C8C7',
        //   confirmButtonText: 'OK',
        //   showCancelButton: true,
        //   cancelButtonText: 'cancel',
        //   // timer: 5000,
        // }).then(function (result) {
        //     console.log("result of swal",result.isConfirmed)
        //     if(result.isConfirmed == true){
        //       setActiveStep((prevActiveStep) => prevActiveStep);
        //     }
        //     else if(result.isConfirmed == false){
        //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
        //     }
        // })
      // }
  }

  const validateSSEInfo = () => {
    console.log("SSEInfo in validation, CountryCodeHere",SSEInfo, CountryCodeHere);
    console.log('sse info in validation from redux',sseInfoFromReduxOnMainPage);
    let sseNameErr = '';
    let sseEmailErr = '';
    let ssePhoneNoErr = '';
    let ssePanErr = '';
    let sseServiceErr = '';
    let asseAssessmentYearErr = '';

    if(!sseInfoFromReduxOnMainPage.assesse_name)  {
      sseNameErr = 'Please Enter ASSESSE Name';
    }
    if(!sseInfoFromReduxOnMainPage.assesse_email)  {
      sseEmailErr = 'Please Enter ASSESSE Email';
    }
      else if(typeof sseInfoFromReduxOnMainPage.assesse_email !== "undefined"){
            let lastAtPos = sseInfoFromReduxOnMainPage.assesse_email.lastIndexOf('@');
            let lastDotPos = sseInfoFromReduxOnMainPage.assesse_email.lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
                sseEmailErr = "Email is not valid";
                if(sseEmailErr) {
                  setsseEmailErr(sseEmailErr);
                }
            }
          else {
              setsseEmailErr();
            }
        }
      else{
            setsseEmailErr();
      }

    if(!sseInfoFromReduxOnMainPage.assesse_phone)  {
      ssePhoneNoErr = 'Please Enter ASSESSE Phone Number';
    }
     else if (typeof sseInfoFromReduxOnMainPage.assesse_phone !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/)

        // if (!pattern.test(sseInfoFromReduxOnMainPage.assesse_phone)) {
        //   ssePhoneNoErr = "Please enter only number.";
        // }else if(sseInfoFromReduxOnMainPage.assesse_phone.length <= 11){
        //   ssePhoneNoErr = "Please enter valid phone number.";
        // }
        if (CountryCodeHere == "in"){
          console.log("sseInfoFromReduxOnMainPage.assesse_phone.length",sseInfoFromReduxOnMainPage.assesse_phone.length)
          // if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 15){
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "us"){
          console.log("sseInfoFromReduxOnMainPage.assesse_phone.length",sseInfoFromReduxOnMainPage.assesse_phone.length)
          // if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 17){
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "gb"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "ca"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "ae"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 12){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "sg"){
          // console.lo("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 8){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "np"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 12){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "jp"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "de"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 12){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "bt"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 12){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "au"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 13){
            ssePhoneNoErr = "Please enter valid phone number.";
          }
        }
      }

    if(!sseInfoFromReduxOnMainPage.assesse_pan)  {
      ssePanErr = 'Please Enter ASSESSE PAN';
    }
    else if (typeof sseInfoFromReduxOnMainPage.assesse_pan !== "undefined") {
        var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
        if(sseInfoFromReduxOnMainPage.assesse_pan.length !== 10){
          console.log("in != 10")
          ssePanErr = "PAN Number is Not Valid";
        }
        else if (!pattern.test(sseInfoFromReduxOnMainPage.assesse_pan)) {
          console.log("in string check")
          ssePanErr = "PAN Number is Not Valid";
        }
      }
      else{
            setssePanErr();
      }

    if(!sseInfoFromReduxOnMainPage.service_id)  {
      sseServiceErr = 'Please Select Service';
    }
    if(!sseInfoFromReduxOnMainPage.assessment_year)  {
      console.log("in asseAssessmentYearErr ")
      asseAssessmentYearErr = 'Please Select Assesment Year';
    }

    if(sseNameErr || sseEmailErr || ssePhoneNoErr || ssePanErr || sseServiceErr || asseAssessmentYearErr ){
      setsseNameErr(sseNameErr);
      setsseEmailErr(sseEmailErr);
      setssePhoneNoErr(ssePhoneNoErr);
      setssePanErr(ssePanErr);
      setsseServiceErr(sseServiceErr);
      setasseAssessmentYearErr(asseAssessmentYearErr);

      return false;
    }
    return true;
  }

  const validateSelfAssTrueUndefinedRedux = () => {
    console.log("validateSelfAssTrueUndefinedRedux CreateCaseDetails",CreateCaseDetails);
    let serviceIdErr = '';
    let assesmentYearErr = '';

    if(!CreateCaseDetails.service_id)  {
      serviceIdErr = 'Please Select Service';
      // console.log("in validateSSEInfo sseNameErr");
    }
    if(!CreateCaseDetails.assessment_year)  {
      assesmentYearErr = 'Please Select Assessment Year';
    }

    if(serviceIdErr || assesmentYearErr){
      setserviceIdErr(serviceIdErr);
      setassesmentYearErr(assesmentYearErr);

      return false;
    }
    return true;
  }

  const validateSelfAssTrueDefinedRedux = () => {
    console.log("validateSelfAssTrueDefinedRedux createCaseDetailsRedux",createCaseDetailsRedux);
    let serviceIdErr = '';
    let assesmentYearErr = '';

    if(!createCaseDetailsRedux.service_id)  {
      serviceIdErr = 'Please Select Service';
      // console.log("in validateSSEInfo sseNameErr");
    }
    if(!createCaseDetailsRedux.assessment_year)  {
      assesmentYearErr = 'Please Select Assessment Year';
    }

    if(serviceIdErr || assesmentYearErr){
      setserviceIdErr(serviceIdErr);
      setassesmentYearErr(assesmentYearErr);

      return false;
    }
    return true;
  }

  const handleNext = async() => {
    await setNextOrBackIs("next")
   console.log('ActiveStep & formState is in 1st if',activeStep,formState,)
    console.log('ActiveStep is',activeStep)
    console.log('SelfAssesment in handle next is, selfAssesFromRedux is, assesmentYearFromRedux',
      SelfAssesment,selfAssesFromRedux,CreateCaseDetails,assesmentYearFromRedux)
    // To save General details
    if ( formState == "1" && activeStep == 0 ){
      console.log('ActiveStep and formState is in 1st if sseInfoFromReduxOnMainPage,lastCategoryFromRedux',
      activeStep,formState,sseInfoFromReduxOnMainPage,lastCategoryFromRedux)
      console.log('lastCategoryFromRedux',lastCategoryFromRedux)
      if( !lastCategoryFromRedux || lastCategoryFromRedux == "" || lastCategoryFromRedux == "0"){
        Swal.fire({
          text: "Please Fill Generic form First",
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          timer: 5000,
        })
      }
      //  save General details if self ass is true
      // else if( SelfAssesment == true ){
      else if( selfAssesFromRedux == "true" || selfAssesFromRedux == true ){
        console.log("created_case_case_id is newCaseCreatedCaseIdRedux",newCaseCreatedCaseIdRedux)
        // if(JSON.parse(localStorage.getItem('created_case_case_id')) == null)
        if(newCaseCreatedCaseIdRedux === undefined )
        {
          const isValid = validateSelfAssTrueUndefinedRedux();
          if(isValid){
          setShowProcedure(true);
          console.log("CreateCaseDetails in ss t",CreateCaseDetails)
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({...CreateCaseDetails, business_id: business_id })
          };
          return fetch( process.env.REACT_APP_CREATE_CASE , requestOptions)
            .then((Response) => Response.json())
            .then( async(caseCreated) => {
              console.log("caseCreated sucess",caseCreated);
              setShowProcedure(false);
              if(caseCreated.success == true){
                console.log("It's Success of case creation general details response",caseCreated.data.case_info);
                await addCreateCaseDetailsRedux({...CreateCaseDetails,
                  case_id: caseCreated.data.case_info.case_id })
                await addnewCaseCreateCaseIdRedux(caseCreated.data.case_info.case_id)
                await addSseInfoRedux({
                  case_id: caseCreated.data.case_info.case_id,
                  device_token: JSON.parse(localStorage.getItem('device_token')),
                  session_id: JSON.parse(localStorage.getItem('session_id')),
                })
                await localStorage.setItem('service_id_of_case_for_doc', JSON.stringify(caseCreated.data.case_info.service_id));
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

                setserviceIdErr ("");
                setassesmentYearErr("");

                

                // await setCreateCaseDetails({
                //   created_by: "client",
                //   service_id: JSON.parse(serviceId),
                //   self_assessment: JSON.parse(forSelf),
                //   device_token: device_token,
                //   session_id: session_id,
                //   case_id: created_case_case_id
                // });

                // await localStorage.setItem('case_creation_details', JSON.stringify(caseCreated.data.case_info));
                // await localStorage.setItem('created_case_case_id', JSON.stringify(caseCreated.data.case_info.case_id));
                // setCreateCaseDetails({});
              }
              if(caseCreated.success == false){
                Swal.fire({
                  icon: 'error',
                  text: caseCreated.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
          }
        }
        else if(newCaseCreatedCaseIdRedux !== undefined){
        // if(JSON.parse(localStorage.getItem('created_case_case_id')) !== null){
          // Swal.fire({
          //   icon: 'error',
          //   text: "Case is Created Already",
          //   confirmButtonColor: '#d33',
          //   confirmButtonText: 'OK',
          //   timer: 5000,
          // })
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          const isValid = validateSelfAssTrueDefinedRedux();
          if(isValid){
            setShowProcedure(true);
            console.log("createCaseDetailsRedux in update existing",createCaseDetailsRedux)
            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify({...createCaseDetailsRedux, business_id: business_id})
            };
            return fetch( process.env.REACT_APP_UPDATE_CASE , requestOptions)
              .then((Response) => Response.json())
              .then( async(caseCreated) => {
                setShowProcedure(false);
                console.log("caseCreated sucess",caseCreated.success);
                if(caseCreated.success == true){
                  console.log("update case response",caseCreated.data.case_info);
                  console.log("It's Success of case creation general details update")
                  await addCreateCaseDetailsRedux({...CreateCaseDetails,
                  case_id: caseCreated.data.case_info.case_id })
                  await addSseInfoRedux({
                    case_id: caseCreated.data.case_info.case_id,
                    device_token: JSON.parse(localStorage.getItem('device_token')),
                    session_id: JSON.parse(localStorage.getItem('session_id')),
                  })
                  await addnewCaseCreateCaseIdRedux(caseCreated.data.case_info.case_id)
                  await localStorage.setItem('service_id_of_case_for_doc', JSON.stringify(caseCreated.data.case_info.service_id));
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);

                  setserviceIdErr ("");
                  setassesmentYearErr("");
                  // await localStorage.setItem('case_creation_details', JSON.stringify(caseCreated.data.case_info));
                  // await localStorage.setItem('created_case_case_id', JSON.stringify(caseCreated.data.case_info.case_id));

                  // setCreateCaseDetails({});
                }
                if(caseCreated.success == false){
                  Swal.fire({
                    icon: 'error',
                    text: caseCreated.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
          }
        }
      // }
      //  save General details if self ass is false
      }
      // else if( SelfAssesment == false )
      else if( selfAssesFromRedux == "false" || selfAssesFromRedux == false )
      {
        // if(JSON.parse(localStorage.getItem('created_case_case_id')) == null)
        if(newCaseCreatedCaseIdRedux === undefined )
        {
          const isValid = validateSSEInfo();
          if(isValid){
            // addSseInfoRedux(sseInfoFromReduxOnMainPage)
            setShowProcedure(true);
            console.log("SSEInfo in api call new",SSEInfo)
            console.log("SSEInforedux in api call new",sseInfoFromReduxOnMainPage)
            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify({...sseInfoFromReduxOnMainPage, business_id: business_id })
            };
            return fetch( process.env.REACT_APP_CREATE_CASE , requestOptions)
              .then((Response) => Response.json())
              .then( async(caseCreated) => {
                setShowProcedure(false);
                console.log("caseCreated sucess with form",caseCreated.success);
                if(caseCreated.success == true){
                  console.log("caseCreated sucess with form in next step condition",caseCreated.success);
                  await addSseInfoRedux({...SSEInfo,
                    assesse_name: sseInfoFromReduxOnMainPage.assesse_name,
                    assesse_id:  sseInfoFromReduxOnMainPage.assesse_id,
                    assesse_email: sseInfoFromReduxOnMainPage.assesse_email,
                    assesse_pan: sseInfoFromReduxOnMainPage.assesse_pan,
                    assesse_phone: sseInfoFromReduxOnMainPage.assesse_phone,
                    self_assessment: sseInfoFromReduxOnMainPage.self_assessment,
                    assessment_year: sseInfoFromReduxOnMainPage.assessment_year,
                    service_id: sseInfoFromReduxOnMainPage.service_id,
                    
                    case_id: caseCreated.data.case_info.case_id,
                    device_token: JSON.parse(localStorage.getItem('device_token')),
                    session_id: JSON.parse(localStorage.getItem('session_id')),
                  })
                  await addnewCaseCreateCaseIdRedux(caseCreated.data.case_info.case_id)
                  await localStorage.setItem('service_id_of_case_for_doc', JSON.stringify(caseCreated.data.case_info.service_id));
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  console.log("caseCreated response",caseCreated.data.case_info);
                  console.log("It's Success of case creation general details")

                  // await localStorage.setItem('case_creation_details', JSON.stringify(caseCreated.data.case_info));
                  // await localStorage.setItem('created_case_case_id', JSON.stringify(caseCreated.data.case_info.case_id));

                  setsseNameErr("");
                  setsseEmailErr("");
                  setssePhoneNoErr("");
                  setssePanErr("");
                  setsseServiceErr("");
                  setasseAssessmentYearErr("");
                }
                if(caseCreated.success == false){
                  Swal.fire({
                    icon: 'error',
                    text: caseCreated.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
          }
        }
        else if(newCaseCreatedCaseIdRedux !== undefined){
          // Swal.fire({
          //   icon: 'error',
          //   text: "Case is Created Already",
          //   confirmButtonColor: '#d33',
          //   confirmButtonText: 'OK',
          //   timer: 5000,
          // })
          // setActiveStep((prevActiveStep) => prevActiveStep + 1);
          const isValid = validateSSEInfo();
          if(isValid){
            console.log("SSEInfo in api call update",SSEInfo)
            console.log("SSEInforedux in api call update",sseInfoFromReduxOnMainPage)
            setShowProcedure(true);
            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({...sseInfoFromReduxOnMainPage, business_id: business_id})
              };
              return fetch( process.env.REACT_APP_UPDATE_CASE , requestOptions)
                .then((Response) => Response.json())
                .then( async(caseCreated) => {
                  console.log("updatecase sucess with form",caseCreated.success);
                   setShowProcedure(false);
                  if(caseCreated.success == true){
                    console.log("caseCreated response",caseCreated.data.case_info);
                    console.log("It's Success of case creation general details update existing")
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    // await addSseInfoRedux({...SSEInfo, case_id: caseCreated.data.case_info.case_id })
                    await addSseInfoRedux({...SSEInfo,
                      assesse_name: sseInfoFromReduxOnMainPage.assesse_name,
                      assesse_id: sseInfoFromReduxOnMainPage.assesse_id,
                      assesse_email: sseInfoFromReduxOnMainPage.assesse_email,
                      assesse_pan: sseInfoFromReduxOnMainPage.assesse_pan,
                      assesse_phone: sseInfoFromReduxOnMainPage.assesse_phone,
                      self_assessment: sseInfoFromReduxOnMainPage.self_assessment,
                      assessment_year: sseInfoFromReduxOnMainPage.assessment_year,
                      service_id: sseInfoFromReduxOnMainPage.service_id,
                      case_id: caseCreated.data.case_info.case_id,
                      device_token: JSON.parse(localStorage.getItem('device_token')),
                      session_id: JSON.parse(localStorage.getItem('session_id')),
                    })
                    await addnewCaseCreateCaseIdRedux(caseCreated.data.case_info.case_id)
                    // await localStorage.setItem('case_creation_details', JSON.stringify(caseCreated.data.case_info));
                    // await localStorage.setItem('created_case_case_id', JSON.stringify(caseCreated.data.case_info.case_id));
                    await localStorage.setItem('service_id_of_case_for_doc', JSON.stringify(caseCreated.data.case_info.service_id));
                    setsseNameErr("");
                    setsseEmailErr("");
                    setssePhoneNoErr("");
                    setssePanErr("");
                    setsseServiceErr("");
                    setasseAssessmentYearErr("");
                  }
                  if(caseCreated.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: caseCreated.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });
          }
        }
      }
    }

    else if(formState == 0 && activeStep == 0 ){
    // else if( !createCaseDetails.service_id ){
      Swal.fire({
        text: "Please Fill Generic form First",
        confirmButtonColor: 'primary',
        confirmButtonText: 'OK',
        timer: 5000,
      })
    }


    // This if is to call Save 1st level answers
    if ( formStateQLOne == "2" && activeStep == 1  ){
      console.log('ActiveStep is in 1',activeStep)
      console.log('formStateQLOne is in 1',formStateQLOne)
      setQsn_Answer_list([])
      // qsn_list.filter(item => item.is_default == true ).map((f) => {
      qsnListFromRedux.map((f) => {
        if(f.question_type === "TEXT-ANSWER"){
          if(f.answer){
            Qsn_Answer_list.push({
              "question_id": f.question_id,
              "boolean_ans": '',
              "text_answer": f.answer,
              "single_answer": '',
              "multi_answer": ''
            });
          }
        }
        else if(f.question_type === "BOOLEAN"){
          if(f.answer == true || f.answer == false || f.answer == "True" || f.answer == "False"){
            Qsn_Answer_list.push({
              "question_id": f.question_id,
              "boolean_ans": f.answer,
              "text_answer": '',
              "single_answer": '',
              "multi_answer": ''
            });
          }
        }
        // else if(f.question_type === "SINGLE-ANSWER"){
        //   for (var i = 0; i < f.options.length; i++) {
        //     if (f.options[i].selected === true) {
        //       // setoption_single_ans(f.options[i].option_id)
        //       let option_single_ans_here = f.options[i].option_id
        //       console.log("option_single_ans_here", option_single_ans_here)
        //       Qsn_Answer_list.push({
        //         "question_id": f.question_id,
        //         "boolean_ans": '',
        //         "text_answer": '',
        //         "single_answer": option_single_ans_here,
        //         "multi_answer": [],
        //       });
        //     }
        //   }
        else if(f.question_type === "SINGLE-ANSWER"){
          // if(f.answer !== "" && f.answer !== null ){
          if(f.single_answer !== "" && f.single_answer !== null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                // setoption_single_ans(f.options[i].option_id)
                let option_single_ans_here = f.options[i].option_id
                console.log("option_single_ans_here", option_single_ans_here)
                Qsn_Answer_list.push({
                  "question_id": f.question_id,
                  "boolean_ans": '',
                  "text_answer": '',
                  "single_answer": option_single_ans_here,
                  // "single_answer_text": f.answer,
                  "multi_answer": [],
                });
              }
            }
            //   Qsn_Answer_list.push({
            //     "question_id": f.question_id,
            //     "boolean_ans": '',
            //     "text_answer": '',
            //     "single_answer": f.answer,
            //     "multi_answer": ''
            //   });
            // }
          }
        }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          if( f.answer == "ok" || f.answer == null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,
                });
              }
              console.log("options_list",options_list)
            }
            if(options_list.length > 0){
              Qsn_Answer_list.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list,
              });
            }
          }
          else if(f.answer == "NotOk"){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,
                });
              }
              console.log("options_list",options_list)
            }
            if(options_list.length > 0){
              Qsn_Answer_list.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list,
              });
            }
          }
          setOptions_list([]);
        }
      })
      console.log("Qsn_Answer_list",Qsn_Answer_list)
      // let case_id = JSON.parse(localStorage.getItem('created_case_case_id'));
      console.log("case_id newCaseCreatedCaseIdRedux in que l1 form state",newCaseCreatedCaseIdRedux);

      let optionalAnsIsEmpty = false
      qsnListFromRedux.map((f) => {
        console.log("f in qsnListFromRedux map",f)
        if(f.question_type === "TEXT-ANSWER"){
          console.log("f in TEXT-ANSWER qsnListFromRedux map",f)
          if(f.is_optional == false && f.answer == null || f.answer == ''){
            console.log("f in TEXT-ANSWER condition satisfying",f)
            optionalAnsIsEmpty = true
          }
        }
        else if(f.question_type === "BOOLEAN"){
          console.log("f in BOOLEAN qsnListFromRedux map",f)
          if(f.is_optional == false && f.answer == null){
            console.log("f in BOOLEAN condition satisfying",f)
            optionalAnsIsEmpty = true
          }
        }
        else if(f.question_type === "SINGLE-ANSWER"){
          console.log("f in SINGLE-ANSWER qsnListFromRedux map",f)
          if(f.is_optional == false && f.single_answer == "" && f.single_answer == null ){
            optionalAnsIsEmpty = true
          }
        }
        // else if (f.question_type === "MULTIPLE-ANSWER") {
        //   console.log("f in MULTIPLE-ANSWER qsnListFromRedux map",f)
        // }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          console.log("f in MULTIPLE-ANSWER qsnListFromRedux map",f)
          if(f.is_optional == false ){
            let options_list = []
            console.log("f in MULTIPLE-ANSWER qsnListFromRedux condition",f)
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                console.log("f.options[i].selected === true",f.options[i].selected)
                options_list.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,
                });
              }
              console.log("options_list",options_list)
            }
            if( options_list.length == 0){
              console.log("options_list.length",options_list.length)
              optionalAnsIsEmpty = true
            }
          }
        }
      })
      console.log("optionalAnsIsEmpty check",optionalAnsIsEmpty)

      // if(Qsn_Answer_list.length === qsnListFromRedux.length){
      if( optionalAnsIsEmpty == false ){
        // if( Qsn_Answer_list.length == 0 && qsn_list.length == 0 )
        // {
        //     console.log("both lengths are zero,Qsn_Answer_list.length,qsn_list.length",
        //       Qsn_Answer_list.length,qsn_list.length)
        //     Swal.fire({
        //       text: "Please Answer All Questions",
        //       confirmButtonColor: 'primary',
        //       confirmButtonText: 'OK',
        //       timer: 5000,
        //     })
        // }
        // else{
          if( Qsn_Answer_list.length == 0){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            // Swal.fire({
            //   // icon: 'error',
            //   text: "Questions are not available for this set..Please navigate to next set",
            //   confirmButtonColor: 'primary',
            //   cancelButtonColor: '#D3C8C7',
            //   confirmButtonText: 'OK',
            //   showCancelButton: true,
            //   cancelButtonText: 'cancel',
            //   // timer: 5000,
            // }).then(function (result) {
            //     console.log("result of swal",result.isConfirmed)
            //     if(result.isConfirmed == true){
            //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
            //     }
            //     else if(result.isConfirmed == false){
            //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
            //     }
            // })
          }
          else{
            console.log("both lengths are equal, Qsn_Answer_list.length, qsn_list.length, qsnListFromRedux.length",
            Qsn_Answer_list.length,qsn_list.length,qsnListFromRedux.length)
            setShowProcedure(true);
            let SubmitAnsObject = ({
              case_id: newCaseCreatedCaseIdRedux,
              question_answers: Qsn_Answer_list,
              business_id: business_id,
              device_token: device_token,
              session_id: session_id,
            })
            console.log("SubmitAnsObject",SubmitAnsObject)

            console.log("SubmitAnsObject",SubmitAnsObject)
              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(SubmitAnsObject)
              };
              return fetch( process.env.REACT_APP_ADD_UPDATE_CASE_ANSWERS , requestOptions)
                .then((Response) => Response.json())
                .then( answerSavesQusLevelOne  => {
                  console.log("answerSavesQusLevelOne sucess with form",answerSavesQusLevelOne.success);
                  setShowProcedure(false);
                  if(answerSavesQusLevelOne.success == true){
                    console.log("It's Success of case creation answer Saves Qus Level One")
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                  if(answerSavesQusLevelOne.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: answerSavesQusLevelOne.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });
            }
        // }
      }
      else{
        console.log("both lengths are not equal,Qsn_Answer_list.length,qsn_list.length, qsnListFromRedux.length",
          Qsn_Answer_list.length,qsn_list.length,qsnListFromRedux.length)
        Swal.fire({
          text: "Please Answer All Mandatory Questions",
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          timer: 5000,
        })
      }

    }
    // else(){

    // }

    // This if is to call Save 2nd level answers
    if ( formStateQLTwo == "3" && activeStep == 2  ){
      console.log('ActiveStep is in 2',activeStep)
      console.log('formStateQLTwo is in 2',formStateQLTwo)
      setQsn_Answer_list_LTwo([])
      qsnListLevelTwoFromRedux.map((f) => {
        if(f.question_type === "TEXT-ANSWER"){
          if(f.answer){
            Qsn_Answer_list_LTwo.push({
              "question_id": f.question_id,
              "boolean_ans": '',
              "text_answer": f.answer,
              "single_answer": '',
              "multi_answer": ''
            });
          }
        }
        else if(f.question_type === "BOOLEAN"){
          if(f.answer == true || f.answer == false || f.answer == "True" || f.answer == "False"){
            Qsn_Answer_list_LTwo.push({
              "question_id": f.question_id,
              "boolean_ans": f.answer,
              "text_answer": '',
              "single_answer": '',
              "multi_answer": ''
            });
          }
        }
        // else if(f.question_type === "SINGLE-ANSWER"){
        //   for (var i = 0; i < f.options.length; i++) {
        //     if (f.options[i].selected === true) {
        //       // setoption_single_ans_LTwo(f.options[i].option_id)
        //       let option_single_ans_LTwo_here = f.options[i].option_id
        //       console.log("option_single_ans_LTwo_here", option_single_ans_LTwo_here)
        //       Qsn_Answer_list_LTwo.push({
        //         "question_id": f.question_id,
        //         "boolean_ans": '',
        //         "text_answer": '',
        //         "single_answer": option_single_ans_LTwo_here,
        //         "multi_answer": [],
        //       });
        //     }
        //   }
        // }
        else if(f.question_type === "SINGLE-ANSWER"){
          // if(f.answer !== "" && f.answer !== null ){
          if(f.single_answer !== "" && f.single_answer !== null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                // setoption_single_ans(f.options[i].option_id)
                let option_single_ans_LTwo_here = f.options[i].option_id
                console.log("option_single_ans_LTwo_here", option_single_ans_LTwo_here)
                Qsn_Answer_list_LTwo.push({
                  "question_id": f.question_id,
                  "boolean_ans": '',
                  "text_answer": '',
                  "single_answer": option_single_ans_LTwo_here,
                  // "single_answer_text": f.answer,
                  "multi_answer": [],
                });
              }
            }
          }
        }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          if( f.answer == "ok" || f.answer == null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list_LTwo.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,
                });
              }
              console.log("options_list_LTwo",options_list_LTwo)
            }
            if(options_list_LTwo.length > 0){
              Qsn_Answer_list_LTwo.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list_LTwo,
              });
            }
          }
          else if(f.answer == "NotOk"){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list_LTwo.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,
                });
              }
              console.log("options_list_LTwo",options_list_LTwo)
            }
            if(options_list_LTwo.length > 0){
              Qsn_Answer_list_LTwo.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list_LTwo,
              });
            }
          }
        }
        setOptions_list_LTwo([]);
      })
      console.log("Qsn_Answer_list_LTwo",Qsn_Answer_list_LTwo)
      // let case_id = JSON.parse(localStorage.getItem('created_case_case_id'));
      console.log("case_id newCaseCreatedCaseIdRedux in que l2 form state",newCaseCreatedCaseIdRedux);

      let optionalAnsIsEmptyLevel2 = false
      qsnListLevelTwoFromRedux.map((f) => {
        console.log("f in qsnListLevelTwoFromRedux map",f)
        if(f.question_type === "TEXT-ANSWER"){
          console.log("f in TEXT-ANSWER qsnListLevelTwoFromRedux map",f)
          if(f.is_optional == false && f.answer == null || f.answer == ''){

            optionalAnsIsEmptyLevel2 = true
          }
        }
        else if(f.question_type === "BOOLEAN"){
          console.log("f in BOOLEAN qsnListLevelTwoFromRedux map",f)
          if(f.is_optional == false && f.answer == null){
            console.log("f in BOOLEAN condition satisfying",f)
            optionalAnsIsEmptyLevel2 = true
          }
        }
        else if(f.question_type === "SINGLE-ANSWER"){
          console.log("f in SINGLE-ANSWER qsnListLevelTwoFromRedux map",f)
          if(f.is_optional == false && f.single_answer == "" && f.single_answer == null ){
            optionalAnsIsEmptyLevel2 = true
          }
        }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          console.log("f in MULTIPLE-ANSWER qsnListLevelTwoFromRedux map",f)
          if(f.is_optional == false ){
            let options_list_LTwo = []
            console.log("f in MULTIPLE-ANSWER qsnListLevelTwoFromRedux condition",f)
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                console.log("f.options[i].selected === true",f.options[i].selected)
                options_list_LTwo.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,
                });
              }
              console.log("options_list_LTwo",options_list_LTwo)
            }
            if( options_list_LTwo.length == 0){
              console.log("options_list_LTwo.length",options_list_LTwo.length)
              optionalAnsIsEmptyLevel2 = true
            }
            //
          }
        }
      })
      console.log("optionalAnsIsEmptyLevel2 2nd level check",optionalAnsIsEmptyLevel2)

      // if(Qsn_Answer_list_LTwo.length === qsnListLevelTwoFromRedux.length){
      if(optionalAnsIsEmptyLevel2 == false){
        if( Qsn_Answer_list_LTwo.length == 0){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          // Swal.fire({
          //   // icon: 'error',
          //   text: "Questions are not available for this set..Please navigate to next set",
          //   confirmButtonColor: 'primary',
          //   cancelButtonColor: '#D3C8C7',
          //   confirmButtonText: 'OK',
          //   showCancelButton: true,
          //   cancelButtonText: 'cancel',
          //   // timer: 5000,
          // }).then(function (result) {
          //     console.log("result of swal",result.isConfirmed)
          //     if(result.isConfirmed == true){
          //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
          //     }
          //     else if(result.isConfirmed == false){
          //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
          //     }
          // })
        }
        else{
          console.log("both lengths are equal, Qsn_Answer_list_LTwo.length, qsn_list_LTwo.length, qsnListLevelTwoFromRedux.length",
            Qsn_Answer_list_LTwo.length,qsn_list_LTwo.length, qsnListLevelTwoFromRedux.length)
          setShowProcedure(true);
          let SubmitAnsObjectLevelTwo = ({
            case_id: newCaseCreatedCaseIdRedux,
            question_answers: Qsn_Answer_list_LTwo,
            business_id: business_id,
            device_token: device_token,
            session_id: session_id,
          })

          console.log("SubmitAnsObjectLevelTwo",SubmitAnsObjectLevelTwo)
            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(SubmitAnsObjectLevelTwo)
            };
            return fetch( process.env.REACT_APP_ADD_UPDATE_CASE_ANSWERS , requestOptions)
              .then((Response) => Response.json())
              .then( answerSavesQusLevelTwo  => {
                console.log("answerSavesQusLevelTwo sucess with form",answerSavesQusLevelTwo.success);
                setShowProcedure(false);
                if(answerSavesQusLevelTwo.success == true){
                  console.log("It's Success of case creation answer Saves Qus Level Two")
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                if(answerSavesQusLevelTwo.success == false){
                  Swal.fire({
                    icon: 'error',
                    text: answerSavesQusLevelTwo.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
          }
      }
      else{
        console.log("both lengths are not equal, Qsn_Answer_list_LTwo.length, qsn_list_LTwo.length, qsnListLevelTwoFromRedux.length",
          Qsn_Answer_list_LTwo.length,qsn_list_LTwo.length, qsnListLevelTwoFromRedux.length)
        Swal.fire({
          text: "Please Answer All Mandatory Questions",
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          timer: 5000,
        })
      }


    }

    // This if is to call Save 3nd level answers
    if ( formStateQLThree == "4" && activeStep == 3  ){
      console.log('ActiveStep is in 4',activeStep)

      setQsn_Answer_list_LThree([])
      qsnListLevelThreeFromRedux.map((f) => {
        if(f.question_type === "TEXT-ANSWER"){
          if(f.answer){
            Qsn_Answer_list_LThree.push({
              "question_id": f.question_id,
              "boolean_ans": '',
              "text_answer": f.answer,
              "single_answer": '',
              "multi_answer": ''
            });
          }
        }
        else if(f.question_type === "BOOLEAN"){
          if(f.answer == true || f.answer == false || f.answer == "True" || f.answer == "False"){
            Qsn_Answer_list_LThree.push({
              "question_id": f.question_id,
              "boolean_ans": f.answer,
              "text_answer": '',
              "single_answer": '',
              "multi_answer": ''
            });
          }
        }
        // else if(f.question_type === "SINGLE-ANSWER"){
        //    for (var i = 0; i < f.options.length; i++) {
        //     if (f.options[i].selected === true) {
        //       // setoption_single_ans_LThree(f.options[i].option_id)
        //       let option_single_ans_LThree_here = f.options[i].option_id
        //       console.log("option_single_ans_LThree_here", option_single_ans_LThree_here)
        //       Qsn_Answer_list_LThree.push({
        //         "question_id": f.question_id,
        //         "boolean_ans": '',
        //         "text_answer": '',
        //         "single_answer": option_single_ans_LThree_here,
        //         "multi_answer": [],
        //       });
        //     }
        //   }
        // }
        else if(f.question_type === "SINGLE-ANSWER"){
          // if(f.answer !== "" && f.answer !== null ){
          if(f.single_answer !== "" && f.single_answer !== null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                // setoption_single_ans(f.options[i].option_id)
                let option_single_ans_LThree_here = f.options[i].option_id
                console.log("option_single_ans_LThree_here", option_single_ans_LThree_here)
                Qsn_Answer_list_LThree.push({
                  "question_id": f.question_id,
                  "boolean_ans": '',
                  "text_answer": '',
                  "single_answer": option_single_ans_LThree_here,
                  // "single_answer_text": f.answer,
                  "multi_answer": [],
                });
              }
            }
          }
        }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          if( f.answer == "ok" || f.answer == null ){
            setoptions_list_LThree([]);
            console.log("f in MULTIPLE-ANSWER",f)
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                console.log("f.options[i].selected === true",f.options[i].selected)
                options_list_LThree.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,
                });
              }
              // if (f.options[i].selected === false){
              //   console.log("f.options[i].selected === false",f.options[i].selected)
              //   options_list_LThree.map((op) => {
              //     if(op.option_id == f.options[i].option_id)
              //     options_list_LThree.splice(op, 1);
              //   })
              // }
              console.log("options_list_LThree",options_list_LThree)
            }
            if(options_list_LThree.length > 0){
              Qsn_Answer_list_LThree.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list_LThree,
              });
            }
          }
          else if(f.answer == "NotOk"){
            setoptions_list_LThree([]);
            console.log("f in MULTIPLE-ANSWER",f)
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                console.log("f.options[i].selected === true",f.options[i].selected)
                options_list_LThree.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,
                });
              }
              // if (f.options[i].selected === false){
              //   console.log("f.options[i].selected === false",f.options[i].selected)
              //   options_list_LThree.map((op) => {
              //     if(op.option_id == f.options[i].option_id)
              //     options_list_LThree.splice(op, 1);
              //   })
              // }
              console.log("options_list_LThree",options_list_LThree)
            }
            if(options_list_LThree.length > 0){
              Qsn_Answer_list_LThree.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list_LThree,
              });
            }
          }
        }

      })
      console.log("Qsn_Answer_list_LThree",Qsn_Answer_list_LThree)
      // let case_id = JSON.parse(localStorage.getItem('created_case_case_id'));
      console.log("case_id newCaseCreatedCaseIdRedux in que l3 form state",newCaseCreatedCaseIdRedux);

      let optionalAnsIsEmptyLevel3 = false
      qsnListLevelThreeFromRedux.map((f) => {
        console.log("f in qsnListLevelThreeFromRedux map",f)
        if(f.question_type === "TEXT-ANSWER"){
          console.log("f in TEXT-ANSWER qsnListLevelThreeFromRedux map",f)
          if(f.is_optional == false && f.answer == null || f.answer == ''){
            optionalAnsIsEmptyLevel3 = true
          }
        }
        else if(f.question_type === "BOOLEAN"){
          console.log("f in BOOLEAN qsnListLevelThreeFromRedux map",f)
          if(f.is_optional == false && f.answer == null){
            console.log("f in BOOLEAN condition satisfying",f)
            optionalAnsIsEmptyLevel3 = true
          }
        }
        else if(f.question_type === "SINGLE-ANSWER"){
          console.log("f in SINGLE-ANSWER qsnListLevelThreeFromRedux map",f)
          if(f.is_optional == false && f.single_answer == "" && f.single_answer == null ){
            optionalAnsIsEmptyLevel3 = true
          }
        }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          console.log("f in MULTIPLE-ANSWER qsnListLevelThreeFromRedux map",f)
          if(f.is_optional == false ){
            let options_list_LTwo = []
            console.log("f in MULTIPLE-ANSWER qsnListLevelThreeFromRedux condition",f)
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                console.log("f.options[i].selected === true",f.options[i].selected)
                options_list_LTwo.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,
                });
              }
              console.log("options_list_LTwo",options_list_LTwo)
            }
            if( options_list_LTwo.length == 0){
              console.log("options_list_LTwo.length",options_list_LTwo.length)
              optionalAnsIsEmptyLevel3 = true
            }
            //
          }
        }
      })
      console.log("optionalAnsIsEmptyLevel3 3rd level check",optionalAnsIsEmptyLevel3)

      // if(Qsn_Answer_list_LThree.length === qsnListLevelThreeFromRedux.length){
      if( optionalAnsIsEmptyLevel3 == false ){
        if( Qsn_Answer_list_LThree.length == 0){
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          // Swal.fire({
          //   // icon: 'error',
          //   text: "Questions are not available for this set..Please navigate to next set",
          //   confirmButtonColor: 'primary',
          //   cancelButtonColor: '#D3C8C7',
          //   confirmButtonText: 'OK',
          //   showCancelButton: true,
          //   cancelButtonText: 'cancel',
          //   // timer: 5000,
          // }).then(function (result) {
          //     console.log("result of swal",result.isConfirmed)
          //     if(result.isConfirmed == true){
          //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
          //     }
          //     else if(result.isConfirmed == false){
          //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
          //     }
          // })
        }
        else{
          console.log("both lengths are equal, Qsn_Answer_list_LThree.length, qsn_list_LThree.length, qsnListLevelThreeFromRedux.length",
            Qsn_Answer_list_LThree.length,qsn_list_LThree.length, qsnListLevelThreeFromRedux.length)
          setShowProcedure(true);
            let SubmitAnsObjectLevelThree = ({
              case_id: newCaseCreatedCaseIdRedux,
              question_answers: Qsn_Answer_list_LThree,
              business_id: business_id,
              device_token: device_token,
              session_id: session_id,
            })

            console.log("SubmitAnsObjectLevelThree",SubmitAnsObjectLevelThree)
                const requestOptions = {
                  method: 'POST',
                  headers: authHeader(),
                  body: JSON.stringify(SubmitAnsObjectLevelThree)
                };
                return fetch( process.env.REACT_APP_ADD_UPDATE_CASE_ANSWERS , requestOptions)
                  .then((Response) => Response.json())
                  .then( answerSavesQusLevelThree  => {
                    console.log("answerSavesQusLevelThree sucess with form",answerSavesQusLevelThree.success);
                    setShowProcedure(false);
                    if(answerSavesQusLevelThree.success == true){
                      console.log("It's Success of case creation answer Saves Qus Level Two")
                      setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                    if(answerSavesQusLevelThree.success == false){
                      Swal.fire({
                        icon: 'error',
                        text: answerSavesQusLevelThree.errors,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK',
                        timer: 5000,
                      })
                    }
                  })
                  .catch(err => {
                    setShowProcedure(false);
                    Swal.fire({
                      icon: 'error',
                      // text: "Something went wrong",
                      text: "Server Error. Please try again.",
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                  });
              }

      }
      else{
        console.log("both lengths are not equal, Qsn_Answer_list_LThree.length, qsn_list_LThree.length, qsnListLevelThreeFromRedux.length",
          Qsn_Answer_list_LThree.length,qsn_list_LThree.length, qsnListLevelThreeFromRedux.length)
        Swal.fire({
          text: "Please Answer All Mandatory Questions",
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          timer: 5000,
        })
      }
    }

    // This if is to call Save upload files case final state
    if ( formStateUploadFile == "5" && activeStep == 4 ){
      console.log("In submit function")
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // newCaseCreatedCaseIdRedux
        body: JSON.stringify({'case_id':newCaseCreatedCaseIdRedux ,'stage':"BUSINESS", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_CASE_DOCUMENTS , requestOptions)
        .then((Response) => Response.json())
        .then( async(caseDocuments) => {
          setShowProcedure(false);
          // setShowProcedure(false);
          console.log("caseDocuments for last level",caseDocuments);
          if(caseDocuments.success == true)
          {
            let forvalidation = caseDocuments.data.case_documents

            let validationCheckFlag = false
            forvalidation.map((f) => {
              if(f.is_optional == false){
                if ( !f.user_uploaded.document ){
                  validationCheckFlag = true
                }
              }
            })
            console.log("validationCheckFlag",validationCheckFlag)

            // let arrayWithFlag = (
            //     forvalidation.map((f) => {
            //       if( f.user_uploaded.document ) {
            //         f.fileUplodedFlag = true
            //       }
            //       return f;
            //     })
            //   )
            // console.log("arrayWithFlag is",arrayWithFlag)
            // let getCountHere = [];
            // let TogetCount = (
            //   arrayWithFlag.map((f) => {
            //     if( f.fileUplodedFlag == true ) {
            //       getCountHere.push(f)
            //     }
            //   })
            // )
            // console.log("getCountHere",getCountHere)

            // if(getCountHere.length == forvalidation.length){
            if( validationCheckFlag == false){
              console.log("Files are uploaded")
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            // else if(getCountHere.length !== forvalidation .length){
            else if( validationCheckFlag == true ){
              console.log("Files are not uploaded")
              Swal.fire({
                // icon: 'error',
                text: "Please Upload All Mandatory Files",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }

            // forvalidation.map((f)=>{
            //   console.log("forvalidation",f.user_uploaded.document)
            //   if( f.user_uploaded.document  == undefined ) {
            //     console.log("Files are not uploaded")
            //     // Swal.fire({
            //     //     icon: 'error',
            //     //     text: "Please Upload All Files",
            //     //     confirmButtonColor: '#d33',
            //     //     confirmButtonText: 'OK',
            //     //     timer: 5000,
            //     //   })
            //   }
            //   else if( f.user_uploaded.document ){
            //     console.log("Files are uploaded")
            //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
            //   }
            // })
          }
        else{
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }

    // This if is to call Save upload files case final state personal
    if ( formStateUploadFilePersonal == "6" && activeStep == 5 ){
      console.log("In submit function personal")
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // newCaseCreatedCaseIdRedux
        body: JSON.stringify({'case_id':newCaseCreatedCaseIdRedux ,'stage':"PERSONAL", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_CASE_DOCUMENTS , requestOptions)
        .then((Response) => Response.json())
        .then( async(caseDocuments) => {
          setShowProcedure(false);
          // setShowProcedure(false);
          console.log("caseDocuments for last level",caseDocuments);
          if(caseDocuments.success == true)
          {
            let forvalidation = caseDocuments.data.case_documents

            let validationCheckFlag = false
            forvalidation.map((f) => {
              if(f.is_optional == false){
                if ( !f.user_uploaded.document ){
                  validationCheckFlag = true
                }
              }
            })
            console.log("validationCheckFlag",validationCheckFlag)

            // let arrayWithFlag = (
            //     forvalidation.map((f) => {
            //       if( f.user_uploaded.document ) {
            //         f.fileUplodedFlag = true
            //       }
            //       return f;
            //     })
            //   )
            // console.log("arrayWithFlag is",arrayWithFlag)
            // let getCountHere = [];
            // let TogetCount = (
            //   arrayWithFlag.map((f) => {
            //     if( f.fileUplodedFlag == true ) {
            //       getCountHere.push(f)
            //     }
            //   })
            // )
            // console.log("getCountHere",getCountHere)

            // if(getCountHere.length == forvalidation.length){
            if( validationCheckFlag == false ){
              console.log("Files are uploaded")
              // setActiveStep((prevActiveStep) => prevActiveStep + 1);
              setShowProcedure(true);
              let SubmitAnsObjectUploadFiles = ({
                case_id: newCaseCreatedCaseIdRedux,
                case_status: "submit-case",
                business_id: business_id ,
                device_token: device_token,
                session_id: session_id,
              })
              console.log("SubmitAnsObjectUploadFiles",SubmitAnsObjectUploadFiles)
                const requestOptions = {
                  method: 'POST',
                  headers: authHeader(),
                  body: JSON.stringify(SubmitAnsObjectUploadFiles)
                  // body: SubmitAnsObjectUploadFiles
                };
                return fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
                  .then((Response) => Response.json())
                  .then( caseSubmited  => {
                  console.log("caseSubmited sucess",caseSubmited.success);
                  setShowProcedure(false);
                  if(caseSubmited.success == true){
                    console.log("It's Success of case submit")
                    Swal.fire({
                      icon: 'success',
                      text: "Case is created Succesfully.",
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setRedirectToCaseList(true);
                    // {<Link to="/cases"/>}
                    // history.push("/cases")
                    newCaseActionResetCallRedux();
                    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                  if(caseSubmited.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: caseSubmited.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }
                })
                .catch(error => {
                  setShowProcedure(false);
                                console.log(error);
                                Swal.fire({
                                  icon: 'error',
                                  text: "Something Went Wrong!",
                                  confirmButtonColor: '#d33',
                                  confirmButtonText: 'OK',
                                  timer: 5000,
                                })
                                });
                    }
            // else if(getCountHere.length !== forvalidation .length){
            else if( validationCheckFlag == true ){
              console.log("Files are not uploaded")
              Swal.fire({
                // icon: 'error',
                text: "Please Upload All Mandatory Files",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
          }
        else{
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })

    }

  };

  const handleBack = async() => {
    await setNextOrBackIs("back")
    await setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //   if( newCaseCreatedCaseIdRedux!== undefined){
    //     addSseInfoRedux({...SSEInfo,
    //     case_id: newCaseCreatedCaseIdRedux,
    //     service_id: lastCategoryFromRedux,
    //    })
    //  }
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  if(RedirectToCaseList == true){
    // return <Redirect to='/cases'/>;
    return(
        <Redirect to="/cases" />
        // <Link to="/cases" />
        // history.push("/cases")
    )
  }
  else{
  return (
    // console.log("case_creation_details,editItemIs",case_creation_details,editItemIs),
    // console.log("editItemIs",editItemIs),
    <div className={classes.root}>
      {ShowProcedure ? <Modal className={classes.showProcedureStyle} isOpen={true}>
            <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
          </Modal>: null}
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div className={classes.wrapper}>
          <Grid container>
            <Grid item xs={1} className={classes.pageHeaderLabel} >
              <Button href="/cases" className={classes.backButton}>
                  <span className={classes.backArrow}>
                  <ArrowBackIcon fontSize="small" />
                </span>
                Back
              </Button>
            </Grid>
          </Grid>
          <Grid container className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
            <Grid item xs={12} className={classes.pageHeaderLabel}>
              {/* <FormattedMessage id="newCasesLabel" /> */}
              New Case
            </Grid>
          </Grid>
          <Grid container className={classes.linkBackToCase}>
            <Grid item xs={12}>
              <Link to="/cases" className={classes.linkNoUnderline}>
                {/* <FormattedMessage id="navigateToCase" /> */}
                View All Cases
              </Link>
            </Grid>
          </Grid>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {intl.formatMessage({ id: label })}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  <FormattedMessage id="stepCompleteMessage" />
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                  className={classes.button}
                >
                  <FormattedMessage id="resetLabel" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  // href="/cases"
                >
                  <FormattedMessage id="submitLabel" />
                </Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    {/* <FormattedMessage id="backLabel" /> */}
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {intl.formatMessage({
                      id:
                        activeStep === steps.length - 1
                          ? "finishLabel"
                          : "nextlabel",
                    })}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Slide>
    </div>
  );
  }
}

export default injectIntl(TaxManagementClientCases);
