import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { injectIntl, FormattedMessage } from "react-intl";
// import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect, Link} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from "../actions";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {  blue  } from '@mui/material/colors';


// import TaxManagementSignUp from "../TaxManagementSignUp/TaxManagementSignUp"
import TaxManagementForgotPassword from "../TaxManagementForgotPassword/TaxManagementForgotPassword"

const theme = createTheme({
  palette: {
    primary: {
      main: '#001eaa',
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(../Image/loginBanner.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarLocked: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarSignIn: {
    margin: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(7),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftAlign: {
    textAlign: "left",
  },
  leftAlignBackToHome: {
    textAlign: "left",
    marginTop: '1%',
    marginLeft: '1%'
  },
  errorBlock: {
    marginLeft: "5%",
    marginRight: "5%",
    paddingTop: "2%",
    textAlign: "left",
  },
  overFlowHidden: {
    overflow: "hidden",
  },
  dontHaveAccText: {
    cursor: "pointer",
    color: "#3f51b5",
  },
  
  
}));



function LoginPageTemplate({
  intl,
  username,
  password,
  submitted,
  handleSubmit,
  handleChange,
  alertObject,
  loggingIn,
  submittedForUsername,
  userActiveStatusIsChecked,
  handleCheckIfUserIsActive
}) {
  const classes = useStyles();
  const [redirect, setredirect] = React.useState(false);
  const [redirectForgotPass, setredirectForgotPass] = React.useState(false);
  const [redirectHomePage, setredirectHomePage] = React.useState(false);

  // const [userActiveStatusIsChecked, setuserActiveStatusIsChecked] = React.useState(false);

  const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);

  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] = React.useState(JSON.parse(localStorage.getItem('session_id')));

  const [showPassword, setShowPassword] = React.useState(false);

  const dispatch = useDispatch()

  const onClickSignUp = () => {
    
    return(
      console.log("in sign up func"),
      setredirect(true)
      // history.push('/TaxManagementSignUp');
      // <Route path="/login" 
      //               render={(props) => (
      //                 <TaxManagementSignUp />
      //               )}
      //               />
    )
  } 

  React.useEffect(() => {
    // console.log("loggingIn in login",loggingIn)
    console.log("deviceTokenFromRedux in useeffect login",deviceTokenFromRedux)
    console.log("device_token in useeffect login",device_token)
    console.log("session_id in useeffect login",session_id)
    
    let user = JSON.parse(localStorage.getItem('user'));
    console.log("user in useeffect login",user)


    if( device_token !== null && session_id !== null && user !== null){
      console.log("device token , session id, user info found in useeffect of login")
      // Swal.fire({
      //   icon: 'error',
      //   text: "You are already logged in in app",
      //   confirmButtonColor: '#d33',
      //   confirmButtonText: 'OK'
      // })
      dispatch(userActions.logout());
    }
    else{
      console.log("device token , session id, user info is empty in useeffect of login")
    }

  }, []);

  const onClickForgotPassword = () => {
    return(
      console.log("in onClickForgotPassword"),
      setredirectForgotPass(true)
    ) 
  }

  const BackToHomeScreenFunc = () => {
    console.log("Back ToHomeScreenFunc")
    setredirectHomePage(true);
    // history.push('/');
  }

  
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  

  // const handleCheckIfUserIsActive = () => {
  //   console.log("To check if user is active")

  //   const requestOptions = {
  //     method: 'POST',
  //     headers: authHeader(),
  //     body: JSON.stringify({
  //       'username':
  //     })
  //   };
  //   fetch(process.env.REACT_APP_GET_ACCOUNT_STATUS, requestOptions)
  // }

  // if( redirectHomePage === true ){
  //   return (
  //     console.log("in sign up link"),
  //     <Redirect to={'/HomePage'}/>
  //   )
  // }
  if(redirect == true){
    return (
      console.log("in sign up link"),
      <Redirect to={'/TaxManagementSignUp'}/>
     
      //  3. its working but not showing back button
      // <Link to="/" component={TaxManagementSignUp} />

      // 2.
      // <Link
      //   to={{
      //     pathname: "/TaxManagementSignUp",
      //   }}
      // />

      // 1
      // <Link to="/TaxManagementSignUp" />
    )
    
  }
  else if (redirectForgotPass == true){
    return (
      console.log("in onClickForgotPassword"),
      <Redirect to={'/TaxManagementForgotPassword'}/>
    )
  }
  else{
  return (
    console.log("loggingIn in login",loggingIn),
    // console.table()
    <Grid container component="main" className={classes.root}>
      
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        className={classes.overFlowHidden}
      >

        <Grid item xs className={classes.leftAlignBackToHome}  >
          <Link to='/HomePage' variant="body2" style={{color:'#551A88'}}>
            <FormattedMessage id="backToHomePageLabel" />
           </Link>
            {/* <Button
              size="big"
              variant="text"
              color="primary"
              className={classes.LoginButton}
              onClick={() => BackToHomeScreenFunc()}
            >
              Back To Home Screen
            </Button> */}
        </Grid>

        <div className={classes.paper}>
          
          {!loggingIn && (
            <Avatar className={classes.avatarLocked}>
              <LockOutlinedIcon />
            </Avatar>
          )}
          {loggingIn && (
            <Avatar className={classes.avatarSignIn}>
              <CircularProgress size={20} />
            </Avatar>
          )}
          <Typography component="h1" variant="h5">
            <FormattedMessage id="signInLabel" />
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>

            {
              ( userActiveStatusIsChecked === false )?
              (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    // label={intl.formatMessage({ id: "userNameLabel" })}
                    label="Email ID"
                    // inputProps={{ style: { textTransform: "lowercase" } }}
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={username}
                    onChange={handleChange}
                  />
                </>
              ):
              (<></>)
            }

            {
              ( userActiveStatusIsChecked === true )?
              (
                <>
                  {/* <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={intl.formatMessage({ id: "passwordLabel" })}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    autoFocus
                    onChange={handleChange}
                  /> */}
                  <ThemeProvider theme={theme} >
                  <FormControl sx={{ width: '100%' }} variant="outlined" style={{marginTop:'4.5%'}}>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      // id="outlined-adornment-password"
                      // color={'#ffffff'}
                      color="primary"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      name="password"
                      id="password"
                      label="Password"
                      value={password}
                      autoFocus
                      onChange={handleChange}
                    />
                  </FormControl>
                  </ThemeProvider>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    <FormattedMessage id="signInLabel" />
                  </Button>
                </>
              ):
              (
                <>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleCheckIfUserIsActive}
                  >
                    Next
                  </Button>
                </>
              )
            }
            
            <Grid container>
              {/* <Grid item xs className={classes.leftAlign} onClick={onClickForgotPassword}  > */}
              <Grid item xs className={classes.leftAlign}  >
                <Link to="TaxManagementForgotPassword" variant="body2" style={{color:'#551A88'}}>
                  <FormattedMessage id="forgotPsswordLabel" />
                </Link>
              </Grid>
              {/* <Grid item onClick={onClickSignUp} className={classes.dontHaveAccText}> */}
              {/* <Grid item > */}
                {/* <Link href="/TaxManagementSignUp" variant="body2" > */}
                  {/* <FormattedMessage  id="doNotAccountLabel" /> */}
                {/* </Link> */}
                <Link to="TaxManagementSignUp" variant="body2" style={{color:'#551A88'}}>
                  <FormattedMessage  id="doNotAccountLabel" />
                </Link> 
              {/* </Grid> */}
                {/* <text style={{color:'#551A88', cursor:'pointer'}} onClick={handleOpenTermsAndCondition}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terms and Conditions
                </text>  */}
            </Grid>
          </form>
        </div>
        <Grid item xs={12}>
          {console.log("alertObject is",alertObject)}
          <div>
            {alertObject.message && (
              <div className={classes.errorBlock}>
                {alertObject.message.message && (
                  <Alert severity="error">{alertObject.message.message}</Alert>
                )}
                {!alertObject.message.message && (
                  <Alert severity="error">
                    {/* <FormattedMessage id="somethingWrong" /> */}
                    Wrong Username or Password
                  </Alert>
                )}
              </div>
            )}
          </div>
          <div>
            {/* {submitted && submittedForUsername  && !username && (
              <div className={classes.errorBlock}>
                <Alert variant="filled" severity="error">
                  {submitted && !username && (
                    <div>
                      <FormattedMessage id="userNameWrong" />
                      Email ID is required
                    </div>
                  )}
                </Alert>
              </div>
            )} */}

            {
              ( submitted && !username || submittedForUsername  && !username )?
              (
                <div className={classes.errorBlock}>
                <Alert variant="filled" severity="error">
                  {/* {submitted && !username && ( */}
                    <div>
                      {/* <FormattedMessage id="userNameWrong" /> */}
                      Email ID is required
                    </div>
                  {/* )} */}
                </Alert>
              </div>
              ):
              (<></>)
            }
            
            {/* {submitted && !password && ( */}
            {submitted && userActiveStatusIsChecked && !password && (
              <div className={classes.errorBlock}>
                <Alert variant="filled" severity="error">
                  {submitted && !password && (
                    <div>
                      <FormattedMessage id="passwordWrong" />
                    </div>
                  )}
                </Alert>
              </div>
            )}

            {/* {
              ( submitted && !password || submittedForUsername  && !password )?
              (
                <div className={classes.errorBlock}>
                  <Alert variant="filled" severity="error">
                    {submitted && !password && (
                      <div>
                        <FormattedMessage id="passwordWrong" />
                      </div>
                    )}
                  </Alert>
                </div>
              ):
              (<></>)
            } */}

            {/* {
              submittedForUsername && !username && (
                <div className={classes.errorBlock}>
                  <Alert variant="filled" severity="error">
                    {submittedForUsername && !username && (
                      <div>
                        Email ID is required
                      </div>
                    )}
                  </Alert>
                </div>
              )
            } */}

              
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
}

export default injectIntl(LoginPageTemplate);
