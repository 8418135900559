import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import { injectIntl, FormattedMessage } from "react-intl";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import EditCaseGeneralDetails from "./EditCaseGeneralDetails";
import EditCaseQuestionnaire1ClientCases from "./EditCaseQuestionnaire1ClientCases";
import EditCaseQuestionnaire2ClientCases from "./EditCaseQuestionnaire2ClientCases";
import EditCaseQuestionnaire3ClientCases from "./EditCaseQuestionnaire3ClientCases";
import EditCaseUploadFileClientCases from "./EditCaseUploadFileClientCases";
import EditCaseUploadFileClientCasesPersonal from "./EditCaseUploadFileClientCasesPersonal";
import { Link, Redirect } from "react-router-dom";
import Swal from 'sweetalert2';
import { authHeader } from '../../helpers';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import { PropagateLoader } from 'react-spinners';
import useCheckSessionApi from "../../SessionValidity";
import { userActions } from "../../actions";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { addsseInfo , addcreateCaseDetails, addnewCaseCreateCaseId, updateCaseActionReset, addqsnList, addqsnListLevelTwo, 
  addqsnListLevelThree,
 } from "../../actions/updatecase.actions";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    margin: "10%",
    marginRight: "5%",
    marginTop: "7%",
    marginBottom: "3%",
  },
  pageHeaderLabel: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  pageHeaderContainer: {
    // marginBottom: "5%",
  },
  CaseRejectedLable: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "20px",
    color: "red",
    textAlign: "left",
  },
  CaseRejectedComment: {
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.64)",
    textAlign: "left",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
  linkBackToCase: {
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: "600",
    textTransform: "none",
    marginTop: "-2%",
    marginBottom: "2%",
    textAlign: "right",
  },
  linkNoUnderline: {
    textDecoration: "none",
  },
  showProcedureStyle: {
    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
  },
  backButton: {
    padding: 0,
    lineHeight: 0,
    textTransform: "none",
    color: "rgba(0, 0, 0, 0.64)",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: [
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  backArrow: {
    marginRight: "10%",
  },
}));

function getSteps() {
  return [
    "generalDetails",
    "questionnaire1",
    "questionnaire2",
    "questionnaire3",
    "fileUploadBusiness",
    "fileUploadPersonal",
  ];
}

function EditExistingCase({ intl, props }) {
  const classes = useStyles();
  const [ ShowProcedure, setShowProcedure ] = React.useState(false);
  const [ editItemIs, seteditItemIs ] = React.useState(JSON.parse(localStorage.getItem('selected_case_for_edit')));
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [business_id, setbusiness_id ] = React.useState(JSON.parse(localStorage.getItem('logedIn_users_businessId')));
  const [device_token, setdevice_token] = React.useState(JSON.parse(localStorage.getItem('device_token')));
  const [session_id, setsession_id] =  React.useState(JSON.parse(localStorage.getItem('session_id')));

  const [case_creation_details, setcase_creation_details] = React.useState(JSON.parse(localStorage.getItem('case_creation_details')));
  const [created_case_case_id, setcreated_case_case_id] = React.useState(JSON.parse(localStorage.getItem('created_case_case_id')));

  const [formState, setformState] = React.useState(0);

  const [CreateCaseDetails, setCreateCaseDetails] = React.useState({});

  const [ NextOrBackIs, setNextOrBackIs ] = React.useState("next");

  // variables from general state
  const dispatch = useDispatch();

  const selfAssesFromRedux = useSelector(state => state.newCase.selfAssesment);
  const newCaseCreatedCaseIdRedux = useSelector(state => state.updateCase.newCaseCreatedCaseId);
  const sseInfoFromReduxOnMainPage = useSelector(state => state.updateCase.sseInfo);
  const createCaseDetailsRedux = useSelector(state => state.updateCase.createCaseDetails);

  const addnewCaseCreateCaseIdRedux = (caseId) => dispatch(addnewCaseCreateCaseId(caseId));
  const addSseInfoRedux = (sseInfo) => dispatch(addsseInfo(sseInfo));
  const addCreateCaseDetailsRedux = (CreateCaseDetails) => dispatch(addcreateCaseDetails(CreateCaseDetails));

  const addqsnListRedux = (qsnList) => dispatch(addqsnList(qsnList));
  const addqsnListLevelTwoRedux = (qsnListLevelTwo) => dispatch(addqsnListLevelTwo(qsnListLevelTwo));
  const addqsnListLevelThreeRedux = (qsnListLevelThree) => dispatch(addqsnListLevelThree(qsnListLevelThree));
  
  const updateCaseActionResetRedux = () => dispatch(updateCaseActionReset());

  const [selectedServiceId, setselectedServiceId] = React.useState(0);
  const [SelfAssesment, setSelfAssesment] = React.useState();
  const [SSEInfo, setSSEInfo] = React.useState({});
  const [CountryCodeHere, setCountryCodeHere] = React.useState("");
  const [AssesmentYearHere, setAssesmentYearHere] = React.useState("");
  const [CaseNameHere, setCaseNameHere] = React.useState("");
  const [CaseDescriptionHere, setCaseDescriptionHereHere] = React.useState("");
  const [ sseNameErr, setsseNameErr ] = React.useState("");
  const [ sseEmailErr, setsseEmailErr ] = React.useState("");
  const [ sseEmailIncorrectErr, setsseEmailIncorrectErr ] = React.useState("");
  const [ ssePhoneNoErr , setssePhoneNoErr ] = React.useState("");
  const [ ssePhoneNoNotDigitErr , setssePhoneNoNotDigitErr ] = React.useState("");
  const [ ssePhoneNoIncorrectErr , setssePhoneNoIncorrectErr ] = React.useState("");
  const [ ssePanErr , setssePanErr ] = React.useState("");
  const [ ssePanNotValidErr , setssePanNotValidErr ] = React.useState("");
  const [ sseServiceErr , setsseServiceErr ] = React.useState("");
  const [ sseAssesYearErr , setsseAssesYearErr ] = React.useState("");
  const [ sseAssesCaseNameErr , setsseAssesCaseNameErr ] = React.useState("");
  const [ sseAssesCaseDescriptionErr , setsseAssesCaseDescriptionErr ] = React.useState("");

  const [serviceIdErr , setserviceIdErr ] = React.useState("");
  const [assesmentYearErr, setassesmentYearErr] = React.useState("");
  const [caseNameErr, setcaseNameErr] = React.useState("");
  const [caseDescriptionErr, setcaseDescriptionErr] = React.useState("");

  // case details from api
  const [CaseOverallDetails, setCaseOverallDetails] = React.useState();

  // variables from Question Level One
  const [formStateQLOne, setformStateQLOne] = React.useState(0);
  const [SubmitAnsObject, setSubmitAnsObject] = React.useState({});

  const [qsn_list, setqsn_list] = React.useState([]);
  const [Qsn_Answer_list, setQsn_Answer_list] = React.useState([]);
  const [options_list, setOptions_list] = React.useState([]);
  const [option_single_ans, setoption_single_ans ] = React.useState("");
  const qsnListFromRedux = useSelector(state => state.updateCase.qsnList);

  // variables from Question Level Two
  const [formStateQLTwo, setformStateQLTwo] = React.useState(0);

  const [qsn_list_LTwo, setqsn_list_LTwo] = React.useState([]);
  const [Qsn_Answer_list_LTwo, setQsn_Answer_list_LTwo] = React.useState([]);
  const [options_list_LTwo, setOptions_list_LTwo] = React.useState([]);
  const [option_single_ans_LTwo, setoption_single_ans_LTwo] = React.useState("");
  const qsnListLevelTwoFromRedux = useSelector(state => state.updateCase.qsnListLevelTwo);

  // variables from Question Level Three
  const [formStateQLThree, setformStateQLThree] = React.useState(0);

  const[qsn_list_LThree, setqsn_list_LThree] = React.useState([]);
  const [Qsn_Answer_list_LThree, setQsn_Answer_list_LThree] = React.useState([]);
  const [options_list_LThree, setoptions_list_LThree] = React.useState([]);
  const [option_single_ans_LThree, setoption_single_ans_LThree] = React.useState("");
  const qsnListLevelThreeFromRedux = useSelector(state => state.updateCase.qsnListLevelThree);

  // variables from Upload Files
  const [formStateUploadFile, setformStateUploadFile] = React.useState(0);

  // variables from file upload personal
  const [formStateUploadFilePersonal, setformStateUploadFilePersonal] = React.useState(0);
  const [RedirectToCaseList, setRedirectToCaseList] = React.useState(false);

  const [flag , setflag] = React.useState(false)
  const [DeviceToken, setDeviceToken] =   React.useState();
  const [SessionId, setSessionId] =   React.useState();
  const deviceTokenFromRedux = useSelector(state => state.loginDetails.deviceToken);
  const sessionIdFromRedux = useSelector(state => state.loginDetails.sessionId);
  let user = JSON.parse(localStorage.getItem('user'));
  // const [data, checkSessionValidity] = useCheckSessionApi({
  //   url: process.env.REACT_APP_CHECK_SESSION_VALIDITY ,
  //   options: {
  //     method: "POST",
  //     headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Token " + user.key,
  //     },
  //     body: JSON.stringify({
  //       device_token: deviceTokenFromRedux,
  //       session_id: sessionIdFromRedux,
  //     }),
  //   },
  //   props,
  // });

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        // return <NewCaseGeneralDetailsClientCases onSelectService={handleNext}/>
        return <EditCaseGeneralDetails onSelectService={handleChangeFormState}
          sseNameErr={sseNameErr} sseEmailErr={sseEmailErr} sseEmailIncorrectErr={sseEmailIncorrectErr} ssePhoneNoErr={ssePhoneNoErr} 
          ssePhoneNoNotDigitErr={ssePhoneNoNotDigitErr} ssePhoneNoIncorrectErr={ssePhoneNoIncorrectErr} 
          ssePanNotValidErr={ssePanNotValidErr} ssePanErr={ssePanErr} sseServiceErr={sseServiceErr} 
          sseAssesYearErr={sseAssesYearErr} sseAssesCaseNameErr={sseAssesCaseNameErr} sseAssesCaseDescriptionErr={sseAssesCaseDescriptionErr}
          serviceIdErr={serviceIdErr} assesmentYearErr={assesmentYearErr} caseNameErr={caseNameErr} caseDescriptionErr={caseDescriptionErr}/>
      case 1:
        return <EditCaseQuestionnaire1ClientCases selectedServiceId={selectedServiceId} 
          onSelectQuestionLevelOne={handleChangeFormStateQuestionOne} />;
      case 2:
        return <EditCaseQuestionnaire2ClientCases onSelectQuestionLevelTwo={handleChangeFormStateQuestionTwo}/>;
      case 3:
        return <EditCaseQuestionnaire3ClientCases  onSelectQuestionLevelThree={handleChangeFormStateQuestionThree}/>;
      case 4:
        return <EditCaseUploadFileClientCases onSelectUploadFiles={handleChangeFormStateUploadFiles}/>;
      case 5:
        return <EditCaseUploadFileClientCasesPersonal onSelectUploadFilesPersonal={handleChangeFormStateUploadFilesPersonal}/>;
      default:
        return "Unknown step";
    }
  }

  useEffect(() => {
    // readItemFromStorage();
    dispatch(userActions.checkSessionValidity());

    // to fetch case details of selected case
    const requestOptionsCaseDetails = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({'case_id': editItemIs.case_id, 'business_id': business_id,
      'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_CASE_DETAILS , requestOptionsCaseDetails)
      .then((Response) => Response.json())
      .then( async(caseDetails) => {
        console.log("caseDetailson index of edit info page",caseDetails);
        if(caseDetails.success == true)
        {
          console.log("caseDetails data is =========",caseDetails, caseDetails.data.case_details);
          setCaseOverallDetails(caseDetails.data.case_details)

          if( caseDetails.data.case_details.case_alert_message !== null ){

          }
        }
        // else if(caseDetails.success == false){
        //   setCaseOverallDetails()
        // }

      })

  }, [flag]);

  // const readItemFromStorage = async() => {
  //   try {
  //     setDeviceToken(deviceTokenFromRedux)
  //     setSessionId(sessionIdFromRedux)
  //     setflag(false)    
  //     } catch (error) {
  //       console.log("ERROR:",error);
  //     }
  // };

  const handleChangeFormState = async(formState, serviceId,forSelf,SSEInfo,CountryCode,assesment_yearH, 
    case_name, case_description) => 
  {
    console.log("in handle Change Form State case_name, case_description is",case_name, case_description);
     console.log('I will submit my ChildForm Input State:, serviceID is,',formState,serviceId,assesment_yearH);
     console.log('serviceID is, forSelf, SSEInfo',serviceId, forSelf, SSEInfo);
     console.log(",CountryCode",CountryCode);
     console.log("assesment_yearH is on main page",assesment_yearH);
     let assYearForEdit = assesment_yearH;
     console.log("assYearForEdit is",assYearForEdit);
     setformState(formState);
     await setselectedServiceId(serviceId);
     await setSelfAssesment(JSON.parse(forSelf));
     await setCountryCodeHere(CountryCode);
     await setAssesmentYearHere(assesment_yearH);
     await setCaseNameHere(case_name);
     await setCaseDescriptionHereHere(case_description);
     console.log("SelfAssesment in next",SelfAssesment); 
    await setCreateCaseDetails({ ...CreateCaseDetails,
      created_by: "client",
      // service_id: JSON.parse(serviceId),
      service_id: serviceId,
      case_id: editItemIs.case_id,
      client_id: editItemIs.client_id,
      self_assessment: JSON.parse(forSelf),
      assessment_year: assesment_yearH,
      name: case_name,
      description: case_description,
      device_token: device_token,
      session_id: session_id,
    });
     if(sseInfoFromReduxOnMainPage !== undefined ){
       await setSSEInfo(sseInfoFromReduxOnMainPage)
       console.log('sse info in condition',sseInfoFromReduxOnMainPage);
     }
    //  setSSEInfo(SSEInfo);
    //  setSSEInfo({...SSEInfo, service_id: JSON.parse(serviceId) , self_assessment: JSON.parse(forSelf) })
     console.log("SSEInfo in next",SSEInfo); 
    //  await setCreateCaseDetails({
    //     case_id: editItemIs.case_id,
    //     client_id: editItemIs.client_id,
    //     name: editItemIs.name,
    //     service_id: JSON.parse(serviceId),
    //     description: editItemIs.description,
    //     device_token: device_token,
    //     session_id: session_id,
    //     self_assessment: editItemIs.self_assessment,
    //  });
    
     await addCreateCaseDetailsRedux(CreateCaseDetails);
    console.log("setCreateCaseDetails in handle func",CreateCaseDetails)
  }

  const handleChangeFormStateQuestionOne = async(formState,qsn_list,empty ) => {
    console.log('call of fun in main page with form state que 1',formState);
    console.log('call of fun in main page with Qsn_Answer_list 1',qsn_list);
    setqsn_list(qsn_list);
    setformStateQLOne(formState);
    setqsn_list_LTwo(qsn_list);

    if( empty == "Yes Empty"){
      if( NextOrBackIs == "next" ){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if( NextOrBackIs == "back" ){
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }

    //   console.log("yes array is empty");
      // Swal.fire({
      //   // icon: 'error',
      //   text: "Questions are not available for this set one..Please navigate to next set",
      //   confirmButtonColor: 'primary',
      //   cancelButtonColor: '#D3C8C7',
      //   confirmButtonText: 'OK',
      //   showCancelButton: true,
      //   cancelButtonText: 'cancel',
      //   // timer: 5000,
      // }).then(function (result) {
      //     console.log("result of swal",result.isConfirmed)
      //     if(result.isConfirmed == true){
      //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //     }
      //     else if(result.isConfirmed == false){
      //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
      //     }
      // })

    }
  }

  const handleChangeFormStateQuestionTwo = async(formState,qsn_list,empty) => {
    console.log('call of fun in main page with form state que 2',formState);
    console.log('call of fun in main page with qsn_list que 2',qsn_list);
    setformStateQLTwo(formState);
    setqsn_list_LTwo(qsn_list);
    if( empty == "Yes Empty"){
      if( NextOrBackIs == "next" ){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if( NextOrBackIs == "back" ){
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }

      //   console.log("yes array is empty");
        // Swal.fire({
        //   // icon: 'error',
        //   text: "Questions are not available for this set two..Please navigate to next set",
        //   confirmButtonColor: 'primary',
        //   cancelButtonColor: '#D3C8C7',
        //   confirmButtonText: 'OK',
        //   showCancelButton: true,
        //   cancelButtonText: 'cancel',
        //   // timer: 5000,
        // }).then(function (result) {
        //     console.log("result of swal",result.isConfirmed)
        //     if(result.isConfirmed == true){
        //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //     }
        //     else if(result.isConfirmed == false){
        //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
        //     }
        // })

      }
  }

  const handleChangeFormStateQuestionThree  = async(formState,qsn_list,empty) => {
    console.log('call of fun in main page with form state que 3',formState);
    console.log('call of fun in main page with qsn_list que 3',qsn_list);
    setformStateQLThree(formState);
    setqsn_list_LThree(qsn_list);
    console.log('NextOrBackIs in que set 3======================',NextOrBackIs);
    if( empty == "Yes Empty"){
      if( NextOrBackIs == "next" ){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if( NextOrBackIs == "back" ){
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }

      //   console.log("yes array is empty");
        // Swal.fire({
        //   // icon: 'error',
        //   text: "Questions are not available for this set three..Please navigate to next set",
        //   confirmButtonColor: 'primary',
        //   cancelButtonColor: '#D3C8C7',
        //   confirmButtonText: 'OK',
        //   showCancelButton: true,
        //   cancelButtonText: 'cancel',
        //   // timer: 5000,
        // }).then(function (result) {
        //     console.log("result of swal",result.isConfirmed)
        //     if(result.isConfirmed == true){
        //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //     }
        //     else if(result.isConfirmed == false){
        //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
        //     }
        // })
      }
  }

  const handleChangeFormStateUploadFiles = async(formState,empty) => {
    setformStateUploadFile(formState);
    console.log('NextOrBackIs in doc======================',NextOrBackIs);
    // if( empty == "Yes Empty"){
    //   if( NextOrBackIs == "next" ){
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //   }
    //   if( NextOrBackIs == "back" ){
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //   }

      //   console.log("yes array is empty");
        // Swal.fire({
        //   // icon: 'error',
        //   text: "Documents are not available for this set..Please navigate to next set",
        //   confirmButtonColor: 'primary',
        //   cancelButtonColor: '#D3C8C7',
        //   confirmButtonText: 'OK',
        //   showCancelButton: true,
        //   cancelButtonText: 'cancel',
        //   // timer: 5000,
        // }).then(function (result) {
        //     console.log("result of swal",result.isConfirmed)
        //     if(result.isConfirmed == true){
        //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //     }
        //     else if(result.isConfirmed == false){
        //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
        //     }
        // })
      // }
  }

  const handleChangeFormStateUploadFilesPersonal = async(formState, empty) => {
    // console.log('call of fun in main page with form state',formState);
    setformStateUploadFilePersonal(formState);
    // if( empty == "Yes Empty"){
    //   if( NextOrBackIs == "next" ){
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //   }
    //   if( NextOrBackIs == "back" ){
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    //   }

      //   console.log("yes array is empty");
        // Swal.fire({
        //   // icon: 'error',
        //   text: "Documents are not available for this set..Please Click Submit to save the case",
        //   confirmButtonColor: 'primary',
        //   cancelButtonColor: '#D3C8C7',
        //   confirmButtonText: 'OK',
        //   showCancelButton: true,
        //   cancelButtonText: 'cancel',
        //   // timer: 5000,
        // })
        // .then(function (result) {
        //     console.log("result of swal",result.isConfirmed)
        //     if(result.isConfirmed == true){
        //       setActiveStep((prevActiveStep) => prevActiveStep);
        //     }
        //     else if(result.isConfirmed == false){
        //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
        //     }
        // })
      // }
  }

  const validateSSEInfo = () => {
    console.log("SSEInfo in validation",SSEInfo);
    console.log('sse info in validation from redux',sseInfoFromReduxOnMainPage);
    let sseNameErr = '';
    let sseEmailErr = '';
    let sseEmailIncorrectErr = '';
    let ssePhoneNoErr = '';
    let ssePhoneNoNotDigitErr = '';
    let ssePhoneNoIncorrectErr = '';
    let ssePanErr = '';
    let ssePanNotValidErr = '';
    let sseServiceErr = '';
    let sseAssesYearErr = '';
    let sseAssesCaseNameErr = '';
    let sseAssesCaseDescriptionErr = '';

    if(!sseInfoFromReduxOnMainPage.assesse_name || sseInfoFromReduxOnMainPage.assesse_name == " ")  {
      sseNameErr = 'Please Enter ASSESSE Name';
      console.log("in validateSSEInfo sseNameErr");
    }
    if(!sseInfoFromReduxOnMainPage.assesse_email || sseInfoFromReduxOnMainPage.assesse_email == " ")  {
      sseEmailErr = 'Please Enter ASSESSE Email';
    }
      else if(typeof sseInfoFromReduxOnMainPage.assesse_email !== "undefined"){
            let lastAtPos = sseInfoFromReduxOnMainPage.assesse_email.lastIndexOf('@');
            let lastDotPos = sseInfoFromReduxOnMainPage.assesse_email.lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
                sseEmailIncorrectErr = "Email is not valid";
                if(sseEmailIncorrectErr) {
                  setsseEmailIncorrectErr(sseEmailIncorrectErr);
                }
            }
      }
    
    if(!sseInfoFromReduxOnMainPage.assesse_phone || sseInfoFromReduxOnMainPage.assesse_phone == " " )  {
      ssePhoneNoErr = 'Please Enter ASSESSE Phone Number';
    }
    else if (typeof sseInfoFromReduxOnMainPage.assesse_phone !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/)

        // if (!pattern.test(sseInfoFromReduxOnMainPage.assesse_phone)) {
        //   ssePhoneNoNotDigitErr = "Please enter only number.";
        // }else if(sseInfoFromReduxOnMainPage.assesse_phone.length != 10){
        //   ssePhoneNoIncorrectErr = "Please enter valid phone number.";
        // }
        if (CountryCodeHere == "in"){
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "us"){
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "gb"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "ca"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "ae"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 12){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "sg"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 8){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "np"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 12){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "jp"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 10){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "de"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 12){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "bt"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.length !== 12){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
        else if(CountryCodeHere == "au"){
          // console.log("clientDetails.phone.length gb",clientDetails.phone.length)
          if(sseInfoFromReduxOnMainPage.assesse_phone.slength !== 13){
            ssePhoneNoIncorrectErr = "Please enter valid phone number.";
          }
        }
      }

    if(!sseInfoFromReduxOnMainPage.assesse_pan || sseInfoFromReduxOnMainPage.assesse_pan == " ")  {
      ssePanErr = 'Please Enter ASSESSE PAN';
    }
    else if (typeof sseInfoFromReduxOnMainPage.assesse_pan !== "undefined") {
        var pattern = new RegExp(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/)
        if(sseInfoFromReduxOnMainPage.assesse_pan.length !== 10){
          console.log("in != 10")
          ssePanNotValidErr = "PAN Number is Not Valid";
        }
        else if (!pattern.test(sseInfoFromReduxOnMainPage.assesse_pan)) {
          console.log("in string check")
          ssePanNotValidErr = "PAN Number is Not Valid";
        } 
      }

    if(!sseInfoFromReduxOnMainPage.service_id || sseInfoFromReduxOnMainPage.service_id == " ")  {
      sseServiceErr = 'Please Select Service';
    }
    if(!sseInfoFromReduxOnMainPage.assessment_year || sseInfoFromReduxOnMainPage.assessment_year == " ")  {
      sseAssesYearErr = 'Please Select Assesment Year';
    }
    if(!sseInfoFromReduxOnMainPage.name || sseInfoFromReduxOnMainPage.name == " ")  {
      sseAssesCaseNameErr = 'Please Enter Case Name';
    }
    if(!sseInfoFromReduxOnMainPage.description || sseInfoFromReduxOnMainPage.description == " ")  {
      sseAssesCaseDescriptionErr = 'Please Enter Case Description';
    }
    

    if(sseNameErr || sseEmailErr || ssePhoneNoErr || ssePanErr || ssePanNotValidErr || sseServiceErr || 
      sseAssesYearErr || sseAssesCaseNameErr || sseAssesCaseDescriptionErr || sseEmailIncorrectErr ||
        ssePhoneNoIncorrectErr || ssePhoneNoNotDigitErr){
      setsseNameErr(sseNameErr);
      setsseEmailErr(sseEmailErr);
      setsseEmailIncorrectErr(sseEmailIncorrectErr);
      setssePhoneNoErr(ssePhoneNoErr);
      setssePhoneNoNotDigitErr(ssePhoneNoNotDigitErr);
      setssePhoneNoIncorrectErr(ssePhoneNoIncorrectErr);
      setssePanErr(ssePanErr);
      setssePanNotValidErr(ssePanNotValidErr);
      setsseServiceErr(sseServiceErr);
      setsseAssesYearErr(sseAssesYearErr);
      setsseAssesCaseNameErr(sseAssesCaseNameErr);
      setsseAssesCaseDescriptionErr(sseAssesCaseDescriptionErr);

      return false;
    }
    return true;
  }

  const validateSelfAssTrue = () => {
    console.log("in validateSelfAssTrue",CreateCaseDetails)
    let serviceIdErr = '';
    let assesmentYearErr = '';
    let caseNameErr = '';
    let caseDescriptionErr = '';

    if(!CreateCaseDetails.service_id || CreateCaseDetails.service_id == "")  {
      serviceIdErr = 'Please Select Service';
      // console.log("in validateSSEInfo sseNameErr");
    }
    if(!CreateCaseDetails.assessment_year)  {
      assesmentYearErr = 'Please Select Assessment Year';
    }
    if(!CreateCaseDetails.name)  {
      caseNameErr = 'Please Enter Case Name';
    }
    if(!CreateCaseDetails.description)  {
      caseDescriptionErr = 'Please Enter Case Description';
    }

    if(serviceIdErr || assesmentYearErr || caseNameErr || caseDescriptionErr){
      setserviceIdErr(serviceIdErr);
      setassesmentYearErr(assesmentYearErr);
      setcaseNameErr(caseNameErr);
      setcaseDescriptionErr(caseDescriptionErr);

      return false;
    }
    return true;
  }

  const handleNext = async() => {
    // await localStorage.setItem('backornext', JSON.stringify("next"));
    await setNextOrBackIs("next")
   console.log('ActiveStep is in 1st if',activeStep)
   console.log('formState is in 1st if,selfAssesFromRedux',formState,selfAssesFromRedux)
      // console.log("created_case_case_id is",created_case_case_id)
      // if( created_case_case_id == null ){
      //   console.log("created_case_case_id == null ")
      // }
      // else if(created_case_case_id == "null"){
      //    console.log("created_case_case_id == null in paranthesis ")
      // }
    console.log('ActiveStep is',activeStep)

    // To save General details 
    if ( formState == "1" && activeStep == 0){
      console.log('ActiveStep is in 1st if',activeStep)
      console.log('formState is in 1st if',formState)
      //  save General details if self ass is true
      // if( SelfAssesment == true || SelfAssesment == 'true' ){
      if( selfAssesFromRedux == "true"){
        const isValid = validateSelfAssTrue();
        if(isValid){
          setShowProcedure(true);
          console.log("created_case_case_id is",created_case_case_id)
          // if(JSON.parse(localStorage.getItem('created_case_case_id')) == null)
          // {
          
            console.log("CreateCaseDetailsRedux in submit",createCaseDetailsRedux)
            console.log("CreateCaseDetails in submit,",AssesmentYearHere,CreateCaseDetails)
            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              // body: JSON.stringify(CreateCaseDetails)
              body: JSON.stringify({ 
                'case_id':CreateCaseDetails.case_id, 'client_id': CreateCaseDetails.client_id,
                'assessment_year': AssesmentYearHere, 'created_by': CreateCaseDetails.created_by,
                'self_assessment': CreateCaseDetails.self_assessment, 'service_id':CreateCaseDetails.service_id,
                'name': CaseNameHere,"description": CaseDescriptionHere,
                'business_id': business_id, 'device_token': device_token, 'session_id': session_id 
              })
            };
            return fetch( process.env.REACT_APP_UPDATE_CASE , requestOptions)
              .then((Response) => Response.json())
              .then( async(caseUpdated) => {
                setShowProcedure(false);
                console.log("caseUpdated sucess",caseUpdated.success);
                if(caseUpdated.success == true){
                  console.log("caseUpdated response",caseUpdated.data.case_info);
                  console.log("It's Success of case creation general details self ass true")
                  await addCreateCaseDetailsRedux({...CreateCaseDetails,
                    case_id: caseUpdated.data.case_info.case_id })
                  await addnewCaseCreateCaseIdRedux(caseUpdated.data.case_info.case_id)
                  await addSseInfoRedux({
                    created_by: "client",
                    case_id: caseUpdated.data.case_info.case_id,
                    service_id: caseUpdated.data.case_info.service_id,
                    assesse_id:"",
                    assesse_name: "",
                    assesse_id:  "",
                    assesse_email: "",
                    assesse_pan: "",
                    assesse_phone: "",
                    assessment_year: "",
                    self_assessment: caseUpdated.data.case_info.self_assessment,
                    device_token: JSON.parse(localStorage.getItem('device_token')),
                    session_id: JSON.parse(localStorage.getItem('session_id')),
                  })
                  await localStorage.setItem('selected_case_for_edit', JSON.stringify(caseUpdated.data.case_info));
                  await localStorage.setItem('case_creation_details', JSON.stringify(caseUpdated.data.case_info));
                  await localStorage.setItem('created_case_case_id', JSON.stringify(caseUpdated.data.case_info.case_id));
                  console.log("selected_case_for_edit after api call",JSON.parse(localStorage.getItem('selected_case_for_edit')));
                  await localStorage.setItem('service_id_of_case_for_doc', JSON.stringify(caseUpdated.data.case_info.service_id));
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  // setCreateCaseDetails({});
                  // setformState(0);
                  setserviceIdErr("");
                  setassesmentYearErr("");
                  setcaseNameErr("");
                  setcaseDescriptionErr("");
                }
                if(caseUpdated.success == false){
                  Swal.fire({
                    icon: 'error',
                    text: caseUpdated.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
          // }
          // else if(JSON.parse(localStorage.getItem('created_case_case_id')) !== null){
          //   Swal.fire({
          //     icon: 'error',
          //     text: "Case is Created Already",
          //     confirmButtonColor: '#d33',
          //     confirmButtonText: 'OK',
          //     timer: 5000,
          //   })
          //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
          // }
        }
      }
      //  save General details if self ass is false
      // else if( SelfAssesment == false || SelfAssesment == 'false' )
      else if( selfAssesFromRedux == 'false' )
      {
        // if(JSON.parse(localStorage.getItem('created_case_case_id')) == null)
        // {
        const isValid = validateSSEInfo();
        if(isValid){
          setShowProcedure(true);
          console.log("SSEInfo in api call",SSEInfo)
          console.log("SSEInforedux in api call",sseInfoFromReduxOnMainPage)
          const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({...sseInfoFromReduxOnMainPage, 'business_id': business_id})
          };
          return fetch( process.env.REACT_APP_UPDATE_CASE , requestOptions)
            .then((Response) => Response.json())
            .then( async(caseUpdated) => {
              setShowProcedure(false);
              console.log("caseUpdated sucess with form",caseUpdated.success);
              if(caseUpdated.success == true){
                console.log("caseUpdated response",caseUpdated.data.case_info);
                console.log("It's Success of case creation general details self ass false")
                await addSseInfoRedux({...SSEInfo, case_id: caseUpdated.data.case_info.case_id })
                await addnewCaseCreateCaseIdRedux(caseUpdated.data.case_info.case_id)    
                await localStorage.setItem('selected_case_for_edit', JSON.stringify(caseUpdated.data.case_info));           
                await localStorage.setItem('case_creation_details', JSON.stringify(caseUpdated.data.case_info));
                await localStorage.setItem('created_case_case_id', JSON.stringify(caseUpdated.data.case_info.case_id));
                console.log("selected_case_for_edit after api call",JSON.parse(localStorage.getItem('selected_case_for_edit')))
                await localStorage.setItem('service_id_of_case_for_doc', JSON.stringify(caseUpdated.data.case_info.service_id));
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setsseNameErr();
                setsseEmailErr();
                setsseEmailIncorrectErr();
                setssePhoneNoErr();
                setssePhoneNoNotDigitErr();
                setssePhoneNoIncorrectErr();
                setssePanErr();
                setssePanNotValidErr();
                setsseServiceErr();
                setsseAssesYearErr();
                setsseAssesCaseNameErr();
                setsseAssesCaseDescriptionErr();
              }
              if(caseUpdated.success == false){
                Swal.fire({
                  icon: 'error',
                  text: caseUpdated.errors,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK',
                  timer: 5000,
                })
              }
            })
            .catch(err => {
              setShowProcedure(false);
              Swal.fire({
                icon: 'error',
                // text: "Something went wrong",
                text: "Server Error. Please try again.",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            });
        }
        // }
        // else if(JSON.parse(localStorage.getItem('created_case_case_id')) !== null){
        //   Swal.fire({
        //     icon: 'error',
        //     text: "Case is Created Already",
        //     confirmButtonColor: '#d33',
        //     confirmButtonText: 'OK',
        //     timer: 5000,
        //   })
        //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // }
      }
    }
    else if(formState == 0 && activeStep == 0 ){
      Swal.fire({
        text: "Please Fill Generic form First",
        confirmButtonColor: 'primary',
        confirmButtonText: 'OK',
        timer: 5000,
      })
    }

    // This if is to call Save 1st level answers
    if ( formStateQLOne == "2" && activeStep == 1  ){
      console.log('ActiveStep is in 2',activeStep)
      setQsn_Answer_list([])
      // qsn_list.map((f) => {
      qsnListFromRedux.map((f) => {
        if(f.question_type === "TEXT-ANSWER"){
          if(f.answer){
            Qsn_Answer_list.push({
              "question_id": f.question_id,
              "boolean_ans": '',
              "text_answer": f.answer,
              "single_answer": '',
              "multi_answer": [],
            });
          }
        }
        else if(f.question_type === "BOOLEAN"){
          if(f.answer == true || f.answer == false){
            Qsn_Answer_list.push({
              "question_id": f.question_id,
              "boolean_ans": f.answer,
              "text_answer": '',
              "single_answer": '',
              "multi_answer": [],
            });
          }
        }
        // else if(f.question_type === "SINGLE-ANSWER"){
        //   for (var i = 0; i < f.options.length; i++) {
        //     if (f.options[i].selected === true) {
        //       setoption_single_ans(f.options[i].option_id)
        //       let option_single_ans_here = f.options[i].option_id
        //       console.log("option_single_ans, option_single_ans_here",option_single_ans, option_single_ans_here)
        //       Qsn_Answer_list.push({
        //         "question_id": f.question_id,
        //         "boolean_ans": '',
        //         "text_answer": '',
        //         "single_answer": option_single_ans_here,
        //         "multi_answer": [],
        //       });
        //     } 
        //   }
        // }
        else if(f.question_type === "SINGLE-ANSWER"){
          // if(f.answer !== "" && f.answer !== null ){
          if(f.single_answer !== "" && f.single_answer !== null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                // setoption_single_ans(f.options[i].option_id)
                let option_single_ans_here = f.options[i].option_id
                console.log("option_single_ans_here", option_single_ans_here)
                Qsn_Answer_list.push({
                  "question_id": f.question_id,
                  "boolean_ans": '',
                  "text_answer": '',
                  "single_answer": option_single_ans_here,
                  // "single_answer_text": f.answer,
                  "multi_answer": [],
                });
              }
            }
          }
        }

        else if (f.question_type === "MULTIPLE-ANSWER") {
          if(f.answer == "ok" || f.answer == null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,                            
                });
              }
              console.log("options_list",options_list) 
            }
            if(options_list.length > 0){
              Qsn_Answer_list.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list,
              });
            }
          }
          else if(f.answer == "NotOk"){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,                            
                });
              }
              console.log("options_list",options_list) 
            }
            if(options_list.length > 0){
              Qsn_Answer_list.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list,
              });
            }
          }
          setOptions_list([]);
        }
      })
      console.log("Qsn_Answer_list",Qsn_Answer_list)
      // let case_id = JSON.parse(localStorage.getItem('created_case_case_id'));
      console.log("case_id newCaseCreatedCaseIdRedux in que l1 form state",newCaseCreatedCaseIdRedux);

      let optionalAnsIsEmpty = false
      qsnListFromRedux.map((f) => {
        console.log("f in qsnListFromRedux map",f)
        if(f.question_type === "TEXT-ANSWER"){
          console.log("f in TEXT-ANSWER qsnListFromRedux map",f)
          if(f.is_optional == false && f.answer == null || f.answer == ''){
            console.log("f in TEXT-ANSWER condition satisfying",f)
            optionalAnsIsEmpty = true
          }
        }
        else if(f.question_type === "BOOLEAN"){
          console.log("f in BOOLEAN qsnListFromRedux map",f)
          if(f.is_optional == false && f.answer == null){
            console.log("f in BOOLEAN condition satisfying",f)
            optionalAnsIsEmpty = true
          }
        }
        else if(f.question_type === "SINGLE-ANSWER"){
          console.log("f in SINGLE-ANSWER qsnListFromRedux map",f)
          if(f.is_optional == false && f.single_answer == "" && f.single_answer == null ){
            optionalAnsIsEmpty = true
          }
        }
        // else if (f.question_type === "MULTIPLE-ANSWER") {
        //   console.log("f in MULTIPLE-ANSWER qsnListFromRedux map",f)
        // }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          console.log("f in MULTIPLE-ANSWER qsnListFromRedux map",f)
          if(f.is_optional == false ){
            let options_list = []
            console.log("f in MULTIPLE-ANSWER qsnListFromRedux condition",f)
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                console.log("f.options[i].selected === true",f.options[i].selected)
                options_list.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,                            
                });
              }
              console.log("options_list",options_list) 
            }
            if( options_list.length == 0){
              console.log("options_list.length",options_list.length) 
              optionalAnsIsEmpty = true
            }
          }
        }
      })
      console.log("optionalAnsIsEmpty check",optionalAnsIsEmpty)
      
      // if(Qsn_Answer_list.length === qsnListFromRedux.length){
      if( optionalAnsIsEmpty == false ){
        if( Qsn_Answer_list.length == 0){
          setActiveStep((prevActiveStep) => prevActiveStep + 1); 
          // Swal.fire({
          //   // icon: 'error',
          //   text: "Questions are not available for this set..Please navigate to next set",
          //   confirmButtonColor: 'primary',
          //   cancelButtonColor: '#D3C8C7',
          //   confirmButtonText: 'OK',
          //   showCancelButton: true,
          //   cancelButtonText: 'cancel',
          //   // timer: 5000,
          // }).then(function (result) {
          //     console.log("result of swal",result.isConfirmed)
          //     if(result.isConfirmed == true){
          //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
          //     }
          //     else if(result.isConfirmed == false){
          //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
          //     }
          // })
        }
        else{
          setShowProcedure(true);
          console.log("both lengths are equal, Qsn_Answer_list.length, qsn_list.length, qsnListFromRedux.length",
          Qsn_Answer_list.length,qsn_list.length, qsnListFromRedux.length)
          let SubmitAnsObject = ({
            case_id: newCaseCreatedCaseIdRedux, 
            question_answers: Qsn_Answer_list,
            business_id: business_id,
            device_token: device_token,
            session_id: session_id,
          })
          console.log("SubmitAnsObject",SubmitAnsObject)

          console.log("SubmitAnsObject",SubmitAnsObject)
              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(SubmitAnsObject)
              };
              return fetch( process.env.REACT_APP_ADD_UPDATE_CASE_ANSWERS , requestOptions)
                .then((Response) => Response.json())
                .then( answerSavesQusLevelOne  => {
                  setShowProcedure(false);
                  console.log("answerSavesQusLevelOne sucess with form",answerSavesQusLevelOne.success);
                  if(answerSavesQusLevelOne.success == true){
                    console.log("It's Success of case creation answer Saves Qus Level One")
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                  if(answerSavesQusLevelOne.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: answerSavesQusLevelOne.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });
            }
      }
      else{
        console.log("optionalAnsIsEmpty is true check all fields")
        console.log("both lengths are not equal,Qsn_Answer_list.length,qsn_list.length, qsnListFromRedux.length",
          Qsn_Answer_list.length,qsn_list.length,qsnListFromRedux.length)
        Swal.fire({
          text: "Please Answer All Mandatory Questions",
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          timer: 5000,
        })
      }
    }

    // This if is to call Save 2nd level answers
    if ( formStateQLTwo == "3" && activeStep == 2  ){
      console.log('ActiveStep is in 3',activeStep)
      setQsn_Answer_list_LTwo([])
      qsnListLevelTwoFromRedux.map((f) => {
        if(f.question_type === "TEXT-ANSWER"){
          if(f.answer){
            Qsn_Answer_list_LTwo.push({
              "question_id": f.question_id,
              "boolean_ans": '',
              "text_answer": f.answer,
              "single_answer": '',
              "multi_answer": [],
            });
          }
        }
        else if(f.question_type === "BOOLEAN"){
          if(f.answer == true || f.answer == false){
            Qsn_Answer_list_LTwo.push({
              "question_id": f.question_id,
              "boolean_ans": f.answer,
              "text_answer": '',
              "single_answer": '',
              "multi_answer": [],
            });
          }
        }
        // if(f.question_type === "SINGLE-ANSWER"){
        //   for (var i = 0; i < f.options.length; i++) {
        //     if (f.options[i].selected === true) {
        //       setoption_single_ans_LTwo(f.options[i].option_id)
        //       let option_single_ans_LTwo_here = f.options[i].option_id
        //       console.log("option_single_ans, option_single_ans_LTwo_here",option_single_ans, option_single_ans_LTwo_here)
        //       Qsn_Answer_list_LTwo.push({
        //         "question_id": f.question_id,
        //         "boolean_ans": '',
        //         "text_answer": '',
        //         "single_answer": option_single_ans_LTwo_here,
        //         "multi_answer": [],
        //       });
        //     } 
        //   }
        // }
        else if(f.question_type === "SINGLE-ANSWER"){
          // if(f.answer !== "" && f.answer !== null ){
          if(f.single_answer !== "" && f.single_answer !== null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                // setoption_single_ans(f.options[i].option_id)
                let option_single_ans_LTwo_here = f.options[i].option_id
                console.log("option_single_ans_LTwo_here", option_single_ans_LTwo_here)
                Qsn_Answer_list_LTwo.push({
                  "question_id": f.question_id,
                  "boolean_ans": '',
                  "text_answer": '',
                  "single_answer": option_single_ans_LTwo_here,
                  // "single_answer_text": f.answer,
                  "multi_answer": [],
                });
              }
            }
          }
        }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          if(f.answer == "ok" || f.answer == null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list_LTwo.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,                            
                });
              }
              console.log("options_list_LTwo",options_list_LTwo) 
            }
            if(options_list_LTwo.length > 0){
              Qsn_Answer_list_LTwo.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list_LTwo,
              });
            }
          }
          else if(f.answer == "NotOk"){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list_LTwo.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,                            
                });
              }
              console.log("options_list_LTwo",options_list_LTwo) 
            }
            if(options_list_LTwo.length > 0){
              Qsn_Answer_list_LTwo.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list_LTwo,
              });
            }
          }
        }
        setOptions_list_LTwo([]);
      })
      console.log("Qsn_Answer_list_LTwo",Qsn_Answer_list_LTwo)
      // let case_id = JSON.parse(localStorage.getItem('created_case_case_id'));
      console.log("case_id newCaseCreatedCaseIdRedux in que l1 form state",newCaseCreatedCaseIdRedux);

      let optionalAnsIsEmptyLevel2 = false
      qsnListLevelTwoFromRedux.map((f) => {
        console.log("f in qsnListLevelTwoFromRedux map",f)
        if(f.question_type === "TEXT-ANSWER"){
          console.log("f in TEXT-ANSWER qsnListLevelTwoFromRedux map",f)
          if(f.is_optional == false && f.answer == null || f.answer == ''){

            optionalAnsIsEmptyLevel2 = true
          }
        }
        else if(f.question_type === "BOOLEAN"){
          console.log("f in BOOLEAN qsnListLevelTwoFromRedux map",f)
          if(f.is_optional == false && f.answer == null){
            console.log("f in BOOLEAN condition satisfying",f)
            optionalAnsIsEmptyLevel2 = true
          }
        }
        else if(f.question_type === "SINGLE-ANSWER"){
          console.log("f in SINGLE-ANSWER qsnListLevelTwoFromRedux map",f)
          if(f.is_optional == false && f.single_answer == "" && f.single_answer == null ){
            optionalAnsIsEmptyLevel2 = true
          }
        }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          console.log("f in MULTIPLE-ANSWER qsnListLevelTwoFromRedux map",f)
          if(f.is_optional == false ){
            let options_list_LTwo = []
            console.log("f in MULTIPLE-ANSWER qsnListLevelTwoFromRedux condition",f)
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                console.log("f.options[i].selected === true",f.options[i].selected)
                options_list_LTwo.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,                            
                });
              }
              console.log("options_list_LTwo",options_list_LTwo) 
            }
            if( options_list_LTwo.length == 0){
              console.log("options_list_LTwo.length",options_list_LTwo.length) 
              optionalAnsIsEmptyLevel2 = true
            }
            // 
          }
        }
      })
      console.log("optionalAnsIsEmptyLevel2 2nd level check",optionalAnsIsEmptyLevel2)
      
      // if(Qsn_Answer_list_LTwo.length === qsnListLevelTwoFromRedux.length){
      if(optionalAnsIsEmptyLevel2 == false){
        if( Qsn_Answer_list_LTwo.length == 0){
          setActiveStep((prevActiveStep) => prevActiveStep + 1); 
          // Swal.fire({
          //   // icon: 'error',
          //   text: "Questions are not available for this set..Please navigate to next set",
          //   confirmButtonColor: 'primary',
          //   cancelButtonColor: '#D3C8C7',
          //   confirmButtonText: 'OK',
          //   showCancelButton: true,
          //   cancelButtonText: 'cancel',
          //   // timer: 5000,
          // }).then(function (result) {
          //     console.log("result of swal",result.isConfirmed)
          //     if(result.isConfirmed == true){
          //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
          //     }
          //     else if(result.isConfirmed == false){
          //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
          //     }
          // })
        }
        else{
          setShowProcedure(true);
          console.log("both lengths are equal, Qsn_Answer_list_LTwo.length, qsn_list_LTwo.length, qsnListLevelTwoFromRedux,length", 
            Qsn_Answer_list_LTwo.length,qsn_list_LTwo.length, qsnListLevelTwoFromRedux.length)
          let SubmitAnsObjectLevelTwo = ({
            case_id: newCaseCreatedCaseIdRedux,
            question_answers: Qsn_Answer_list_LTwo,
            business_id: business_id,
            device_token: device_token,
            session_id: session_id,
          })

        console.log("SubmitAnsObjectLevelTwo",SubmitAnsObjectLevelTwo)
            const requestOptions = {
              method: 'POST',
              headers: authHeader(),
              body: JSON.stringify(SubmitAnsObjectLevelTwo)
            };
            return fetch( process.env.REACT_APP_ADD_UPDATE_CASE_ANSWERS , requestOptions)
              .then((Response) => Response.json())
              .then( answerSavesQusLevelTwo  => {
                setShowProcedure(false);
                console.log("answerSavesQusLevelTwo sucess with form",answerSavesQusLevelTwo.success);
                if(answerSavesQusLevelTwo.success == true){
                  console.log("It's Success of case creation answer Saves Qus Level Two")
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                if(answerSavesQusLevelTwo.success == false){
                  Swal.fire({
                    icon: 'error',
                    text: answerSavesQusLevelTwo.errors,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK',
                    timer: 5000,
                  })
                }
              })
              .catch(err => {
                setShowProcedure(false);
                Swal.fire({
                  icon: 'error',
                  // text: "Something went wrong",
                  text: "Server Error. Please try again.",
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
              });
          }
      }
      else{
        console.log("both lengths are not equal, Qsn_Answer_list_LTwo.length, qsn_list_LTwo.length, qsnListLevelTwoFromRedux.length", 
          Qsn_Answer_list_LTwo.length,qsn_list_LTwo.length, qsnListLevelTwoFromRedux.length)
        Swal.fire({
          text: "Please Answer All Mandatory Questions",
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          timer: 5000,
        })
      }

      
    }

    // This if is to call Save 3nd level answers
    if ( formStateQLThree == "4" && activeStep == 3  ){
      console.log('ActiveStep is in 4',activeStep)

      setQsn_Answer_list_LThree([])
      qsnListLevelThreeFromRedux.map((f) => {
        if(f.question_type === "TEXT-ANSWER"){
          if(f.answer){
            Qsn_Answer_list_LThree.push({
              "question_id": f.question_id,
              "boolean_ans": '',
              "text_answer": f.answer,
              "single_answer": '',
              "multi_answer": [],
            });
          }
        }
        else if(f.question_type === "BOOLEAN"){
          if(f.answer == true || f.answer == false){
            Qsn_Answer_list_LThree.push({
              "question_id": f.question_id,
              "boolean_ans": f.answer,
              "text_answer": '',
              "single_answer": '',
              "multi_answer": [],
            });
          }
        }
        // else if(f.question_type === "SINGLE-ANSWER"){
        //   for (var i = 0; i < f.options.length; i++) {
        //     if (f.options[i].selected === true) {
        //       setoption_single_ans_LThree(f.options[i].option_id)
        //       let option_single_ans_LThree_here = f.options[i].option_id
        //       console.log("option_single_ans, option_single_ans_LThree_here",option_single_ans, option_single_ans_LThree_here)
        //       Qsn_Answer_list_LThree.push({
        //         "question_id": f.question_id,
        //         "boolean_ans": '',
        //         "text_answer": '',
        //         "single_answer": option_single_ans_LThree_here,
        //         "multi_answer": [],
        //       });
        //     } 
        //   }         
        // }
        else if(f.question_type === "SINGLE-ANSWER"){
          // if(f.answer !== "" && f.answer !== null ){
          if(f.single_answer !== "" && f.single_answer !== null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                // setoption_single_ans(f.options[i].option_id)
                let option_single_ans_LThree_here = f.options[i].option_id
                console.log("option_single_ans_LThree_here", option_single_ans_LThree_here)
                Qsn_Answer_list_LThree.push({
                  "question_id": f.question_id,
                  "boolean_ans": '',
                  "text_answer": '',
                  "single_answer": option_single_ans_LThree_here,
                  // "single_answer_text": f.answer,
                  "multi_answer": [],
                });
              }
            }
          }
        }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          setoptions_list_LThree([]);
          if(f.answer == "ok" || f.answer == null ){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list_LThree.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,                            
                });
              }
              console.log("options_list_LThree",options_list_LThree) 
            }
            if(options_list_LThree.length > 0){
              Qsn_Answer_list_LThree.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list_LThree,
              });
            } 
          } 
          else if(f.answer == "NotOk"){
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                options_list_LThree.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,                            
                });
              }
              console.log("options_list_LThree",options_list_LThree) 
            }
            if(options_list_LThree.length > 0){
              Qsn_Answer_list_LThree.push({
                question_id: f.question_id,
                boolean_ans: "",
                text_answer: "",
                single_answer: "",
                multi_answer: options_list_LThree,
              });
            } 
          }
        }
        
      })
      console.log("Qsn_Answer_list_LThree",Qsn_Answer_list_LThree)
      // let case_id = JSON.parse(localStorage.getItem('created_case_case_id'));
      console.log("case_id newCaseCreatedCaseIdRedux in que l1 form state",newCaseCreatedCaseIdRedux);

      let optionalAnsIsEmptyLevel3 = false
      qsnListLevelThreeFromRedux.map((f) => {
        console.log("f in qsnListLevelThreeFromRedux map",f)
        if(f.question_type === "TEXT-ANSWER"){
          console.log("f in TEXT-ANSWER qsnListLevelThreeFromRedux map",f)
          if(f.is_optional == false && f.answer == null || f.answer == ''){
            optionalAnsIsEmptyLevel3 = true
          }
        }
        else if(f.question_type === "BOOLEAN"){
          console.log("f in BOOLEAN qsnListLevelThreeFromRedux map",f)
          if(f.is_optional == false && f.answer == null){
            console.log("f in BOOLEAN condition satisfying",f)
            optionalAnsIsEmptyLevel3 = true
          }
        }
        else if(f.question_type === "SINGLE-ANSWER"){
          console.log("f in SINGLE-ANSWER qsnListLevelThreeFromRedux map",f)
          if(f.is_optional == false && f.single_answer == "" && f.single_answer == null ){
            optionalAnsIsEmptyLevel3 = true
          }
        }
        else if (f.question_type === "MULTIPLE-ANSWER") {
          console.log("f in MULTIPLE-ANSWER qsnListLevelThreeFromRedux map",f)
          if(f.is_optional == false ){
            let options_list_LTwo = []
            console.log("f in MULTIPLE-ANSWER qsnListLevelThreeFromRedux condition",f)
            for (var i = 0; i < f.options.length; i++) {
              if (f.options[i].selected === true) {
                console.log("f.options[i].selected === true",f.options[i].selected)
                options_list_LTwo.push({
                  option: f.options[i].option,
                  option_id: f.options[i].option_id,                            
                });
              }
              console.log("options_list_LTwo",options_list_LTwo) 
            }
            if( options_list_LTwo.length == 0){
              console.log("options_list_LTwo.length",options_list_LTwo.length) 
              optionalAnsIsEmptyLevel3 = true
            }
            // 
          }
        }
      })
      console.log("optionalAnsIsEmptyLevel3 3rd level check",optionalAnsIsEmptyLevel3)

      // if(Qsn_Answer_list_LThree.length === qsnListLevelThreeFromRedux.length){
        if( optionalAnsIsEmptyLevel3 == false ){
        if( Qsn_Answer_list_LThree.length == 0){
          setActiveStep((prevActiveStep) => prevActiveStep + 1); 
          // Swal.fire({
          //   // icon: 'error',
          //   text: "Questions are not available for this set..Please navigate to next set",
          //   confirmButtonColor: 'primary',
          //   cancelButtonColor: '#D3C8C7',
          //   confirmButtonText: 'OK',
          //   showCancelButton: true,
          //   cancelButtonText: 'cancel',
          //   // timer: 5000,
          // }).then(function (result) {
          //     console.log("result of swal",result.isConfirmed)
          //     if(result.isConfirmed == true){
          //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
          //     }
          //     else if(result.isConfirmed == false){
          //       setActiveStep((prevActiveStep) => prevActiveStep - 1);
          //     }
          // })
        }
        else{
          setShowProcedure(true);
          console.log("both lengths are equal, Qsn_Answer_list_LThree.length, qsn_list_LThree.length, qsnListLevelThreeFromRedux.length",
            Qsn_Answer_list_LThree.length,qsn_list_LThree.length, qsnListLevelThreeFromRedux.length)
          let SubmitAnsObjectLevelThree = ({
            case_id: newCaseCreatedCaseIdRedux, 
            question_answers: Qsn_Answer_list_LThree,
            business_id: business_id,
            device_token: device_token,
            session_id: session_id,
          })

          console.log("SubmitAnsObjectLevelThree",SubmitAnsObjectLevelThree)
              const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(SubmitAnsObjectLevelThree)
              };
              return fetch( process.env.REACT_APP_ADD_UPDATE_CASE_ANSWERS , requestOptions)
                .then((Response) => Response.json())
                .then( answerSavesQusLevelThree  => {
                  setShowProcedure(false);
                  console.log("answerSavesQusLevelThree sucess with form",answerSavesQusLevelThree.success);
                  if(answerSavesQusLevelThree.success == true){
                    console.log("It's Success of case creation answer Saves Qus Level Two")
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                  if(answerSavesQusLevelThree.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: answerSavesQusLevelThree.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }
                })
                .catch(err => {
                  setShowProcedure(false);
                  Swal.fire({
                    icon: 'error',
                    // text: "Something went wrong",
                    text: "Server Error. Please try again.",
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                  })
                });
            }
      }
      else{
        console.log("both lengths are not equal, Qsn_Answer_list_LThree.length, qsn_list_LThree.length, qsnListLevelThreeFromRedux.length", 
          Qsn_Answer_list_LThree.length,qsn_list_LThree.length, qsnListLevelThreeFromRedux.length)
        Swal.fire({
          text: "Please Answer All Mandatory Questions",
          confirmButtonColor: 'primary',
          confirmButtonText: 'OK',
          timer: 5000,
        })
      }
    }

    // This if is to call Save upload files case final state
    if ( formStateUploadFile == "5" && activeStep == 4 ){
      console.log("In submit function")
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // newCaseCreatedCaseIdRedux
        body: JSON.stringify({'case_id':newCaseCreatedCaseIdRedux ,'stage':"BUSINESS", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_CASE_DOCUMENTS , requestOptions)
        .then((Response) => Response.json())
        .then( async(caseDocuments) => {
          setShowProcedure(false);
          // setShowProcedure(false);
          console.log("caseDocuments for last level",caseDocuments);
          if(caseDocuments.success == true)
          {
            let forvalidation = caseDocuments.data.case_documents

            let validationCheckFlag = false
            forvalidation.map((f) => {
              if(f.is_optional == false){
                if ( !f.user_uploaded.document ){
                  validationCheckFlag = true
                }
              }
            })
            console.log("validationCheckFlag",validationCheckFlag)

            // let arrayWithFlag = (
            //     forvalidation.map((f) => {
            //       if( f.user_uploaded.document ) {
            //         f.fileUplodedFlag = true 
            //       }
            //       return f;
            //     })
            //   )
            // console.log("arrayWithFlag is",arrayWithFlag)

            // let getCountHere = [];
            // let TogetCount = (
            //   arrayWithFlag.map((f) => {
            //     if( f.fileUplodedFlag == true ) {
            //       getCountHere.push(f)
            //     }
            //   })
            // )
            // console.log("getCountHere",getCountHere)


            // if(getCountHere.length == forvalidation.length){
            if( validationCheckFlag == false){
              console.log("Files are uploaded")
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            // else if(getCountHere.length !== forvalidation .length){
            else if( validationCheckFlag == true ){ 
              console.log("Files are not uploaded")
              Swal.fire({
                // icon: 'error',
                text: "Please Upload All Mandatory Files",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }

            // forvalidation.map((f)=>{
            //   console.log("forvalidation",f.user_uploaded.document)
            //   if( f.user_uploaded.document  == undefined ) {
            //     console.log("Files are not uploaded")
            //     // Swal.fire({
            //     //     icon: 'error',
            //     //     text: "Please Upload All Files",
            //     //     confirmButtonColor: '#d33',
            //     //     confirmButtonText: 'OK',
            //     //     timer: 5000,
            //     //   })
            //   }
            //   else if( f.user_uploaded.document ){
            //     console.log("Files are uploaded")
            //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
            //   }
            // })
          }
        else{
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      .catch(err => {
        setShowProcedure(false);
        Swal.fire({
          icon: 'error',
          // text: "Something went wrong",
          text: "Server Error. Please try again.",
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })
      });
    }

    //  This if is to call Save upload files case final state personal
    if ( formStateUploadFilePersonal == "6" && activeStep == 5 ){
      console.log("In submit function personal")
      const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        // newCaseCreatedCaseIdRedux
        body: JSON.stringify({'case_id':newCaseCreatedCaseIdRedux ,'stage':"PERSONAL", 'business_id': business_id,
        'device_token': device_token, 'session_id': session_id })
      };
      fetch( process.env.REACT_APP_GET_CASE_DOCUMENTS , requestOptions)
        .then((Response) => Response.json())
        .then( async(caseDocuments) => {
          setShowProcedure(false);
          // setShowProcedure(false);
          console.log("caseDocuments for last level",caseDocuments);
          if(caseDocuments.success == true)
          {
            let forvalidation = caseDocuments.data.case_documents

            let validationCheckFlag = false
            forvalidation.map((f) => {
              if(f.is_optional == false){
                if ( !f.user_uploaded.document ){
                  validationCheckFlag = true
                }
              }
            })
            console.log("validationCheckFlag",validationCheckFlag)

            // let arrayWithFlag = (
            //     forvalidation.map((f) => {
            //       if( f.user_uploaded.document ) {
            //         f.fileUplodedFlag = true 
            //       }
            //       return f;
            //     })
            //   )
            // console.log("arrayWithFlag is",arrayWithFlag)
            // let getCountHere = [];
            // let TogetCount = (
            //   arrayWithFlag.map((f) => {
            //     if( f.fileUplodedFlag == true ) {
            //       getCountHere.push(f)
            //     }
            //   })
            // )
            // console.log("getCountHere",getCountHere)

            // if(getCountHere.length == forvalidation.length){
            if( validationCheckFlag == false ){
              console.log("Files are uploaded")
              // setActiveStep((prevActiveStep) => prevActiveStep + 1);
              setShowProcedure(true);
              let SubmitAnsObjectUploadFiles = ({
                case_id: newCaseCreatedCaseIdRedux, 
                case_status: "submit-case",
                business_id: business_id,
                device_token: device_token,
                session_id: session_id,
              })
              console.log("SubmitAnsObjectUploadFiles",SubmitAnsObjectUploadFiles)
                const requestOptions = {
                  method: 'POST',
                  headers: authHeader(),
                  body: JSON.stringify(SubmitAnsObjectUploadFiles)
                  // body: SubmitAnsObjectUploadFiles
                };
                return fetch( process.env.REACT_APP_UPDATE_CASE_STATUS , requestOptions)
                  .then((Response) => Response.json())
                  .then( caseSubmited  => {
                  console.log("caseSubmited sucess",caseSubmited.success);
                  setShowProcedure(false);
                  if(caseSubmited.success == true){
                    console.log("It's Success of case submit")
                    Swal.fire({
                      icon: 'success',
                      text: "Case is created Succesfully.",
                      // confirmButtonColor: '#d33',
                      confirmButtonColor: 'primary',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                    setRedirectToCaseList(true);
                    // updateCaseActionResetRedux();  
                    addqsnListRedux();
                    addqsnListLevelTwoRedux();
                    addqsnListLevelThreeRedux()  ;             
                  }
                  if(caseSubmited.success == false){
                    Swal.fire({
                      icon: 'error',
                      text: caseSubmited.errors,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK',
                      timer: 5000,
                    })
                  }
                })
                .catch(error => {
                  setShowProcedure(false);
                                console.log(error);
                                Swal.fire({
                                  icon: 'error',
                                  text: "Something Went Wrong!",
                                  confirmButtonColor: '#d33',
                                  confirmButtonText: 'OK',
                                  timer: 5000,
                                })
                                });
                    }
            // else if(getCountHere.length !== forvalidation .length){
            else if( validationCheckFlag == true ){
              console.log("Files are not uploaded")
              Swal.fire({
                // icon: 'error',
                text: "Please Upload All Mandatory Files",
                confirmButtonColor: 'primary',
                confirmButtonText: 'OK',
                timer: 5000,
              })
            }
          }
        else{
          Swal.fire({
            icon: 'error',
            // text: "Something went wrong",
            text: "Server Error. Please try again.",
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
            timer: 5000,
          })
        }
      })
      
    }
    // if ( formStateUploadFile == "5" && activeStep == 4 ){
    //   console.log("In submit function")
    //   setShowProcedure(true);
    //   let SubmitAnsObjectUploadFiles = ({
    //     case_id: newCaseCreatedCaseIdRedux, 
    //     case_status: "submit-case",
    //     device_token: device_token,
    //     session_id: session_id,
    //   })
    //   console.log("SubmitAnsObjectUploadFiles",SubmitAnsObjectUploadFiles)
    //     const requestOptions = {
    //       method: 'POST',
    //       headers: authHeader(),
    //       // body: JSON.stringify(SubmitAnsObjectUploadFiles)
    //       body: SubmitAnsObjectUploadFiles
    //     };
    //     console.log("requestOptions edit case",requestOptions)
    //     return fetch(process.env.REACT_APP_UPDATE_CASE_STATUS, requestOptions)
    //       .then((Response) => Response.json())
    //       .then( caseSubmited  => {
    //       console.log("caseSubmited sucess",caseSubmited);
    //       setShowProcedure(false);
    //       if(caseSubmited.success == true){
    //         console.log("It's Success of case submit")
    //         Swal.fire({
    //           icon: 'success',
    //           text: "Case is created Succesfully.",
    //           confirmButtonColor: '#d33',
    //           confirmButtonText: 'OK',
    //           timer: 5000,
    //         })
    //         setRedirectToCaseList(true);
    //         updateCaseActionResetRedux();
    //         // setActiveStep((prevActiveStep) => prevActiveStep + 1);                   
    //       }
    //       if(caseSubmited.success == false){
    //         Swal.fire({
    //           icon: 'error',
    //           text: caseSubmited.errors,
    //           confirmButtonColor: '#d33',
    //           confirmButtonText: 'OK',
    //           timer: 5000,
    //         })
    //       }
    //     })
    //     .catch(error => {
    //        setShowProcedure(false);
    //                     console.log(error);
    //                     Swal.fire({
    //                       icon: 'error',
    //                       text: "Something Went Wrong!",
    //                       confirmButtonColor: '#d33',
    //                       confirmButtonText: 'OK',
    //                       timer: 5000,
    //                     })
    //                     });
    // }
      
  };

  const handleBack = async() => {
    await setNextOrBackIs("back")
    await setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // await localStorage.setItem('backornext', JSON.stringify("back"));
    
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  if(RedirectToCaseList == true){
    return(
        <Redirect to="/cases" />
    )
  }
  else{
  return (
    // console.log("CreateCaseDetails in return,AssesmentYearHere",CreateCaseDetails,AssesmentYearHere),
    // console.log("editItemIs",editItemIs),
    <div className={classes.root}>
      {ShowProcedure ? <Modal className={classes.showProcedureStyle} isOpen={true}>
       <PropagateLoader  color={'#255ab0'} size='40px' loading='true' />
      </Modal>: null}
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div className={classes.wrapper}>
          <Grid container>
            <Grid item xs={1} className={classes.pageHeaderLabel} >
              <Button href="/cases" className={classes.backButton}>
                  <span className={classes.backArrow}>
                  <ArrowBackIcon fontSize="small" />
                </span>
                Back
              </Button>
            </Grid>
          </Grid>
          <Grid container className={classes.pageHeaderContainer} style={{marginTop:'1%'}}>
            <Grid item xs={12} className={classes.pageHeaderLabel}>
              {/* <FormattedMessage id="newCasesLabel" /> */}
              Edit Case Details
            </Grid>
          </Grid>
          <Grid container className={classes.linkBackToCase}>
            <Grid item xs={12}>
              <Link to="/cases" className={classes.linkNoUnderline}>
                {/* <FormattedMessage id="navigateToCase" /> */}
                View All Cases
              </Link>
            </Grid>
          </Grid>

          {/*  To show reject msg */}
          <Grid container  style={{marginTop:'1%', marginBottom:'1%'}}>
            {
              ( CaseOverallDetails &&  CaseOverallDetails.case_alert_message !== null )?
              (
                <Grid container item xs={12} >
                  <Grid className={classes.CaseRejectedLable}>
                    Case Reverted to Draft:&nbsp;
                  </Grid>
                  <Grid className={classes.CaseRejectedComment}>
                    {CaseOverallDetails.case_alert_message.message}
                  </Grid>
                </Grid>
              ):
              (<Grid></Grid>)
            }
          </Grid>

          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {intl.formatMessage({ id: label })}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  <FormattedMessage id="stepCompleteMessage" />
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                  className={classes.button}
                >
                  <FormattedMessage id="resetLabel" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  // href="/cases"
                >
                  <FormattedMessage id="submitLabel" />
                </Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    {/* <FormattedMessage id="backLabel" /> */}
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {intl.formatMessage({
                      id:
                        activeStep === steps.length - 1
                          ? "finishLabel"
                          : "nextlabel",
                    })}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Slide>
    </div>
  );
  }
}

export default injectIntl(EditExistingCase);
